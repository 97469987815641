import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';

import { OrderQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';

type ModalOrderRecipientNameProps = {
  order: OrderQuery['order'] | undefined;
};

export const ModalOrderRecipientName: React.FC<
  ModalOrderRecipientNameProps
> = ({ order }) => {
  const { t } = useTranslation();

  const [nameClipboardState, copyNameToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = nameClipboardState;
    if (value) {
      toast.success(t('order.copy_name_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [nameClipboardState, t]);

  const onCopyName = (name: string) => {
    copyNameToClipboard(name);
    track('CopyToClipboard', {
      label: 'name',
    });
  };

  return (
    <>
      <section className="space-y-2">
        <h4 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
          {t('order.recipient_name')}
        </h4>
        {order?.recipient?.fullName ? (
          <div className="flex items-center justify-between">
            <p className="flex items-center text-sm leading-5.5 text-gray-700">
              {order.recipient.fullName}
            </p>
            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('order.copy_name_tooltip')}
                </div>
              }
              placement="left"
            >
              <button
                onClick={() => onCopyName(order.recipient.fullName)}
                type="button"
                className="rounded transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
              >
                <Icon icon="copy" />
              </button>
            </Tooltip>
          </div>
        ) : null}
      </section>
    </>
  );
};
