import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import { useAcceptOrderClaimWithVoucherReplacementMessageMutation } from '../../types/generated-new';
import { OrderQuery } from '../../types/generated-new';
import { TextareaAutosize } from '../textarea-autosize/textarea-autosize';
import { ClaimedProduct } from './claimed-product';

type ModalDigitalCodeAlreadySolvedProps = {
  claim: OrderQuery['order']['claims'][0];
  orderId?: OrderQuery['order']['id'];
  setIsOpen: (isOpen: boolean) => void;
};

type NewDigitalCodeForm = {
  reason: string;
};

export const ModalDigitalCodeAlreadySolved: React.FC<
  ModalDigitalCodeAlreadySolvedProps
> = ({ claim, orderId, setIsOpen }: ModalDigitalCodeAlreadySolvedProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    mutateAsync: acceptOrderClaimWithVoucherReplacementMessageMutation,
    isPending,
    error,
  } = useAcceptOrderClaimWithVoucherReplacementMessageMutation();

  const form = useForm<NewDigitalCodeForm>({
    mode: 'onTouched',
    defaultValues: {
      reason: '',
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(t('order.modals.modal_new_digital_code.accept_claim_error'));
    }
  }, [error]);

  const onSubmit = async (data: NewDigitalCodeForm) => {
    await acceptOrderClaimWithVoucherReplacementMessageMutation({
      input: {
        orderId: orderId ?? '',
        claimId: claim?.id ?? '',
        voucherReplacementMessage: data.reason,
      },
    });

    queryClient.invalidateQueries({
      queryKey: ['order', { orderId: orderId }],
    });
    queryClient.invalidateQueries({
      queryKey: ['orders'],
    });
    setIsOpen(false);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col overflow-hidden"
      >
        <div className="overflow-y-auto p-6 md:p-10">
          <h3
            id="modal-digital-code-already-solved-title"
            className="mb-6 text-2xl font-bold text-gray-900"
          >
            {t(
              'order.modals.modal_digital_code_already_solved.digital_code_already_solved'
            )}
          </h3>
          <div className="space-y-6">
            <Alert
              title={t(
                'order.modals.modal_digital_code_already_solved.message_automatically_sent'
              )}
              icon="information"
              appearance="warning"
            >
              <></>
            </Alert>
            <ul className="space-y-6">
              {claim?.items?.map((product, index) => {
                return (
                  <li key={index} className="space-y-6">
                    <ClaimedProduct appearance="small" product={product} />
                  </li>
                );
              })}
            </ul>
            <div className="space-y-4">
              <div className="font-semibold text-gray-700">
                {t(
                  'order.modals.modal_digital_code_already_solved.reason.label'
                )}
              </div>
              <FormField
                control={form.control}
                name="reason"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">
                      {t(
                        'order.modals.modal_digital_code_already_solved.reason.label'
                      )}
                    </FormLabel>
                    <FormControl>
                      <TextareaAutosize
                        placeholder={t(
                          'order.modals.modal_digital_code_already_solved.reason.placeholder'
                        )}
                        minRows={4}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage match="required">
                      {t(
                        'order.modals.modal_digital_code_already_solved.reason.validation_errors.required'
                      )}
                    </FormMessage>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
          <Button
            type="button"
            appearance="outline"
            onClick={() => setIsOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={isPending}>
            {t('confirm')}
          </Button>
        </div>
      </form>
    </Form>
  );
};
