import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { brandState } from '../../stores/brand';

export type ModalOrderReturnInitialProps = {
  initialOrderId?: string | null;
};

export const ModalOrderReturnInitial: React.FC<
  ModalOrderReturnInitialProps
> = ({ initialOrderId }) => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const brand = useRecoilValue(brandState);

  if (!initialOrderId) {
    return null;
  }

  return (
    <NavLink
      to={`/${brand?.id}/orders/${initialOrderId}${search}`}
      className="-mx-4 -mt-6 mb-6 block cursor-pointer bg-gray-700 p-4 text-center text-xs text-white lg:-m-10 lg:mb-6"
    >
      {t('order.return.return_id')}{' '}
      <span className="font-bold">{initialOrderId}</span>
    </NavLink>
  );
};
