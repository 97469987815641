import Icon from '@appchoose/icon';
import { type VariantProps, cva } from 'cva';

import { OrderDetails, OrderDetailsProps } from './order-details';

const trackingArrowVariants = cva({
  base: 'rounded-full border p-1',
  variants: {
    style: {
      default: '',
      error: 'border-[#F0C5C5] bg-red-300 p-1 text-red-600',
      success: 'border-[#C6E7D5] bg-[#ECF7F1] p-1 text-green-600',
    },
  },
});

const TrackingArrow: React.FC<VariantProps<typeof trackingArrowVariants>> = ({
  style,
}) => {
  return (
    <div className={trackingArrowVariants({ style })}>
      <Icon icon="back" size="large" className="rotate-180" />
    </div>
  );
};

type OrderTrackingUpdateProps = {
  from: OrderDetailsProps['order'];
  to: OrderDetailsProps['order'][];
  updateError: boolean;
};

export const OrderTrackingUpdate: React.FC<OrderTrackingUpdateProps> = ({
  from,
  to,
  updateError,
}) => {
  return (
    <ul className="space-y-4">
      {to.map((order) => {
        const courierStyle = () => {
          if (
            from.parcels?.[0]?.trackingCourier.name ===
            order.parcels?.[0]?.trackingCourier.name
          )
            return 'default';

          if (updateError) return 'error';

          return 'success';
        };
        const orderIdStyle = () => {
          if (
            from.parcels?.[0]?.trackingNumber ===
            order.parcels?.[0]?.trackingNumber
          )
            return 'default';

          if (updateError) return 'error';

          return 'success';
        };

        return (
          <li key={order.id} className="flex items-center space-x-4">
            <div className="w-full divide-y divide-gray-300 rounded border border-gray-300">
              <OrderDetails
                order={to.length > 1 ? order : from}
                size="large"
                alignment="vertical"
                orderIdStyle="line-through"
              />
            </div>
            <TrackingArrow style={updateError ? 'error' : 'success'} />
            <div className="w-full divide-y divide-gray-300 rounded border border-gray-300">
              <OrderDetails
                order={order}
                size="large"
                alignment="vertical"
                courierStyle={courierStyle()}
                orderIdStyle={orderIdStyle()}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
