import { useLocation, useNavigate } from 'react-router-dom';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';

export type OrderNavigation = {
  goToNextPageAndOrder: () => void;
  goToPreviousPageAndOrder: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextOrderId?: string;
  previousOrderId?: string;
};

export type ModalOrderOrderNavigationProps = {
  brandId: string;
  className?: string;
} & OrderNavigation;

export const ModalOrderOrderNavigation: React.FC<
  ModalOrderOrderNavigationProps
> = ({
  brandId,
  className,
  goToNextPageAndOrder,
  goToPreviousPageAndOrder,
  hasNextPage,
  hasPreviousPage,
  nextOrderId,
  previousOrderId,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={cn('flex shrink-0 items-start space-x-2', className)}>
      <button
        type="button"
        className="rounded-[0.1875rem] border border-gray-100 p-1 hover:bg-gray-50 focus:ring-2 focus:ring-gray-100 disabled:bg-gray-50 disabled:text-gray-300"
        tabIndex={-1}
        disabled={!previousOrderId && !hasPreviousPage}
        onClick={() => {
          if (previousOrderId) {
            navigate(`/${brandId}/orders/${previousOrderId}${search}`);
          } else {
            goToPreviousPageAndOrder();
          }
        }}
      >
        <Icon icon="arrowDown" className="size-[1.125rem] rotate-90" />
      </button>
      <button
        type="button"
        className="rounded-[0.1875rem] border border-gray-100 p-1 hover:bg-gray-50 focus:ring-2 focus:ring-gray-100 disabled:bg-gray-50 disabled:text-gray-300"
        tabIndex={-1}
        disabled={!nextOrderId && !hasNextPage}
        onClick={() => {
          if (nextOrderId) {
            navigate(`/${brandId}/orders/${nextOrderId}${search}`);
          } else {
            goToNextPageAndOrder();
          }
        }}
      >
        <Icon icon="arrowDown" className="size-[1.125rem] -rotate-90" />
      </button>
    </div>
  );
};
