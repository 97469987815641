type MediumArrowProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const MediumArrow: React.FC<MediumArrowProps> = (
  props: MediumArrowProps
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="20"
        cy="20"
        r="18"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.505 27.495C19.7784 27.7683 20.2216 27.7683 20.495 27.495L24.9497 23.0402C25.2231 22.7668 25.2231 22.3236 24.9497 22.0503C24.6764 21.7769 24.2332 21.7769 23.9598 22.0503L20 26.0101L16.0402 22.0503C15.7668 21.7769 15.3236 21.7769 15.0503 22.0503C14.7769 22.3236 14.7769 22.7668 15.0503 23.0402L19.505 27.495ZM20.7 13C20.7 12.6134 20.3866 12.3 20 12.3C19.6134 12.3 19.3 12.6134 19.3 13L20.7 13ZM20.7 27L20.7 13L19.3 13L19.3 27L20.7 27Z"
        fill="#0B1115"
      />
    </svg>
  );
};
