import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

import { CircleStroke } from '../icons/circle-stroke/circle-stroke';

export type UpdatingInfoProps = {
  goToNextStep: () => void;
  loading?: boolean;
};

export const UpdatingInfoStepIntroduction: React.FC<UpdatingInfoProps> = ({
  goToNextStep,
}: UpdatingInfoProps) => {
  const { t } = useTranslation();

  const rules = [
    t('updating_info.introduction.first_info'),
    t('updating_info.introduction.second_info'),
    t('updating_info.introduction.third_info'),
  ];

  return (
    <section className="sm:col-11 w-full">
      <div>
        <div className="mt-8 sm:mt-20">
          <h2 className="text-2xl font-bold sm:text-3.5xl">
            {t('updating_info.introduction.title')}
          </h2>
          <h3 className="my-6 text-sm font-normal text-gray-700 sm:mt-10 sm:text-base">
            {t('updating_info.introduction.main-content')}
          </h3>
        </div>
        <ul className="mb-8 space-y-6">
          {rules.map((rule, index) => (
            <li
              key={index}
              className="flex items-center leading-5.5 text-gray-900"
            >
              <div className="mr-2 inline-block">
                <div className="relative flex items-center justify-center">
                  <CircleStroke />
                  <span className="absolute text-sm font-bold text-green-900">
                    {index + 1}
                  </span>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: rule,
                }}
                className="rule-content-bold"
              ></div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 flex justify-end sm:mt-10">
        <Button type="button" size="large" onClick={goToNextStep}>
          {t('lets_go')}
        </Button>
      </div>
    </section>
  );
};
