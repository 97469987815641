import cn from '@appchoose/cn';

import NoPP from '../../assets/img/no-pp.png';
import { replaceChooseImageUrl } from '../../utils/image';

type AvatarProfileProps = {
  pictureUrl: string | undefined;
  className?: string;
};

export const AvatarProfile: React.FC<AvatarProfileProps> = ({
  pictureUrl,
  className,
}: AvatarProfileProps) => {
  return (
    <img
      className={cn('rounded-full border border-gray-100', className)}
      src={pictureUrl ? replaceChooseImageUrl(pictureUrl) : NoPP}
    />
  );
};
