import cn from '@appchoose/cn';

export type TabCounterProps = {
  className?: string;
  counter?: number;
};

export const TabCounter: React.FC<TabCounterProps> = ({
  className,
  counter,
}) => {
  if (counter === null || counter === undefined) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex grow-0 items-center rounded bg-gray-50 px-1.5 py-0.5 text-xs font-semibold text-[#767A7C] group-data-[state=active]:bg-green-300 group-data-[state=active]:text-green-900',
        className
      )}
    >
      {counter}
    </div>
  );
};
