import React, { useId, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TelInput from 'react-phone-number-input/input';

import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import { FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import Label from '@appchoose/label';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { isSupportedCountry, isValidPhoneNumber } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js';
import { matchSorter } from 'match-sorter';

import { getCountryOptions } from '../../utils/address-options';
import {
  generatePhonePlaceholder,
  hasPhoneNumberReachedMaxLength,
} from '../../utils/phone';

export type PhoneFormData = {
  phone: string;
  phoneCountryCode: string;
};

type PhoneFormFieldsProps = {
  autoFocus?: boolean;
};

export const PhoneFormFields = ({ autoFocus }: PhoneFormFieldsProps) => {
  const { t } = useTranslation();
  const id = useId();
  const form = useFormContext<PhoneFormData>();
  const countryOptions = getCountryOptions();
  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();

  const countryMatches = useMemo(
    () => matchSorter(countryOptions, searchQuery, { keys: ['label'] }),
    [countryOptions, searchQuery]
  );

  form.watch('phoneCountryCode');

  return (
    <div className="space-y-2">
      <div className="flex flex-row items-center">
        <Label
          htmlFor={id}
          className={cn(
            'text-xs font-semibold uppercase tracking-wider text-gray-700',
            {
              'text-red-600': form.formState.errors.phone,
            }
          )}
        >
          {t('brand_info.contact_form_fields.phone.label')}
        </Label>
        {form.formState.errors.phone ? (
          <div className="ml-1">
            <Icon icon="alertCircle" className="text-red-600" />
          </div>
        ) : null}
      </div>
      <div className="group flex w-full items-center">
        <FormField
          control={form.control}
          name="phoneCountryCode"
          render={({ field }) => {
            const selectedCountryOption = countryOptions.find(
              (country) => country.value === field.value
            );
            return (
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <button
                    type="button"
                    role="combobox"
                    aria-expanded={open}
                    className='form-input flex items-center justify-between rounded-l border-gray-500 bg-gray-50 text-sm leading-5.5 text-gray-900 transition duration-300 hover:border-gray-700 focus:z-10 focus:border-gray-700 focus:ring-1 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                  >
                    <div className="flex min-h-5.5 items-center truncate">
                      <span className="truncate">
                        {field.value
                          ? selectedCountryOption?.leftSection
                          : t('address.fields.country.empty_field')}
                      </span>
                    </div>
                    <Icon
                      icon={open ? 'close' : 'arrowDown'}
                      className="ml-1 size-4 shrink-0 opacity-50"
                    />
                  </button>
                </PopoverTrigger>
                <PopoverContent className="p-0" align="start">
                  <Command shouldFilter={false}>
                    <CommandInput
                      placeholder={t(
                        'address.fields.country.placeholder_search'
                      )}
                      onValueChange={(value) => {
                        setSearchQuery(value);

                        //#region scroll list to top when typing
                        // https://github.com/pacocoursey/cmdk/issues/234
                        // https://github.com/pacocoursey/cmdk/issues/233

                        // clear pending scroll
                        if (scrollId.current)
                          cancelAnimationFrame(scrollId.current);

                        // the setTimeout is used to create a new task
                        // this is to make sure that we don't scroll until the user is done typing
                        // you can tweak the timeout duration ofc
                        scrollId.current = requestAnimationFrame(() => {
                          // inside your list select the first group and scroll to the top
                          listRef.current?.scrollTo({ top: 0 });
                        });

                        //#endregion
                      }}
                      value={searchQuery}
                    />
                    <CommandList ref={listRef}>
                      <CommandEmpty>
                        {t('address.fields.country.no_results')}
                      </CommandEmpty>
                      <CommandGroup>
                        {countryMatches.map((country) => (
                          <CommandItem
                            key={country.value}
                            value={country.value}
                            keywords={[country.label]}
                            onSelect={(currentValue) => {
                              field.onChange(
                                currentValue === field.value ? '' : currentValue
                              );
                              setOpen(false);
                            }}
                            className={cn('flex items-center justify-between', {
                              'font-semibold text-green-900':
                                field.value === country.value,
                            })}
                          >
                            <div className="flex items-center truncate">
                              {country.leftSection}

                              <span className="truncate">{country.label}</span>
                            </div>
                            <Icon
                              icon="check"
                              className={cn(
                                'ml-2 size-4 shrink-0',
                                field.value === country.value
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            );
          }}
        />

        <FormField
          control={form.control}
          name="phone"
          rules={{
            required: true,
            maxLength: 40,
            validate: {
              validPhoneNumber: (value) => {
                // Toujours valide si le pays n'est pas supporté
                return isSupportedCountry(
                  form.getValues('phoneCountryCode') as CountryCode
                )
                  ? isValidPhoneNumber(
                      value,
                      form.getValues('phoneCountryCode') as CountryCode
                    )
                  : true;
              },
            },
            deps: ['phoneCountryCode'],
          }}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <TelInput
                  id={id}
                  autoFocus={autoFocus}
                  autoComplete="tel"
                  className={cn(
                    'form-input -ml-px block w-full rounded rounded-l-none border border-gray-500 p-2 text-sm leading-5.5 outline-none transition duration-300 hover:border-gray-700 focus:z-10 focus:border-gray-700 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                  )}
                  defaultCountry={
                    (form.getValues('phoneCountryCode') as CountryCode) ?? 'FR'
                  }
                  initialvalueformat="national"
                  placeholder={generatePhonePlaceholder(
                    (form.getValues('phoneCountryCode') as CountryCode) ?? 'FR'
                  )}
                  {...field}
                  onChange={(value) => field.onChange(value ?? '')}
                  onKeyPress={(
                    event: React.KeyboardEvent<HTMLInputElement>
                  ) => {
                    if (
                      hasPhoneNumberReachedMaxLength(
                        field.value ?? '',
                        form.getValues('phoneCountryCode') ?? 'FR'
                      )
                    )
                      event.preventDefault();
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      {!form.formState.errors.phone ? (
        <p className="text-xs">
          {t('brand_info.contact_form_fields.phone.help')}
        </p>
      ) : null}
      {form.formState.errors.phone?.type === 'required' ? (
        <p className="text-xs text-red-600">
          {t('brand_info.contact_form_fields.phone.validation_errors.required')}
        </p>
      ) : null}
      {form.formState.errors.phone?.type === 'maxLength' ? (
        <p className="text-xs text-red-600">
          {t(
            'brand_info.contact_form_fields.phone.validation_errors.maxLength',
            {
              maxLength: '40',
            }
          )}
        </p>
      ) : null}
      {form.formState.errors.phone?.type === 'validPhoneNumber' ? (
        <p className="text-xs text-red-600">
          {t(
            'brand_info.contact_form_fields.phone.validation_errors.validPhoneNumber'
          )}
        </p>
      ) : null}
    </div>
  );
};

PhoneFormFields.displayName = 'PhoneFormFields';
