import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  }, [pathname]);

  return <div className="invisible -m-px h-px w-full" ref={ref}></div>;
};
