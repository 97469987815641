type ShopifyPluggedIconProps = {
  title: string;
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const ShopifyPluggedIcon: React.FC<ShopifyPluggedIconProps> = (
  props: ShopifyPluggedIconProps
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="shopify-plugged-icon-title"
      role="img"
      {...props}
    >
      <title id="shopify-plugged-icon-title">{props.title}</title>
      <path
        d="M18.6037 5.89406C18.5899 5.79348 18.5018 5.73789 18.429 5.73178C18.3564 5.72568 16.8192 5.61177 16.8192 5.61177C16.8192 5.61177 15.7515 4.55185 15.6344 4.43457C15.5171 4.31728 15.2882 4.35295 15.1993 4.37914C15.1862 4.38299 14.9661 4.45096 14.6018 4.56358C14.2451 3.53741 13.6158 2.5943 12.5085 2.5943C12.478 2.5943 12.4465 2.59559 12.415 2.59736C12.1001 2.18091 11.71 2 11.3731 2C8.79375 2 7.56145 5.2244 7.17504 6.86303C6.17281 7.1736 5.46074 7.39435 5.3698 7.42295C4.81037 7.5984 4.79269 7.61607 4.71927 8.14322C4.66384 8.54231 3.20001 19.8628 3.20001 19.8628L14.6065 22L20.787 20.6629C20.787 20.6629 18.6173 5.99463 18.6037 5.89406ZM13.9714 4.75863C13.6849 4.84732 13.3592 4.94806 13.0062 5.05731C13.0066 4.98935 13.0069 4.92235 13.0069 4.84925C13.0069 4.2114 12.9184 3.69792 12.7763 3.29079C13.3469 3.36245 13.7268 4.01154 13.9714 4.75863ZM12.0686 3.41723C12.2272 3.81472 12.3303 4.38508 12.3303 5.15483C12.3303 5.1942 12.33 5.23019 12.3297 5.26666C11.702 5.46106 11.0199 5.67218 10.3363 5.88393C10.7201 4.4026 11.4396 3.68715 12.0686 3.41723ZM11.3022 2.69183C11.4136 2.69183 11.5257 2.72958 11.633 2.80349C10.8064 3.19246 9.92034 4.1722 9.54615 6.12863C8.99924 6.29797 8.46471 6.46346 7.9705 6.61657C8.4088 5.12431 9.44959 2.69183 11.3022 2.69183Z"
        fill="#95BF46"
      />
      <path
        d="M18.429 5.73178C18.3564 5.72567 16.8191 5.61176 16.8191 5.61176C16.8191 5.61176 15.7515 4.55185 15.6344 4.43456C15.5905 4.39086 15.5314 4.36853 15.4695 4.35889L14.6071 21.9998L20.7869 20.6629C20.7869 20.6629 18.6173 5.99463 18.6036 5.89405C18.5898 5.79347 18.5018 5.73788 18.429 5.73178Z"
        fill="#5E8E3E"
      />
      <path
        d="M12.5085 9.14676L11.7465 11.4136C11.7465 11.4136 11.0788 11.0572 10.2603 11.0572C9.06047 11.0572 9.00006 11.8103 9.00006 12C9.00006 13.0354 11.6989 13.432 11.6989 15.8571C11.6989 17.765 10.4888 18.9936 8.85707 18.9936C6.89904 18.9936 5.89777 17.775 5.89777 17.775L6.42202 16.0429C6.42202 16.0429 7.45124 16.9265 8.3198 16.9265C8.88727 16.9265 9.11815 16.4797 9.11815 16.1532C9.11815 14.8027 6.90402 14.7424 6.90402 12.5233C6.90402 10.6556 8.24461 8.84808 10.9507 8.84808C11.9934 8.84808 12.5085 9.14676 12.5085 9.14676Z"
        fill="white"
      />
    </svg>
  );
};
