import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormLabelCheckbox,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input from '@appchoose/input';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import {
  StoreRegion,
  useVerifyShopifyCredentialsQuery,
} from '../../types/generated';
import { transformPrice } from '../../utils/currency';
import { isShopifySecretValid, isShopifyTokenValid } from '../../utils/string';
import {
  SHOPIFY_INTEGRATION_FAQ,
  ShopifyIntegrationForm,
} from './shopify-modal';

export type ShopifyFormProps = {
  isEditMode: boolean;
};

export const ShopifyForm: React.FC<ShopifyFormProps> = ({
  isEditMode,
}: ShopifyFormProps) => {
  const { i18n, t } = useTranslation();

  const brand = useRecoilValue(brandState);
  const activeSale = useRecoilValue(activeSaleState);
  const [canEditCredentials, setCanEditCredentials] = useState(!isEditMode);

  const form = useFormContext<ShopifyIntegrationForm>();

  const { refetch: verifyShopifyCredentials } =
    useVerifyShopifyCredentialsQuery(
      {
        url: form.getValues('shopify_url')?.includes('https://')
          ? form.getValues('shopify_url')
          : `https://${form.getValues('shopify_url')}`,
        accessToken: form.getValues('token')
          ? form.getValues('token')
          : undefined,
      },
      {
        enabled: false,
      }
    );

  const isProductPriceKeptOptions = [
    {
      name: t(
        'settings.integration_tabs.shopify.fields.is_product_price_kept.options.no',
        {
          amount: transformPrice(
            0,
            i18n.language === 'fr' ? 'fr' : 'en',
            activeSale?.currency?.toString()?.toUpperCase() ?? 'EUR'
          ),
        }
      ),
      value: 'false',
    },
    {
      name: t(
        'settings.integration_tabs.shopify.fields.is_product_price_kept.options.yes'
      ),
      value: 'true',
    },
  ];

  form.watch('shopify_url');
  form.watch('token');

  return (
    <div className="space-y-10">
      <div className="space-y-6 border-b border-gray-100 pb-6">
        <h3 className="font-bold text-gray-900">
          {t('settings.integration_tabs.shopify.login_information')}
        </h3>
        <FormField
          control={form.control}
          name="shopify_url"
          rules={{
            required: true,
            validate: async (value: string) => {
              if (!value.includes('myshopify.com'))
                return t(
                  'settings.integration_tabs.shopify.fields.shopify_url.validation_errors.includes_myshopify'
                );

              const { data } = await verifyShopifyCredentials();

              return data?.verifyShopifyCredentials.isValidUrl
                ? true
                : t(
                    'settings.integration_tabs.shopify.fields.shopify_url.validation_errors.invalid_url'
                  );
            },
          }}
          disabled={isEditMode && !canEditCredentials}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormItem>
              <FormLabel>
                {t(
                  'settings.integration_tabs.shopify.fields.shopify_url.label'
                )}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t(
                    'settings.integration_tabs.shopify.fields.shopify_url.placeholder'
                  )}
                  {...field}
                />
              </FormControl>
              {!invalid ? (
                <span className="flex items-center whitespace-pre text-xs">
                  {t(
                    'settings.integration_tabs.shopify.fields.shopify_url.help'
                  )}{' '}
                  <a
                    href={SHOPIFY_INTEGRATION_FAQ}
                    target="_blank"
                    className="font-semibold"
                    rel="noreferrer"
                  >
                    {t(
                      'settings.integration_tabs.shopify.fields.shopify_url.this_document'
                    )}
                  </a>
                  <Icon
                    icon="externalLinkDefault"
                    size="small"
                    className="ml-0.5"
                  />
                </span>
              ) : null}
              <FormMessage match="required">
                {t(
                  'settings.integration_tabs.shopify.fields.shopify_url.validation_errors.required'
                )}
              </FormMessage>
              <FormMessage match="validate">
                {error?.message?.includes('myshopify.com') ? (
                  error?.message
                ) : (
                  <span className="flex items-center whitespace-pre">
                    {error?.message}{' '}
                    <a
                      href={SHOPIFY_INTEGRATION_FAQ}
                      target="_blank"
                      className="font-semibold"
                      rel="noreferrer"
                    >
                      {t(
                        'settings.integration_tabs.shopify.fields.shopify_url.this_document'
                      )}
                    </a>
                    <Icon
                      icon="externalLinkDefault"
                      size="small"
                      className="ml-0.5"
                    />
                  </span>
                )}
              </FormMessage>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="token"
          rules={{
            required: true,
            validate: async (value: string) => {
              if (!isShopifyTokenValid(value))
                return t(
                  'settings.integration_tabs.shopify.fields.token.validation_errors.shopify_token_validation'
                );

              const { data } = await verifyShopifyCredentials();

              return data?.verifyShopifyCredentials.isValidAccessToken
                ? true
                : t(
                    'settings.integration_tabs.shopify.fields.token.validation_errors.invalid_token'
                  );
            },
          }}
          disabled={isEditMode && !canEditCredentials}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormItem>
              <FormLabel>
                {t('settings.integration_tabs.shopify.fields.token.label')}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t(
                    'settings.integration_tabs.shopify.fields.token.placeholder'
                  )}
                  {...field}
                />
              </FormControl>
              {!invalid && !isEditMode ? (
                <p className="text-xs">
                  {t('settings.integration_tabs.shopify.fields.token.help')}
                </p>
              ) : null}
              <FormMessage match="required">
                {t(
                  'settings.integration_tabs.shopify.fields.token.validation_errors.required'
                )}
              </FormMessage>
              <FormMessage match="validate">{error?.message}</FormMessage>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="shared_secret"
          rules={{
            required: true,
            validate: {
              shopifySecretValidation: (value: string) =>
                isShopifySecretValid(value),
            },
          }}
          disabled={isEditMode && !canEditCredentials}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t(
                  'settings.integration_tabs.shopify.fields.shared_secret.label'
                )}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t(
                    'settings.integration_tabs.shopify.fields.shared_secret.placeholder'
                  )}
                  {...field}
                />
              </FormControl>
              <FormMessage match="required">
                {t(
                  'settings.integration_tabs.shopify.fields.shared_secret.validation_errors.required'
                )}
              </FormMessage>
              <FormMessage match="shopifySecretValidation">
                {t(
                  'settings.integration_tabs.shopify.fields.shared_secret.validation_errors.shopify_token_validation'
                )}
              </FormMessage>
            </FormItem>
          )}
        />

        {isEditMode && (
          <Button
            type="button"
            appearance="outline"
            onClick={() => {
              form.setValue('token', '');
              form.setValue('shared_secret', '');
              setCanEditCredentials(true);
            }}
            disabled={canEditCredentials}
          >
            {t('settings.integration_tabs.shopify.reset')}
          </Button>
        )}
      </div>
      <div className="space-y-6">
        <h3 className="font-bold text-gray-900">
          {t('settings.integration_tabs.shopify.additional_information')}
        </h3>
        <FormField
          control={form.control}
          name="carrier_code"
          render={({ field, fieldState: { invalid } }) => (
            <FormItem>
              <FormLabel>
                {t(
                  'settings.integration_tabs.shopify.fields.carrier_code.label'
                )}
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t(
                    'settings.integration_tabs.shopify.fields.carrier_code.placeholder'
                  )}
                  {...field}
                />
              </FormControl>
              {!invalid && brand?.store === StoreRegion.Fr ? (
                <p className="text-xs">
                  {t(
                    'settings.integration_tabs.shopify.fields.carrier_code.note'
                  )}
                </p>
              ) : null}
            </FormItem>
          )}
        />

        {brand?.store === StoreRegion.Fr ? (
          <FormField
            control={form.control}
            name="international_carrier_code"
            render={({ field, fieldState: { invalid } }) => (
              <FormItem>
                <FormLabel>
                  {t(
                    'settings.integration_tabs.shopify.fields.international_carrier_code.label'
                  )}
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder={t(
                      'settings.integration_tabs.shopify.fields.international_carrier_code.placeholder'
                    )}
                    {...field}
                  />
                </FormControl>
                {!invalid ? (
                  <p className="text-xs">
                    {t(
                      'settings.integration_tabs.shopify.fields.international_carrier_code.note'
                    )}
                  </p>
                ) : null}
              </FormItem>
            )}
          />
        ) : null}
        {brand?.store === StoreRegion.Fr ? (
          <FormField
            control={form.control}
            name="mondial_relay_carrier_code"
            render={({ field, fieldState: { invalid } }) => (
              <FormItem>
                <FormLabel>
                  {t(
                    'settings.integration_tabs.shopify.fields.mondial_relay_carrier_code.label'
                  )}
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder={t(
                      'settings.integration_tabs.shopify.fields.mondial_relay_carrier_code.placeholder'
                    )}
                    {...field}
                  />
                </FormControl>
                {!invalid && brand?.store === StoreRegion.Fr ? (
                  <p className="text-xs">
                    {t(
                      'settings.integration_tabs.shopify.fields.mondial_relay_carrier_code.note'
                    )}
                  </p>
                ) : null}
              </FormItem>
            )}
          ></FormField>
        ) : null}
        <div className="space-y-2">
          <div className="text-xs font-semibold uppercase tracking-wider text-gray-700">
            {t('settings.integration_tabs.shopify.fields.stock_live.title')}
          </div>
          <FormField
            control={form.control}
            name="stock_live"
            render={({ field }) => (
              <FormItem>
                <div className="flex space-x-3">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value=""
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabelCheckbox>
                    {t(
                      'settings.integration_tabs.shopify.fields.stock_live.label'
                    )}
                  </FormLabelCheckbox>
                </div>
                <p className="text-xs text-gray-700">
                  {t(
                    'settings.integration_tabs.shopify.fields.stock_live.description'
                  )}
                </p>
              </FormItem>
            )}
          />
        </div>

        <div className="space-y-2">
          <Controller
            control={form.control}
            name="is_product_price_kept"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel>
                  {t(
                    'settings.integration_tabs.shopify.fields.is_product_price_kept.label'
                  )}
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    value={value}
                    onValueChange={onChange}
                    className="gap-3"
                  >
                    {isProductPriceKeptOptions.map((option) => (
                      <div
                        className="group flex items-center space-x-3"
                        key={option.value}
                      >
                        <RadioGroupItem
                          key={option.value}
                          value={option.value}
                          id={`product-price-kept-${option.value}`}
                        />

                        <Label
                          htmlFor={`product-price-kept-${option.value}`}
                          className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                        >
                          {option.name}
                        </Label>
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
      {!isEditMode ? (
        <FormField
          control={form.control}
          name="gcu"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormItem className="space-y-2">
              <label className="flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-beige-300 p-4">
                <FormControl>
                  <Checkbox
                    {...field}
                    value=""
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    className="shrink-0"
                  />
                </FormControl>
                <span className="text-xs text-gray-700">
                  {t(
                    'settings.integration_tabs.shopify.fields.choose_conditions.description'
                  )}
                </span>
              </label>
              <FormMessage match="required">
                {t(
                  'settings.integration_tabs.shopify.fields.choose_conditions.validation_errors.required'
                )}
              </FormMessage>
            </FormItem>
          )}
        />
      ) : null}
    </div>
  );
};

ShopifyForm.displayName = 'ShopifyForm';
