type DesktopWaitIllutrationProps = {
  title: string;
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const DesktopWaitIllutration: React.FC<DesktopWaitIllutrationProps> = (
  props: DesktopWaitIllutrationProps
) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="desktop-wait-illustration-title"
      role="img"
      {...props}
    >
      <title id="desktop-wait-illustration-title">{props.title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 25.2998C11.4043 25.2998 9.30005 27.4041 9.30005 29.9998V97.9998C9.30005 100.596 11.4043 102.7 14 102.7H114C116.596 102.7 118.7 100.596 118.7 97.9998V29.9998C118.7 27.4041 116.596 25.2998 114 25.2998H14ZM10.7 29.9998C10.7 28.1773 12.1775 26.6998 14 26.6998H114C115.823 26.6998 117.3 28.1773 117.3 29.9998V97.9998C117.3 99.8223 115.823 101.3 114 101.3H14C12.1775 101.3 10.7 99.8223 10.7 97.9998V29.9998ZM16.5 33.9998C17.3285 33.9998 18 33.3282 18 32.4998C18 31.6714 17.3285 30.9998 16.5 30.9998C15.6716 30.9998 15 31.6714 15 32.4998C15 33.3282 15.6716 33.9998 16.5 33.9998ZM23.4286 32.4998C23.4286 33.3282 22.7571 33.9998 21.9286 33.9998C21.1002 33.9998 20.4286 33.3282 20.4286 32.4998C20.4286 31.6714 21.1002 30.9998 21.9286 30.9998C22.7571 30.9998 23.4286 31.6714 23.4286 32.4998ZM27.3572 33.9998C28.1857 33.9998 28.8572 33.3282 28.8572 32.4998C28.8572 31.6714 28.1857 30.9998 27.3572 30.9998C26.5288 30.9998 25.8572 31.6714 25.8572 32.4998C25.8572 33.3282 26.5288 33.9998 27.3572 33.9998ZM47.3 63.9998C47.3 54.7766 54.7769 47.2998 64 47.2998C73.2232 47.2998 80.7001 54.7766 80.7001 63.9998C80.7001 73.223 73.2232 80.6998 64 80.6998C54.7769 80.6998 47.3 73.223 47.3 63.9998ZM64 48.6998C55.5501 48.6998 48.7001 55.5498 48.7001 63.9998C48.7001 72.4498 55.5501 79.2998 64 79.2998C72.45 79.2998 79.3 72.4498 79.3 63.9998C79.3 55.5498 72.45 48.6998 64 48.6998ZM64.7001 52.9998C64.7001 52.6132 64.3867 52.2998 64 52.2998C63.6134 52.2998 63.3 52.6132 63.3 52.9998V63.7099L59.5051 67.5048C59.2317 67.7782 59.2317 68.2214 59.5051 68.4948C59.7784 68.7681 60.2217 68.7681 60.495 68.4948L64.495 64.4948C64.6263 64.3635 64.7001 64.1855 64.7001 63.9998V52.9998Z"
        fill="#0B1115"
      />
    </svg>
  );
};
