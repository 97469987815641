export const replaceChooseImageUrl = (url: string) => {
  if (
    url.includes('https://storage.googleapis.com/images.appchoose.co/') ||
    url.includes('https://storage.googleapis.com/images.appchoose.io/')
  ) {
    return url.replace(
      'https://storage.googleapis.com/',
      'https://images.choose.app/insecure/resize:fill:264:264/plain/gs://'
    );
  }
  return url;
};
