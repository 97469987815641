type ExchangeProps = React.ComponentPropsWithoutRef<'svg'>;

export const Exchange: React.FC<ExchangeProps> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.87207 13V20C3.87207 20.5523 4.31979 21 4.87207 21H19.0003C19.5526 21 20.0003 20.5523 20.0003 20V15.9007"
        stroke="#139B51"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24316 14L3.88948 11.888L1.49957 14"
        stroke="#139B51"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1279 11V4C20.1279 3.44772 19.6802 3 19.1279 3H4.99973C4.44744 3 3.99973 3.44772 3.99973 4V8.09931"
        stroke="#139B51"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7568 10L20.1105 12.112L22.5004 10"
        stroke="#139B51"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
