import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import { getFormattedAddress } from '@appchoose/address';
import AddressView from '@appchoose/address-view';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';

import { OrderQuery } from '../../types/generated-new';
import {
  recipientToAddress,
  recipientToShopifyAddress,
} from '../../utils/address';
import { track } from '../../utils/analytics';

type ModalOrderRecipientAddressProps = {
  order: OrderQuery['order'] | undefined;
};

export const ModalOrderRecipientAddress: React.FC<
  ModalOrderRecipientAddressProps
> = ({ order }: ModalOrderRecipientAddressProps) => {
  const { t, i18n } = useTranslation();

  const [isPreviousAddressMenuOpen, setIsPreviousAddressMenuOpen] =
    useState(false);

  const [addressClipboardState, copyAddressToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = addressClipboardState;
    if (value) {
      toast.success(t('order.copy_address_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [addressClipboardState, t]);

  const handlePreviousAddressMenuClick = () =>
    setIsPreviousAddressMenuOpen(!isPreviousAddressMenuOpen);

  const onCopyAddress = async (recipient: OrderQuery['order']['recipient']) => {
    let stringToCopy = '';
    await getFormattedAddress(
      recipientToShopifyAddress(recipient),
      i18n.language === 'fr' ? 'FR' : 'EN'
    ).then((address) => {
      address.forEach((row) => (stringToCopy += `${row}\n`));
    });
    copyAddressToClipboard(stringToCopy);
    track('CopyToClipboard', {
      label: 'shipping_address',
    });
  };
  const onCopyPreviousAddress = async (
    recipient: OrderQuery['order']['recipient']['previous']
  ) => {
    if (!recipient) return;

    let stringToCopy = '';
    await getFormattedAddress(
      recipientToShopifyAddress(recipient),
      i18n.language === 'fr' ? 'FR' : 'EN'
    ).then((address) => {
      address.forEach((row) => (stringToCopy += `${row}\n`));
    });
    copyAddressToClipboard(stringToCopy);
    track('CopyToClipboard', {
      label: 'previous_shipping_address',
    });
  };

  if (!order?.recipient) return null;

  return (
    <section className="space-y-2">
      <h4 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
        {t('order.delivery_address')}
      </h4>
      <div className="flex items-start justify-between">
        <AddressView
          label={t('address.label')}
          address={recipientToAddress(order.recipient)}
          locale={i18n.language === 'fr' ? 'FR' : 'EN'}
          size="medium"
        />
        <Tooltip
          content={
            <div className="px-1.5 py-3">{t('order.copy_address_tooltip')}</div>
          }
          placement="left"
        >
          <button
            onClick={() => onCopyAddress(order.recipient)}
            type="button"
            className="rounded pt-1 transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
          >
            <Icon icon="copy" className="copy" />
          </button>
        </Tooltip>
      </div>

      {order?.recipient?.previous ? (
        <div className="mb-4 mt-2 flex items-start justify-between">
          <div className="flex flex-col">
            <div
              className="flex cursor-pointer items-center"
              onClick={handlePreviousAddressMenuClick}
            >
              <div className="mr-1 text-xs font-semibold text-gray-700">
                {t('order.previous_address')}
              </div>
              <Icon
                icon="arrowDown"
                size="medium"
                className={`duration-[250ms] text-gray-700 transition-transform ease-in ${
                  isPreviousAddressMenuOpen ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </div>
            {isPreviousAddressMenuOpen ? (
              <div className="pt-1">
                <AddressView
                  label={t('address.label')}
                  address={recipientToAddress(order.recipient.previous)}
                  locale={i18n.language === 'fr' ? 'FR' : 'EN'}
                  size="small"
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {isPreviousAddressMenuOpen ? (
            <Tooltip
              content={
                <div className="px-1.5 py-3">
                  {t('order.copy_address_tooltip')}
                </div>
              }
              placement="left"
            >
              <button
                onClick={() => onCopyPreviousAddress(order.recipient.previous)}
                type="button"
                className="mt-1 rounded transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
              >
                <Icon icon="copy" className="copy" />
              </button>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      ) : null}
    </section>
  );
};
