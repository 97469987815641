import {
  electronicFormatIBAN,
  friendlyFormatIBAN,
  isValidIBAN,
} from 'ibantools';

const IBAN_DATA = [
  {
    code: 'AL',
    length: '28',
    example: 'AL35202111090000000001234567',
  },
  {
    code: 'DE',
    length: '22',
    example: 'DE91100000000123456789',
  },
  {
    code: 'AD',
    length: '24',
    example: 'AD1400080001001234567890',
  },
  {
    code: 'SA',
    length: '24',
    example: 'SA4420000001234567891234',
  },
  {
    code: 'AT',
    length: '20',
    example: 'AT483200000012345864',
  },
  {
    code: 'AZ',
    length: '28',
    example: 'AZ96AZEJ00000000001234567890',
  },
  {
    code: 'BH',
    length: '22',
    example: 'BH02CITI00001077181611',
  },
  {
    code: 'BE',
    length: '16',
    example: 'BE71096123456769',
  },
  {
    code: 'BY',
    length: '28',
    example: 'BY86AKBB10100000002966000000',
  },
  {
    code: 'BA',
    length: '20',
    example: 'BA393385804800211234',
  },
  {
    code: 'BR',
    length: '29',
    example: 'BR1500000000000010932840814P2',
  },
  {
    code: 'BG',
    length: '22',
    example: 'BG18RZBB91550123456789',
  },
  {
    code: 'CY',
    length: '28',
    example: 'CY21002001950000357001234567',
  },
  {
    code: 'CR',
    length: '22',
    example: 'CR23015108410026012345',
  },
  {
    code: 'HR',
    length: '21',
    example: 'HR1723600001101234565',
  },
  {
    code: 'DK',
    length: '18',
    example: 'DK9520000123456789',
  },
  {
    code: 'EG',
    length: '29',
    example: 'EG800002000156789012345180002',
  },
  {
    code: 'ES',
    length: '24',
    example: 'ES7921000813610123456789',
  },
  {
    code: 'IC',
    length: '24',
    example: 'ES7921000813610123456789',
  },
  {
    code: 'EA',
    length: '24',
    example: 'ES7921000813610123456789',
  },
  {
    code: 'EE',
    length: '20',
    example: 'EE471000001020145685',
  },
  {
    code: 'FI',
    length: '18',
    example: 'FI1410093000123458',
  },
  {
    code: 'AX',
    length: '18',
    example: 'FI1410093000123458',
  },
  {
    code: 'FR',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'GF',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'GP',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'MQ',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'RE',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'PF',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'TF',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'YT',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'NC',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'BL',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'MF',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'PM',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'WF',
    length: '27',
    example: 'FR7630006000011234567890189',
  },
  {
    code: 'GE',
    length: '22',
    example: 'GE60NB0000000123456789',
  },
  {
    code: 'GI',
    length: '23',
    example: 'GI04BARC000001234567890',
  },
  {
    code: 'GL',
    length: '18',
    example: 'GL8964710123456789',
  },
  {
    code: 'GR',
    length: '27',
    example: 'GR9608100010000001234567890',
  },
  {
    code: 'GT',
    length: '28',
    example: 'GT20AGRO00000000001234567890',
  },
  {
    code: 'HU',
    length: '28',
    example: 'HU93116000060000000012345676',
  },
  {
    code: 'FO',
    length: '18',
    example: 'FO9264600123456789',
  },
  {
    code: 'VG',
    length: '24',
    example: 'VG21PACG0000000123456789',
  },
  {
    code: 'IQ',
    length: '23',
    example: 'IQ20CBIQ861800101010500',
  },
  {
    code: 'IE',
    length: '22',
    example: 'IE64IRCE92050112345678',
  },
  {
    code: 'IS',
    length: '26',
    example: 'IS030001121234561234567890',
  },
  {
    code: 'IL',
    length: '23',
    example: 'IL170108000000012612345',
  },
  {
    code: 'IT',
    length: '27',
    example: 'IT60X0542811101000000123456',
  },
  {
    code: 'JO',
    length: '30',
    example: 'JO71CBJO0000000000001234567890',
  },
  {
    code: 'KZ',
    length: '20',
    example: 'KZ563190000012344567',
  },
  {
    code: 'XK',
    length: '20',
    example: 'XK051212012345678906',
  },
  {
    code: 'KW',
    length: '30',
    example: 'KW81CBKU0000000000001234560101',
  },
  {
    code: 'SV',
    length: '28',
    example: 'SV43ACAT00000000000000123123',
  },
  {
    code: 'LV',
    length: '21',
    example: 'LV97HABA0012345678910',
  },
  {
    code: 'LB',
    length: '28',
    example: 'LB92000700000000123123456123',
  },
  {
    code: 'LI',
    length: '21',
    example: 'LI7408806123456789012',
  },
  {
    code: 'LT',
    length: '20',
    example: 'LT601010012345678901',
  },
  {
    code: 'LU',
    length: '20',
    example: 'LU120010001234567891',
  },
  {
    code: 'MK',
    length: '19',
    example: 'MK07200002785123453',
  },
  {
    code: 'MT',
    length: '31',
    example: 'MT31MALT01100000000000000000123',
  },
  {
    code: 'MU',
    length: '30',
    example: 'MU43BOMM0101123456789101000MUR',
  },
  {
    code: 'MR',
    length: '27',
    example: 'MR1300020001010000123456753',
  },
  {
    code: 'MD',
    length: '24',
    example: 'MD21EX000000000001234567',
  },
  {
    code: 'MC',
    length: '27',
    example: 'MC5810096180790123456789085',
  },
  {
    code: 'ME',
    length: '22',
    example: 'ME25505000012345678951',
  },
  {
    code: 'Non',
    length: '15',
    example: 'NO8330001234567',
  },
  {
    code: 'PK',
    length: '24',
    example: 'PK36SCBL0000001123456702',
  },
  {
    code: 'PS',
    length: '29',
    example: 'PS92PALS000000000400123456702',
  },
  {
    code: 'NL',
    length: '18',
    example: 'NL02ABNA0123456789',
  },
  {
    code: 'PL',
    length: '28',
    example: 'PL10105000997603123456789123',
  },
  {
    code: 'PT',
    length: '25',
    example: 'PT50002700000001234567833',
  },
  {
    code: 'QA',
    length: '29',
    example: 'QA54QNBA000000000000693123456',
  },
  {
    code: 'RO',
    length: '24',
    example: 'RO09BCYP0000001234567890',
  },
  {
    code: 'GB',
    length: '22',
    example: 'GB98MIDL07009312345678',
  },
  {
    code: 'IM',
    length: '22',
    example: 'GB98MIDL07009312345678',
  },
  {
    code: 'GG',
    length: '22',
    example: 'GB98MIDL07009312345678',
  },
  {
    code: 'JE',
    length: '22',
    example: 'GB98MIDL07009312345678',
  },
  {
    code: 'DO',
    length: '28',
    example: 'DO22ACAU00000000000123456789',
  },
  {
    code: 'CZ',
    length: '24',
    example: 'CZ5508000000001234567899',
  },
  {
    code: 'SM',
    length: '27',
    example: 'SM76P0854009812123456789123',
  },
  {
    code: 'LC',
    length: '32',
    example: 'LC14BOSL123456789012345678901234',
  },
  {
    code: 'ST',
    length: '25',
    example: 'ST23000200000289355710148',
  },
  {
    code: 'RS',
    length: '22',
    example: 'RS35105008123123123173',
  },
  {
    code: 'SC',
    length: '31',
    example: 'SC52BAHL01031234567890123456USD',
  },
  {
    code: 'SK',
    length: '24',
    example: 'SK8975000000000012345671',
  },
  {
    code: 'SI',
    length: '19',
    example: 'SI56192001234567892',
  },
  {
    code: 'CH',
    length: '21',
    example: 'CH5604835012345678009',
  },
  {
    code: 'SE',
    length: '24',
    example: 'SE7280000810340009783242',
  },
  {
    code: 'TL',
    length: '23',
    example: 'TL380010012345678910106',
  },
  {
    code: 'TN',
    length: '24',
    example: 'TN5904018104004942712345',
  },
  {
    code: 'TR',
    length: '26',
    example: 'TR320010009999901234567890',
  },
  {
    code: 'UA',
    length: '29',
    example: 'UA903052992990004149123456789',
  },
  {
    code: 'AE',
    length: '23',
    example: 'AE460090000000123456789',
  },
];

const IBAN_EXAMPLES = IBAN_DATA.map((data) => ({
  code: data.code,
  example: data.example,
}));

export const formatIbanForHuman = (iban: string) =>
  friendlyFormatIBAN(iban) ?? '';

export const formatIbanForMachine = (iban: string) =>
  electronicFormatIBAN(iban) ?? '';

export const ibanValidation = (iban: string) => {
  const formattedIban = formatIbanForMachine(iban) || '';
  return isValidIBAN(formattedIban);
};

export const isSupportedCountry = (countryCode: string) => {
  return IBAN_DATA.findIndex((country) => country.code === countryCode) !== -1;
};

export const getExampleNumber = (
  countryCode: string,
  examples: { code: string; example: string }[]
) => {
  return examples.find((example) => example.code === countryCode)?.example;
};

export const generateIbanPlaceholder = (countryCode: string) => {
  let placeholder = '';
  if (isSupportedCountry(countryCode)) {
    const ibanNumber = getExampleNumber(countryCode, IBAN_EXAMPLES);
    if (ibanNumber) placeholder = formatIbanForHuman(ibanNumber);
  }
  return placeholder;
};
