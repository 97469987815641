import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Icon from '@appchoose/icon';
import { useKeyboardEvent } from '@react-hookz/web';

import { useResetList } from '../../components/order-table/use-reset-list';
import { useSorting } from '../../components/order-table/use-sorting';
import { usePagination } from './use-pagination';
import { useSearch } from './use-search';

type OrderSearchProps = {
  isSearchActive: boolean;
};

export const OrderSearch: React.FC<OrderSearchProps> = ({ isSearchActive }) => {
  const { resetPagination } = usePagination();
  const { resetList } = useResetList();
  const { searchTerm, setSearchTerm } = useSearch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { resetSorting } = useSorting();
  const { t } = useTranslation();

  useKeyboardEvent('Escape', (e) => {
    const searchInput =
      (e.target as Element | null)?.nodeName === 'INPUT' &&
      (e.target as HTMLInputElement).type === 'search';
    // On active le raccourci seulement si on est sur l'input de recherche,
    // que la recherche est active et que le champ de recherche est vide
    if (isSearchActive && !searchTerm && searchInput) {
      resetList();
    }
  });

  if (!isSearchActive) {
    return null;
  }

  return (
    <div className="relative w-full rounded text-[#767A7C] transition-colors duration-300 focus-within:text-gray-900 sm:border-b sm:border-gray-100">
      <div className="absolute inset-y-0 left-0 flex items-center pl-2 sm:pl-0 [&:has(~_:disabled)]:text-gray-300">
        <Icon icon="search" />
      </div>
      <input
        type="search"
        className="no-cancel-search form-input w-full rounded border px-8 pb-[0.44rem] pt-2 text-sm leading-5.5 text-gray-900 outline-none hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 sm:border-none sm:px-6 sm:focus:ring-transparent"
        placeholder={t('orders.search.placeholder')}
        value={searchTerm}
        onChange={(e) => {
          resetPagination();
          resetSorting();

          setSearchTerm(e.target.value);
          setSearchParams((prev) => {
            prev.delete('after');
            prev.delete('before');
            prev.delete('sort');
            prev.set('q', e.target.value);
            return prev;
          });
        }}
        autoFocus
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-900 sm:pr-0 [&:has(~_:disabled)]:text-gray-300">
        <button type="button" onClick={() => resetList()}>
          <Icon icon="close" />
        </button>
      </div>
    </div>
  );
};
