'use client';

import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-between pt-1 relative items-center',
        caption_label:
          'capitalize flex items-center rounded outline-none gap-x-1 text-xl font-semibold',
        caption_dropdowns: 'pl-3 flex justify-center gap-x-2',
        dropdown: 'absolute top-0 appearance-none opacity-0 capitalize',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          'h-7 w-7 flex bg-transparent p-0 text-gray-900 items-center justify-center focus-visible:ring-2 focus-visible:ring-[#ECECEC] rounded-full'
        ),
        nav_button_previous: '',
        nav_button_next: '',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'uppercase text-gray-500 rounded-md w-9 font-semibold text-xs',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 text-center text-sm p-0 relative rounded [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: 'h-9 w-9 p-0 font-normal outline-none rounded focus-visible:ring-2 focus-visible:ring-[#ECECEC] disabled:cursor-default hover:bg-gray-100 disabled:hover:bg-transparent',
        day_range_end: 'day-range-end',
        day_selected: 'bg-green-900 rounded text-white hover:bg-green-900',
        day_today: 'bg-green-300',
        day_outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        vhidden: 'vhidden hidden',
        ...classNames,
      }}
      components={{
        IconLeft: () => <Icon icon="arrowDown" className="size-5 rotate-90" />,
        IconRight: () => (
          <Icon icon="arrowDown" className="size-5 -rotate-90" />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
