import { useSearchParams } from 'react-router-dom';

import Icon from '@appchoose/icon';

import { usePagination } from '../../views/orders-page/use-pagination';
import { useSearch } from '../../views/orders-page/use-search';

type OrderPaginationProps = {
  afterCursor?: string;
  beforeCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export const OrderPagination: React.FC<OrderPaginationProps> = ({
  afterCursor,
  beforeCursor,
  hasNextPage,
  hasPreviousPage,
}: OrderPaginationProps) => {
  const { setPagination } = usePagination();
  const { isSearchEmpty } = useSearch();
  const [, setSearchParams] = useSearchParams();

  if ((!hasNextPage && !hasPreviousPage) || isSearchEmpty) return null;

  return (
    <div className="mt-8 flex justify-end gap-4">
      <button
        type="button"
        className="rounded border border-gray-100 p-1 outline-none focus:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300"
        disabled={!hasPreviousPage}
        onClick={() => {
          setPagination({ beforeCursor: beforeCursor, afterCursor: undefined });
          setSearchParams((prev) => {
            prev.delete('after');
            prev.set('before', beforeCursor ?? '');

            return prev;
          });

          document.getElementById('scrollIntoView-element')?.scrollIntoView();
        }}
      >
        <Icon icon="arrowDown" size="large" className="rotate-90" />
      </button>
      <button
        type="button"
        className="rounded border border-gray-100 p-1 outline-none focus:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300"
        disabled={!hasNextPage}
        onClick={() => {
          setPagination({ beforeCursor: undefined, afterCursor: afterCursor });
          setSearchParams((prev) => {
            prev.delete('before');
            prev.set('after', beforeCursor ?? '');

            return prev;
          });

          document.getElementById('scrollIntoView-element')?.scrollIntoView();
        }}
      >
        <Icon icon="arrowDown" size="large" className="-rotate-90" />
      </button>
    </div>
  );
};
