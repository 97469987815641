import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { BrandMatchWithSubStep } from '../../../types/navigation';
import { getCorrectStep } from '../../../utils/utils';
import {
  OnboardingStepAuthSignup,
  OnboardingStepAuthSignupProps,
} from './onboarding-step-auth-signup';
import {
  OnboardingStepAuthUpdateProfile,
  OnboardingStepAuthUpdateProfileProps,
} from './onboarding-step-auth-update-profile';
import {
  OnboardingStepAuthConfirmPasswordlessCode,
  OnboardingStepAuthConfirmPasswordlessCodeProps,
} from './onboarding-step-confirm-passwordless-auth-code';

export type OnboardingStepAuthProps = {
  goToNextStep: () => void;
};

export const OnboardingStepAuth: React.FC<OnboardingStepAuthProps> = ({
  goToNextStep,
}: OnboardingStepAuthProps) => {
  const subSteps = [
    OnboardingStepAuthSignup,
    OnboardingStepAuthConfirmPasswordlessCode,
    OnboardingStepAuthUpdateProfile,
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<BrandMatchWithSubStep>();

  const [subStep, setSubStep] = useState(
    getCorrectStep(params.substep, subSteps.length - 1)
  );

  useUpdateEffect(() => {
    setSubStep(getCorrectStep(params.substep, subSteps.length - 1));
  }, [params.substep]);

  const goToNextSubStepOrStep = (email: string) => {
    if (subStep === subSteps.length - 1) {
      goToNextStep();
    } else {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('email', email);

      navigate(
        `/${params.brandId}/onboarding/${params.step ?? ''}/${subStep + 1}?${queryParams.toString()}`
      );
      setSubStep(subStep + 1);
    }
  };

  const Step = subSteps[subStep] as React.ElementType<
    | OnboardingStepAuthSignupProps
    | OnboardingStepAuthConfirmPasswordlessCodeProps
    | OnboardingStepAuthUpdateProfileProps
  >;

  return <Step goToNextSubStepOrStep={goToNextSubStepOrStep} />;
};
