import React from 'react';

import Icon from '@appchoose/icon';

import { ServiceOption } from '../../types/services';

export type ServiceViewProps = {
  service: ServiceOption;
  isSelected: boolean;
  children: React.ReactNode;
};

export const ServiceView: React.FC<ServiceViewProps> = ({
  service,
  isSelected,
  children,
}: ServiceViewProps) => {
  return (
    <div
      className={`relative flex h-20 w-20 cursor-pointer flex-col items-center justify-center rounded-lg transition duration-300 focus-within:ring-2 sm:h-[8.75rem] sm:w-[8.75rem] ${
        isSelected
          ? 'border-2 border-green-900 focus-within:ring-green-900/30'
          : 'border border-gray-500 focus-within:ring-gray-900/30 hover:border-gray-900'
      }`}
    >
      {children}
      {service.type && service.type !== 'custom' && (
        <>
          <img
            src={service.logo_url}
            alt={service.text}
            className="h-14 w-14 object-contain sm:h-25 sm:w-25"
          />
          <span className="sr-only">{service.text}</span>
        </>
      )}
      {!service.type ||
        (service.type === 'custom' && (
          <>
            <img
              src={service.image}
              alt=""
              className="h-14 w-14 object-contain sm:h-20 sm:w-20"
            />
            <span className="w-full truncate text-center text-xs font-semibold text-gray-900">
              {service.text}
            </span>
          </>
        ))}
      {isSelected && (
        <div className="absolute right-0 top-0 -mr-2 -mt-2 bg-white p-0.5">
          <div className="rounded-[0.1875rem] bg-green-900 p-0.5 text-white">
            <Icon icon="check" />
          </div>
        </div>
      )}
    </div>
  );
};
