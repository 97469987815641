import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { addDays } from 'date-fns';

import { brandState } from '../../stores/brand';
import { Widget } from '../../types/generated';
import { formatDayAndFullMonth } from '../../utils/date';
import { TextareaAutosize } from '../textarea-autosize/textarea-autosize';

import './duration-widget.scss';

type RefusalReasonForm = {
  refusalReason: string;
  refusalReasonComment: string;
};

export type DurationWidgetProps = {
  widget: Widget;
  endTime?: string;
  onRefuse: (
    id: string,
    reason: string,
    reasonComment?: string
  ) => Promise<boolean>;
  onConfirm: (id: string, additionnalDays: number) => Promise<boolean>;
};

export const DurationWidget: React.FC<DurationWidgetProps> = ({
  widget,
  endTime,
  onRefuse,
  onConfirm,
}: DurationWidgetProps) => {
  const { i18n, t } = useTranslation();
  const brand = useRecoilValue(brandState);

  const REFUSAL_REASONS = [
    {
      name: t('widgets.duration'),
      value: 'duration',
    },
    {
      name: t('widgets.stock'),
      value: 'stock',
    },
    {
      name: t('widgets.hr'),
      value: 'hr',
    },
    {
      name: t('widgets.others_events'),
      value: 'others_events',
    },
    {
      name: t('widgets.others'),
      value: 'others',
    },
  ];

  const [step, setStep] = useState(1);
  const [response, setResponse] = useState<boolean | null>(null);
  const [additionalDays, setAdditionalDays] = useState(-1);

  const form = useForm<RefusalReasonForm>({
    mode: 'onTouched',
    defaultValues: { refusalReason: '', refusalReasonComment: '' },
  });

  form.watch('refusalReason');

  const onSubmitRefusal = async (data: RefusalReasonForm) => {
    await onRefuse(
      widget.id,
      data.refusalReason,
      data.refusalReasonComment ? data.refusalReasonComment : undefined
    );
    setStep(3);
  };

  const onSubmitConfirm = async () => {
    await onConfirm(widget.id, additionalDays);
    setStep(3);
  };

  const onCancel = () => {
    setStep(1);
    setResponse(null);
    setAdditionalDays(-1);
  };

  const getEndTime = (additionalDays?: number) => {
    let endDate = endTime ? new Date(endTime) : new Date();
    if (additionalDays !== undefined) {
      endDate = addDays(endDate, additionalDays);
    }
    return t('widgets.end_at', {
      date: formatDayAndFullMonth(
        endDate,
        i18n.language === 'fr' ? 'fr' : 'en',
        brand?.timezone ?? undefined
      ),
    });
  };

  const renderStep1 = () => {
    const responseOptions = [
      {
        name: t('widgets.yes_title'),
        description: (
          <>
            <div className="text-gray-900">{t('widgets.yes_subtitle')}</div>
            {widget.proposition ? (
              <p className="mb-0 mt-4 flex items-center text-gray-500">
                {t('widgets.additional_days_description')}
              </p>
            ) : null}
          </>
        ),
        icon: <Icon icon="check" size="large" />,
        value: 'true',
      },
      {
        name: t('widgets.no_title'),
        description: getEndTime(),
        icon: <Icon icon="close" size="large" />,
        value: 'false',
      },
    ];

    return (
      <>
        <h3>{t('widgets.duration_title')}</h3>
        <div>
          <RadioGroupPrimitive.Root
            value={response?.toString()}
            onValueChange={(value: string) => {
              setResponse(value === 'true');
              setStep(2);
            }}
            className="w-full space-y-4 sm:flex sm:gap-x-4 sm:space-y-0"
          >
            {responseOptions.map((option) => (
              <RadioGroupPrimitive.Item
                key={option.value}
                value={option.value}
                className="group relative w-full cursor-pointer rounded-lg border border-gray-300 bg-white p-6 hover:border-gray-400 focus:outline-none focus-visible:border-gray-400 focus-visible:ring-2 focus-visible:ring-gray-500/30 sm:flex sm:justify-between"
              >
                <div className="flex w-full gap-x-4">
                  {option.icon}
                  <div className="w-full space-y-1 text-left">
                    <p className="font-bold leading-5.5 text-gray-900">
                      {option.name}
                    </p>
                    <div className="text-xs">{option.description}</div>
                  </div>
                </div>
              </RadioGroupPrimitive.Item>
            ))}
          </RadioGroupPrimitive.Root>
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    return response ? renderStep2Confirm() : renderStep2Refusal();
  };

  const renderStep2Refusal = () => {
    return (
      <>
        <h3>{t('widgets.refused_title')}</h3>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmitRefusal)}
            className="border-y border-gray-300 py-6"
          >
            <FormField
              control={form.control}
              name="refusalReason"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem className="-mt-2">
                  <FormLabel className="sr-only">
                    {t('widgets.refusal_reason.label')}
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      className="gap-4"
                      {...field}
                      onValueChange={(value) => field.onChange(value)}
                    >
                      {REFUSAL_REASONS.map((option) => (
                        <div
                          className="group flex items-center space-x-3"
                          key={option.value}
                        >
                          <RadioGroupItem
                            key={option.value}
                            value={option.value}
                            id={`refusal-reason-${option.value}`}
                          />

                          <Label
                            htmlFor={`refusal-reason-${option.value}`}
                            className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                          >
                            {option.name}
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage match="required">
                    {t('widgets.refusal_reason.validation_errors.required')}
                  </FormMessage>
                </FormItem>
              )}
            />

            {form.getValues('refusalReason') === 'others' ? (
              <div className="ml-8 mt-3">
                <FormField
                  control={form.control}
                  name="refusalReasonComment"
                  rules={{
                    required: form.getValues('refusalReason') === 'others',
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">
                        {t('widgets.refusal_reason_comment.label')}
                      </FormLabel>
                      <FormControl>
                        <TextareaAutosize
                          placeholder={t(
                            'widgets.refusal_reason_comment.placeholder'
                          )}
                          {...field}
                          autoFocus
                        />
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'widgets.refusal_reason_comment.validation_errors.required'
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </div>
            ) : null}
            <div className="mt-6 flex space-x-4">
              <Button
                onClick={onCancel}
                type="button"
                appearance="outline"
                className="border-none text-green-900"
              >
                {t('widgets.return')}
              </Button>
              <Button type="submit" appearance="primary">
                {t('widgets.confirm')}
              </Button>
            </div>
          </form>
        </Form>
      </>
    );
  };

  const renderStep2Confirm = () => {
    const extendOptions = [
      {
        name: t('widgets.additional_daysWithCount', { count: 4 }),
        description: getEndTime(4),
        value: '4',
      },
      {
        name: t('widgets.additional_daysWithCount', { count: 7 }),
        description: getEndTime(7),
        value: '7',
        badge: (
          <div>
            <div className="inline-flex items-center rounded-full bg-green-300 px-2 py-1 text-xs font-semibold uppercase tracking-wider text-green-900">
              {t('widgets.recommended')}
            </div>
          </div>
        ),
      },
    ];

    return (
      <>
        <h3>{t('widgets.accepted_title')}</h3>
        <div>
          <RadioGroupPrimitive.Root
            value={additionalDays.toString()}
            onValueChange={(value) => setAdditionalDays(parseInt(value))}
            className="w-full space-y-4 sm:flex sm:gap-x-4 sm:space-y-0"
          >
            {extendOptions.map((option) => (
              <RadioGroupPrimitive.Item
                key={option.value}
                value={option.value}
                className="group relative w-full cursor-pointer rounded-lg border border-gray-300 bg-white p-6 hover:border-gray-400 focus:outline-none data-[state=checked]:border-gray-400 data-[state=checked]:ring-2 data-[state=checked]:ring-gray-500/30 sm:flex sm:justify-between"
              >
                <div className="flex w-full gap-x-4">
                  <div className="flex size-5.5 shrink-0 justify-center rounded-full border-2 border-gray-700 text-gray-700 group-focus:outline-none group-focus-visible:ring-2 group-focus-visible:ring-green-900/30 group-disabled:cursor-not-allowed group-disabled:opacity-50 group-[&[aria-invalid=true]]:border-red-600 group-data-[state=checked]:text-green-900">
                    <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="size-[13px] fill-current text-current transition duration-300"
                      >
                        <circle cx="12" cy="12" r="10" />
                      </svg>
                    </RadioGroupPrimitive.Indicator>
                  </div>

                  <div className="w-full space-y-1 text-left">
                    <p className="font-bold leading-5.5 text-gray-900">
                      {option.name}
                    </p>
                    <div className="text-xs">{option.description}</div>
                  </div>
                  {option.badge}
                </div>
              </RadioGroupPrimitive.Item>
            ))}
          </RadioGroupPrimitive.Root>
        </div>
        <div className="mt-6 flex space-x-4">
          <Button
            onClick={onCancel}
            type="button"
            appearance="outline"
            className="border-none"
            style={{ color: '#8A8D8F' }}
          >
            {t('widgets.cancel')}
          </Button>{' '}
          <Button
            type="button"
            onClick={onSubmitConfirm}
            disabled={additionalDays === -1}
            appearance="primary"
          >
            {t('widgets.confirm')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <section className="duration-widget rounded-lg border border-gray-300 p-8">
      {step < 3 ? (
        <div className="flex justify-between">
          <h2 className="flex items-center font-bold text-gray-900">
            <Icon icon="calendar" size="large" className="mr-2" />
            {t('widgets.duration_headtitle')}
          </h2>
          <div className="ml-2 flex shrink-0 self-center rounded-full bg-gray-50 px-2 py-1 text-sm font-semibold text-gray-500">
            {step} / 2
          </div>
        </div>
      ) : null}
      {step === 1 ? renderStep1() : null}
      {step === 2 ? renderStep2() : null}
    </section>
  );
};

DurationWidget.displayName = 'DurationWidget';
