import { useRecoilValue } from 'recoil';

import { activeSaleIdState } from '../../stores/sales';
import { useBillingQuery } from '../../types/generated-new';

export const useBilling = () => {
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const billing = useBillingQuery(
    {
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!activeSaleId,
      refetchOnWindowFocus: false,
      select: (result) => result.billingV2,
    }
  );

  return billing;
};
