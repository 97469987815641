import { atom, selector } from 'recoil';

import { StockQuery, Variant } from '../types/generated';

export type StockProduct = NonNullable<
  NonNullable<StockQuery['products']>[number]
>;

export const productsState = atom<StockProduct[] | undefined>({
  key: 'productsState',
  default: undefined,
});

export enum ProductSort {
  name_asc,
  name_desc,
  stock_asc,
  stock_desc,
  sku_asc,
  sku_desc,
}

export const productSortState = atom<ProductSort>({
  key: 'productSortState',
  default: ProductSort.stock_asc,
});

export const foundVariantIdsState = atom<string[] | undefined>({
  key: 'foundVariantIdsState',
  default: undefined,
});

export type SortedVariant = Omit<Variant, 'stock'> & {
  productName: string;
  productImage: string | undefined;
  productType: string;
  stock: number;
};

export const sortedVariantsState = selector({
  key: 'sortedVariantsState',
  get: ({ get }) => {
    const list = get(productsState);

    if (!list) return undefined;

    const sort = get(productSortState);
    const foundVariantIds = get(foundVariantIdsState);

    const flattenedVariants: SortedVariant[] = [];

    list.forEach((product) => {
      if (!Array.isArray(product.variants)) {
        return;
      }

      product.variants
        .filter((variant): variant is Variant => !!variant)
        .filter((variant) =>
          foundVariantIds ? foundVariantIds.includes(variant.id) : true
        )
        .forEach((variant) => {
          const sanitizedProductImages = product.images?.filter(
            (image): image is string => !!image
          );
          const productImage =
            Array.isArray(sanitizedProductImages) &&
            sanitizedProductImages.length > 0
              ? sanitizedProductImages[0]
              : undefined;

          const sanitizedVariantImages = variant?.images?.filter(
            (image): image is string => !!image
          );
          const variantOrProductImages =
            Array.isArray(sanitizedVariantImages) &&
            sanitizedVariantImages.length > 0
              ? sanitizedVariantImages
              : productImage
                ? [productImage]
                : [];

          flattenedVariants.push({
            ...variant,
            productName: product.name ?? '',
            images: variantOrProductImages,
            productImage,
            productType: product.type ?? '',
            stock: typeof variant.stock === 'number' ? variant.stock : 1000,
          });
        });
    });

    if (sort === ProductSort.stock_asc || sort === ProductSort.stock_desc) {
      const sortDirection = sort === ProductSort.stock_asc ? -1 : 1;
      return flattenedVariants.sort((a, b) => {
        return a.stock > b.stock ? -1 * sortDirection : 1 * sortDirection;
      });
    }

    if (sort === ProductSort.name_asc || sort === ProductSort.name_desc) {
      const sortDirection = sort === ProductSort.name_asc ? 1 : -1;
      return flattenedVariants.sort((a, b) => {
        return (
          a.productName
            .toLowerCase()
            .localeCompare(b.productName.toLowerCase()) * sortDirection
        );
      });
    }

    if (sort === ProductSort.sku_asc || sort === ProductSort.sku_desc) {
      const sortDirection = sort === ProductSort.sku_asc ? 1 : -1;
      return flattenedVariants.sort((a, b) => {
        return (
          (a.sku ?? '')
            .toLowerCase()
            .localeCompare((b.sku ?? '').toLowerCase(), 'en', {
              numeric: true,
            }) * sortDirection
        );
      });
    }
  },
});
