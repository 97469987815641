import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import Tooltip from '@appchoose/tooltip';
import { onlineManager } from '@tanstack/react-query';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { brandState } from '../../stores/brand';
import {
  OrderTagClaim,
  OrderTagFulfillment,
  OrderTagRefund,
  OrderTagReship,
  OrderTagReturn,
  OrderTagShipment,
  TrackingSubStatus,
} from '../../types/generated';
import { OrderTagClaimReason } from '../../types/generated-new';
import { transformPrice } from '../../utils/currency';
import { transformDate } from '../../utils/date';
import { usePagination } from '../../views/orders-page/use-pagination';
import { useSearch } from '../../views/orders-page/use-search';
import { TabEntry, useTabs } from '../../views/orders-page/use-tabs';
import { Sort } from '../icons/sort';
import { SortBottom } from '../icons/sort-bottom';
import { SortTop } from '../icons/sort-top';
import { OrderClaimBadge } from '../order-status-badge/order-claim-badge';
import { OrderClaimReasonBadge } from '../order-status-badge/order-claim-reason-badge';
import { OrderFulfillmentBadge } from '../order-status-badge/order-fulfillment-badge';
import { OrderRefundBadge } from '../order-status-badge/order-refund-badge';
import { OrderReshipBadge } from '../order-status-badge/order-reship-badge';
import { OrderReturnBadge } from '../order-status-badge/order-return-badge';
import { OrderShipmentBadge } from '../order-status-badge/order-shipment-badge';
import { OrderCardRow } from './order-card-row';
import { OrderCardSkeleton } from './order-card-skeleton';
import { OrderItemImages } from './order-item-images';
import { OrderTableError } from './order-table-error';
import { OrderTableNoOrder } from './order-table-no-order';
import { OrderTableNoResult } from './order-table-no-result';
import { OrderTableOffline } from './order-table-offline';
import { OrderTableRow } from './order-table-row';
import { OrderTableSkeleton } from './order-table-skeleton';
import { useFixedHeader } from './use-fixed-header';
import { useSorting } from './use-sorting';

import './order-table.css';

export type OrderDataImage = {
  imageUrl: string;
  quantity: number;
};

export type OrderData = {
  claim: OrderTagClaim[];
  claimReason: OrderTagClaimReason[];
  createdAt: Date;
  fulfillment: OrderTagFulfillment;
  hasSynchronizationError: boolean;
  id: string;
  items: OrderDataImage[];
  parcelTrackingSubStatus: TrackingSubStatus | null;
  parcelHasTrackingStucked: boolean;
  recipientName: string;
  refund: OrderTagRefund;
  requiredAction: boolean;
  reship: OrderTagReship;
  return: OrderTagReturn[];
  shipment: OrderTagShipment[];
  total: {
    amount: number;
    currency: string;
  };
};

type OrderTableProps = {
  data: OrderData[];
  error: unknown;
  isLoading: boolean;
};

const SKELETON_DATA = Array.from({ length: 30 }, (e, i) => i);

export const OrderTable: React.FC<OrderTableProps> = ({
  data,
  error,
  isLoading,
}) => {
  const { resetPagination } = usePagination();
  const { isSearchActive, isSearchEmpty } = useSearch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { sorting, setSorting, trackSorting } = useSorting();
  const { selectedTab } = useTabs();
  const { i18n, t } = useTranslation();

  const brand = useRecoilValue(brandState);

  const tableRef = useRef<HTMLTableElement>(null);

  const columns: ColumnDef<OrderData>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: () => (
          <div className="p-4 text-left">{t('orders.table.id')}</div>
        ),
        cell: ({ row }) => (
          <div
            className={cn(
              'flex items-center gap-x-1 text-xs text-green-900 sm:w-[136px] sm:p-4 sm:text-sm',
              {
                'font-bold': row.original.requiredAction,
                'text-red-600': row.original.hasSynchronizationError,
              }
            )}
          >
            {row.original.hasSynchronizationError ? (
              <Icon icon="shopifyError" className="shrink-0" />
            ) : null}
            <div className="truncate">{row.getValue('id')}</div>
          </div>
        ),
        enableSorting: false,
        size: 136,
      },
      {
        accessorKey: 'createdAt',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.date_asc')
                  : t('orders.table.headers.sort.date_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.created_at')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div
            className={cn('flex items-center text-xs sm:p-4 sm:text-sm', {
              'text-gray-700': row.original.requiredAction,
              'text-[#9DA0A1]': !row.original.requiredAction,
            })}
          >
            {transformDate(
              row.getValue('createdAt'),
              i18n?.language === 'fr' ? 'fr' : 'en',
              brand?.timezone ?? undefined
            )}
          </div>
        ),
      },
      {
        accessorKey: 'recipientName',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.alphanumeric_asc')
                  : t('orders.table.headers.sort.alphanumeric_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.customer')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div
            className={cn(
              'flex items-center font-semibold sm:w-[144px] sm:p-4 sm:font-normal',
              {
                'text-gray-700': row.original.requiredAction,
                'text-[#9DA0A1]': !row.original.requiredAction,
              }
            )}
          >
            <div className="truncate">{row.getValue('recipientName')}</div>
          </div>
        ),
        size: 144,
      },
      {
        accessorKey: 'items',
        header: () => (
          <div className="p-4 text-left">{t('orders.table.items')}</div>
        ),
        cell: ({ row }) => (
          <div className="flex flex-row flex-nowrap gap-x-1 sm:p-4">
            <OrderItemImages items={row.original.items} />
          </div>
        ),
        enableSorting: false,
        size: 144,
      },
      {
        accessorKey: 'total',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.amount_asc')
                  : t('orders.table.headers.sort.amount_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-right hover:bg-gray-50">
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
              <span>{t('orders.table.total')}</span>
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div
            className={cn('flex flex-col items-end text-sm sm:p-4', {
              'text-gray-700': row.original.requiredAction,
              'text-[#9DA0A1]': !row.original.requiredAction,
            })}
          >
            {transformPrice(
              row.original.total.amount,
              i18n.language === 'fr' ? 'fr' : 'en',
              row.original.total.currency
            )}
          </div>
        ),
      },
      {
        accessorKey: 'fulfillment',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.alphanumeric_asc')
                  : t('orders.table.headers.sort.alphanumeric_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.fulfillment')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div className="flex items-center sm:p-4">
            <OrderFulfillmentBadge tag={row.original.fulfillment} />
          </div>
        ),
      },
      {
        accessorKey: 'shipment',
        header: () => (
          <div className="p-4 text-left">{t('orders.table.delivery')}</div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center sm:p-4">
            {row.original.shipment.map((shipment) => (
              <OrderShipmentBadge
                key={shipment}
                tag={shipment}
                trackingSubStatus={row.original.parcelTrackingSubStatus}
                hasTrackingStucked={row.original.parcelHasTrackingStucked}
              />
            ))}
          </div>
        ),
        enableSorting: false,
      },
      {
        accessorKey: 'claim',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.alphanumeric_asc')
                  : t('orders.table.headers.sort.alphanumeric_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.claim')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div className="flex items-center whitespace-nowrap sm:p-4">
            {row.original.claim.map((claim) => (
              <OrderClaimBadge key={claim} tag={claim} />
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'claimReason',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.alphanumeric_asc')
                  : t('orders.table.headers.sort.alphanumeric_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.claim_reason')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div className="flex items-center whitespace-nowrap sm:p-4">
            {row.original.claimReason.map((claimReason) => (
              <OrderClaimReasonBadge key={claimReason} tag={claimReason} />
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'refund',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.alphanumeric_asc')
                  : t('orders.table.headers.sort.alphanumeric_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.refund')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div className="flex items-center whitespace-nowrap sm:p-4">
            <OrderRefundBadge tag={row.original.refund} />
          </div>
        ),
      },
      {
        accessorKey: 'reship',
        header: ({ header }) => (
          <Tooltip
            offset={[0, 16]}
            content={
              <div className="p-1">
                {header.column.getIsSorted() === 'asc'
                  ? t('orders.table.headers.sort.alphanumeric_asc')
                  : t('orders.table.headers.sort.alphanumeric_desc')}
              </div>
            }
          >
            <div className="group flex cursor-pointer justify-between gap-4 p-4 text-left hover:bg-gray-50">
              <span>{t('orders.table.reship')}</span>
              {header.column.getIsSorted() === 'asc' ? (
                <SortTop className="size-4" />
              ) : null}
              {header.column.getIsSorted() === 'desc' ? (
                <SortBottom className="size-4" />
              ) : null}
              <Sort
                className={cn('invisible size-4 group-hover:visible', {
                  hidden: header.column.getIsSorted() !== false,
                })}
              />
            </div>
          </Tooltip>
        ),
        cell: ({ row }) => (
          <div className="flex items-center whitespace-nowrap sm:p-4">
            <OrderReshipBadge tag={row.original.reship} />
          </div>
        ),
      },
      {
        accessorKey: 'return',
        header: () => (
          <div className="p-4 text-left">{t('orders.table.return')}</div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center whitespace-nowrap sm:p-4">
            {row.original.return.map((ret) => (
              <OrderReturnBadge key={ret} tag={ret} />
            ))}
          </div>
        ),
        enableSorting: false,
      },
    ],
    [brand?.timezone, i18n.language, t]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnPinning: { left: ['id'] },
      sorting,
    },
    enableColumnPinning: true,
    enableSortingRemoval: false,
    manualSorting: true,
    sortDescFirst: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (updater) => {
      // make sure updater is callable (to avoid typescript warning)
      if (typeof updater !== 'function') return;

      const newSorting = updater(table.getState().sorting);
      setSorting(newSorting);
      resetPagination();
      trackSorting(newSorting);

      setSearchParams((prev) => {
        prev.delete('after');
        prev.delete('before');
        prev.set('sort', JSON.stringify(newSorting));

        return prev;
      });
    },
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (tableRef.current === null) return;

    if (
      event.currentTarget.scrollLeft > 0 &&
      tableRef.current?.getAttribute('data-scroll') !== 'true'
    ) {
      tableRef.current?.setAttribute('data-scroll', 'true');
    }
    if (
      event.currentTarget.scrollLeft === 0 &&
      tableRef.current?.getAttribute('data-scroll') !== 'false'
    ) {
      tableRef.current?.setAttribute('data-scroll', 'false');
    }
  };

  const [tableWrapperRef, headerRef] = useFixedHeader();

  return (
    <>
      <div id="scrollIntoView-element"></div>
      <div className="hidden overflow-hidden rounded border border-gray-100 sm:block">
        <div
          ref={tableWrapperRef}
          className="no-scrollbar w-full overflow-x-auto"
          onScroll={handleScroll}
        >
          <table ref={tableRef} className="w-full whitespace-nowrap">
            {/* Table header */}
            <thead className="sticky z-[2]" ref={headerRef}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} data-table-row>
                  {/* Table row */}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      data-table-cell
                      className={cn(
                        'sticky shrink-0 items-center bg-white text-xs font-semibold uppercase text-gray-700',
                        {
                          'z-[1]': header.column.getIsPinned(),
                          'shadow-[inset_0_-1px_0_#d8d9da]':
                            !isSearchEmpty && table.getRowModel().rows?.length,
                        }
                      )}
                      style={{
                        width: header.column.getIsPinned()
                          ? header.column.getSize()
                          : undefined,
                        top: 0,
                        left: header.column.getIsPinned()
                          ? header.column.getStart()
                          : undefined,
                      }}
                      {...(header.column.getCanSort()
                        ? { onClick: header.column.getToggleSortingHandler() }
                        : {})}
                    >
                      {/* Table cell */}
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Table body */}
            <tbody>
              {isLoading && !error && !isSearchEmpty
                ? SKELETON_DATA.map((row) => <OrderTableSkeleton key={row} />)
                : null}
              {!isLoading &&
              !error &&
              !isSearchEmpty &&
              table.getRowModel().rows?.length
                ? table
                    .getRowModel()
                    .rows.map((row) => <OrderTableRow key={row.id} row={row} />)
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <ul className="block space-y-3 sm:hidden">
        {isLoading && !error && !isSearchEmpty
          ? SKELETON_DATA.map((row) => <OrderCardSkeleton key={row} />)
          : null}
        {!isLoading &&
        !error &&
        !isSearchEmpty &&
        table.getRowModel().rows?.length
          ? table
              .getRowModel()
              .rows.map((row) => <OrderCardRow key={row.id} row={row} />)
          : null}
      </ul>
      {!onlineManager.isOnline() ? <OrderTableOffline /> : null}
      {!isLoading &&
      !error &&
      onlineManager.isOnline() &&
      !isSearchEmpty &&
      selectedTab !== TabEntry.Filters &&
      !isSearchActive &&
      !table.getRowModel().rows?.length ? (
        <OrderTableNoOrder />
      ) : null}
      {!isLoading &&
      !error &&
      onlineManager.isOnline() &&
      !isSearchEmpty &&
      selectedTab === TabEntry.Filters &&
      !table.getRowModel().rows?.length ? (
        <OrderTableNoResult type="filter" />
      ) : null}
      {!isLoading &&
      !error &&
      onlineManager.isOnline() &&
      !isSearchEmpty &&
      isSearchActive &&
      !table.getRowModel().rows?.length ? (
        <OrderTableNoResult type="search" />
      ) : null}
      {!isLoading && !isSearchEmpty && error ? <OrderTableError /> : null}
    </>
  );
};
