export const transformPrice = (
  price: number | undefined,
  locale: string,
  currency: string
) => {
  const value = typeof price === 'number' ? price / 100 : 0;
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 0,
    }).format(value);
  } catch (e) {
    // Support pour les vieux navigateurs
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
    }).format(value);
  }
};

export const formatPrice = (
  value: number,
  locale: string,
  currency: string
) => {
  try {
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  } catch (e) {
    // Support pour les vieux navigateurs
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
};
