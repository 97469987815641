import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard, useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import { Form, FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import { useInvoiceUploadMutation } from '../../api/graphql';
import { Confetti } from '../../components/icons/confetti';
import { InvoiceFileUpload } from '../../components/invoice-file-upload';
import { useInvoiceCreatedSubscription } from '../../hooks/use-invoice-created-subscription';
import { useInvoiceUpdatedSubscription } from '../../hooks/use-invoice-updated-subscription';
import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreRegion } from '../../types/generated';
import {
  InvoiceStatus,
  InvoicesQuery,
  useInvoicesQuery,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { formatPrice } from '../../utils/currency';
import { InvoiceList } from './invoice-list';
import { useBilling } from './use-billing-stats';

type InvoiceFormData = {
  file: File;
};

type InvoiceInformationProps = {
  isSubjectVat: boolean;
};

export const InvoiceInformation: React.FC<InvoiceInformationProps> = ({
  isSubjectVat,
}: InvoiceInformationProps) => {
  const { data: billing } = useBilling();
  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);
  const queryClient = useQueryClient();
  const { data: invoices } = useInvoicesQuery(
    {
      saleId: activeSale?.id ?? '',
    },
    {
      select: (data) => data?.invoices ?? [],
    }
  );
  const { i18n, t } = useTranslation();
  const form = useForm<InvoiceFormData>({
    mode: 'onTouched',
  });
  const [invoiceUploadMutation, { loading, progress }] =
    useInvoiceUploadMutation();
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  useUpdateEffect(() => {
    //TODO: handle errors
    toast.success(t('invoice.invoice_information.email_copied'));
  }, [clipboardState]);

  useInvoiceCreatedSubscription();
  useInvoiceUpdatedSubscription();

  const onSubmit = async (data: InvoiceFormData) => {
    try {
      await invoiceUploadMutation({
        file: data.file,
        saleId: activeSale?.id ?? '',
      });

      toast.success(t('invoice.invoice_information.upload_success'));

      form.reset();

      queryClient.setQueryData(
        ['invoices', { saleId: activeSale?.id ?? '' }],
        (oldData: InvoicesQuery) => ({
          invoices: [
            ...(oldData?.invoices ?? []),
            {
              __typename: 'Invoice',
              createdAt: new Date().toISOString(),
              fileName: data.file.name,
              fileUrl: '',
              id: self.crypto.randomUUID(),
              status: InvoiceStatus.BotProcessing,
            },
          ],
        })
      );
    } catch {
      toast.error(t('invoice.invoice_information.upload_error'));
      form.reset();
    }
  };

  const remainingAmount = isSubjectVat
    ? (billing?.total_remaining_ttc ?? 0)
    : (billing?.total_remaining_ht ?? 0);
  const hasInvoices = (invoices?.length ?? 0) > 0;
  const contactUsEmail =
    brand?.store === StoreRegion.Fr
      ? 'facture@appchoose.io'
      : 'invoice@appchoose.io';

  form.watch('file');

  return (
    <div className="space-y-6">
      {remainingAmount <= 0 ? (
        <div className="flex flex-col justify-between space-x-6 rounded border border-[#C6E7D5] bg-[#ECF7F1] p-6 md:flex-row">
          <div className="flex w-full items-start space-x-4 pb-4 md:pb-0">
            <div className="rounded-full text-green-900">
              <Confetti className="text-green-600" />
            </div>
            <div className="w-full space-y-1">
              <div className="text-sm font-semibold text-gray-700">
                {t('invoice.invoice_information.your_operation_fully_paid')}
              </div>
              <p className="text-sm text-gray-700">
                {t('invoice.invoice_information.see_you_soon')}
              </p>
            </div>
          </div>
        </div>
      ) : null}

      <div className="space-y-4 rounded-lg border border-gray-100 p-6">
        <div
          className={cn({
            'opacity-50': loading,
          })}
        >
          <div className="flex justify-between">
            <h2 className="text-lg font-bold text-gray-900">
              {hasInvoices
                ? t('invoice.invoice_information.my_invoices')
                : t('invoice.invoice_information.import_an_invoice')}
            </h2>
            <p className="text-3.5xl font-bold text-gray-900">
              {formatPrice(
                remainingAmount / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                activeSale?.currency?.toString()?.toUpperCase() ?? 'EUR'
              )}
            </p>
          </div>
          <div className="flex justify-between">
            <div className="flex space-x-1 text-sm text-gray-700">
              <span>{t('invoice.invoice_information.need_help')}</span>
              <span>{t('invoice.invoice_information.contact_us_at')}</span>
              <button
                type="button"
                className="flex items-center space-x-1 font-semibold text-green-900"
                onClick={() => {
                  copyToClipboard(contactUsEmail);
                  track('CopyToClipboard', {
                    label: 'billing_email',
                  });
                }}
              >
                <span>{contactUsEmail}</span>
                <Icon icon="copy" className="size-3.5 shrink-0" />
              </button>
            </div>
            <div className="text-sm text-gray-700">
              {isSubjectVat
                ? t('invoice.invoice_information.remainder_be_invoiced_ttc')
                : t('invoice.invoice_information.remainder_be_invoiced_ht')}
            </div>
          </div>
        </div>
        <InvoiceList invoices={invoices} />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InvoiceFileUpload
                      isUploading={loading}
                      progress={progress}
                      value={field.value}
                      onValueChange={(file) => {
                        field.onChange(file);
                        if (file) form.handleSubmit(onSubmit)();
                      }}
                      variant={hasInvoices ? 'small' : 'default'}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
    </div>
  );
};
