import Intercom, { show, showNewMessage } from '@intercom/messenger-js-sdk';
import type { UserArgs } from '@intercom/messenger-js-sdk/dist/types';

// See https://developers.intercom.com/installing-intercom/web/attributes-objects#company-object
type IntercomCompany = {
  // Mandatory company ID
  company_id: string;
  // Company name
  name: string;
  // Custom company attribute for AM
  contact_person: string;
  // Custom company attribute for AM
  account_manager: string;
};

const INTERCOM_APP_ID = 'beay1jv0';

export const initIntercom = (
  initInfos: UserArgs & {
    // Used for identity verification
    user_hash: string;
    company: IntercomCompany;
  }
) => {
  Intercom({
    app_id: INTERCOM_APP_ID,
    hide_default_launcher: true,
    ...initInfos,
  });
};

export const openIntercomWithPrePopulatedContent = (
  prePopulatedContent: string
) => {
  showNewMessage(prePopulatedContent);
};

export const openIntercom = () => {
  show();
};
