import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import * as Sentry from '@sentry/react';

export const CodeBlockHeader: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="rounded-t bg-gray-700 px-4 py-2 text-xs font-semibold uppercase text-gray-300">
      {children}
    </div>
  );
};

export const CodeBlockContent: FC<{ content: string; secret?: boolean }> = ({
  content,
  secret = false,
}) => {
  const { t } = useTranslation();
  const [isContentDisplayed, setIsContentDisplayed] = useState(false);
  const [contentClipboardState, copyContentToClipboard] = useCopyToClipboard();
  const contentToShow =
    !secret || isContentDisplayed ? content : '•'.repeat(content.length);

  useEffect(() => {
    const { value, error } = contentClipboardState;
    if (value) {
      toast.success(t('api.copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [contentClipboardState, t]);

  return (
    <div className="flex items-center justify-between text-sm text-white">
      {secret ? (
        <button
          type="button"
          onClick={() => setIsContentDisplayed((prev) => !prev)}
          className="flex flex-1 items-center justify-normal rounded-bl hover:bg-[#767A7C]"
        >
          <pre className="flex-1 p-4 text-left">{contentToShow}</pre>
          <Icon
            icon={isContentDisplayed ? 'eyeOff' : 'eye'}
            className="m-4 size-5 shrink-0"
          />
        </button>
      ) : (
        <pre className="p-4">{contentToShow}</pre>
      )}

      <button
        type="button"
        onClick={() => copyContentToClipboard(content)}
        className="rounded-br p-4 font-semibold hover:bg-[#767A7C]"
      >
        {t('copy')}
      </button>
    </div>
  );
};

export const CodeBlock: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="rounded bg-gray-600">{children}</div>;
};
