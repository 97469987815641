import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMiniSearch } from 'react-minisearch';
import { useTitle, useUpdateEffect } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  ButtonDropdown,
  ButtonDropdownButton,
  ButtonDropdownMenuContent,
  ButtonDropdownMenuItem,
  ButtonDropdownMenuTrigger,
} from '@appchoose/button-dropdown';
import Icon from '@appchoose/icon';
import Illustration from '@appchoose/illustration';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';

import { useProductStockUpdatedSubscription } from '../../hooks/use-product-stock-updated-subscription';
import { brandState } from '../../stores/brand';
import {
  StockProduct,
  foundVariantIdsState,
  productsState,
} from '../../stores/product';
import { activeSaleIdState } from '../../stores/sales';
import { ProductsSort, Variant, useStockQuery } from '../../types/generated';
import { FileExtension, useStocksExportQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import {
  ProductIndex,
  SEARCH_PRODUCT_CONFIG,
  getSearchQuery,
} from '../../utils/product';
import { StockProductList } from './stock-product-list';
import { StockSearch, useSearch } from './stock-search';

import './stock-screen.scss';

export const StockScreen: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();
  const { debouncedSearchTerm } = useSearch();

  const activeSaleId = useRecoilValue(activeSaleIdState);
  const [products, setProducts] = useRecoilState(productsState);
  const setFoundVariantIds = useSetRecoilState(foundVariantIdsState);

  const pageRef = useRef<HTMLDivElement>(null);

  const { search, searchResults, addAll, removeAll } = useMiniSearch(
    [],
    SEARCH_PRODUCT_CONFIG
  );

  useTitle(`${brand?.name ?? ''} ~ Stock`);

  useProductStockUpdatedSubscription();

  const [value, setValue] = useState('export-csv');
  const menuOptions = [
    {
      label: t('stock.export.export_stocks_as_csv'),
      value: 'export-csv',
      onClick: async () => {
        const result = await refetchStocksExport();
        if (result.data) {
          if (result.data.stocksExport.fileUrl) {
            window.open(result.data.stocksExport.fileUrl, '_self');
            toast.success(result.data.stocksExport.fileUrl);
          } else {
            toast.success(t('stock.export.no_stock'));
          }
        }
        track('ExportStock', {
          format: 'csv',
        });
      },
    },
    {
      label: t('stock.export.export_stocks_as_xls'),
      value: 'export-xls',
      onClick: async () => {
        const result = await refetchStocksExport();
        if (result.data) {
          if (result.data.stocksExport.fileUrl) {
            window.open(result.data.stocksExport.fileUrl, '_self');
            toast.success(result.data.stocksExport.fileUrl);
          } else {
            toast.success(t('stock.export.no_stock'));
          }
        }
        track('ExportStock', {
          format: 'xls',
        });
      },
    },
  ];

  const selectedMenuOption = menuOptions.find(
    (option) => option.value === value
  );
  const { isFetching: loadingStocksExport, refetch: refetchStocksExport } =
    useStocksExportQuery(
      {
        saleId: activeSaleId ?? '',
        input: {
          fileExtension:
            value === 'export-csv' ? FileExtension.Csv : FileExtension.Xlsx,
        },
      },
      {
        enabled: false,
      }
    );

  const { data: stockData, isLoading: loadingStock } = useStockQuery(
    {
      saleId: activeSaleId ?? '',
      sort: ProductsSort.Name,
    },
    {
      enabled: !!activeSaleId,
      select: (data) =>
        data.products?.filter(
          (product): product is StockProduct => !!product
        ) ?? [],
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (stockData) {
      setProducts(stockData);

      const index: ProductIndex[] = [];

      stockData.forEach((product) => {
        product.variants
          ?.filter((variant): variant is Variant => !!variant)
          ?.forEach((variant) => {
            index.push({
              id: variant.id,
              name: product.name ?? '',
              sku: variant.sku ?? '',
              options: variant.options.join(' - ').toLowerCase(),
            });
          });
      });

      removeAll();
      addAll(index);
    }
  }, [stockData]);

  useUpdateEffect(() => {
    const searchTerm = debouncedSearchTerm ?? '';
    if (searchTerm) {
      search({
        prefix: true,
        queries: getSearchQuery(searchTerm),
      });
    } else {
      setFoundVariantIds(undefined);
    }
  }, [debouncedSearchTerm]);

  useUpdateEffect(() => {
    setFoundVariantIds(
      searchResults?.map((searchResult): string => searchResult.id) ?? []
    );
  }, [searchResults]);

  return (
    <main
      ref={pageRef}
      className="stock relative flex-1 overflow-y-auto focus:outline-none"
    >
      <div className="m-4 md:m-10">
        <header className="flex items-center justify-between">
          <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
            {t('sidebar.stock')}
          </h2>
          <ButtonDropdown value={value} onValueChange={setValue}>
            <ButtonDropdownButton
              type="button"
              onClick={selectedMenuOption?.onClick}
              disabled={loadingStocksExport}
            >
              {selectedMenuOption?.label}
            </ButtonDropdownButton>
            <ButtonDropdownMenuTrigger disabled={loadingStocksExport}>
              <Icon icon="dropdown" />
            </ButtonDropdownMenuTrigger>
            <ButtonDropdownMenuContent>
              {menuOptions.map((option) => (
                <ButtonDropdownMenuItem key={option.value} value={option.value}>
                  {option.label}
                </ButtonDropdownMenuItem>
              ))}
            </ButtonDropdownMenuContent>
          </ButtonDropdown>
        </header>

        <p className="mb-10 mt-4 flex items-center space-x-2 text-sm text-gray-700">
          <Icon icon="information" className="shrink-0" />
          <span>{t('stock.info')}</span>
        </p>

        <section>
          {loadingStock ? (
            <div className="mb-8 flex justify-center">
              <Loader className="size-8" />
            </div>
          ) : products?.length === 0 ? (
            <div className="text-center">
              <div className="mt-28 flex justify-center">
                <Illustration illustration="inventory" />
              </div>
              <h3 className="my-3.5 text-2xl font-bold text-gray-900">
                {t('stock.empty_title')}
              </h3>
              <p className="text-sm text-gray-700">{t('stock.empty_text')}</p>
            </div>
          ) : (
            <>
              <div className="mb-6">
                <div className="relative rounded-md text-gray-500 transition-all focus-within:text-gray-900">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                    <Icon icon="search" />
                  </div>
                  <StockSearch />
                </div>
              </div>

              <StockProductList pageRef={pageRef} />
            </>
          )}
        </section>
      </div>
    </main>
  );
};

StockScreen.displayName = 'StockScreen';
