import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useHandleHash = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash.startsWith('#/')) {
      window.location.href = location.hash.replace('#', '');
    }
  }, []);
};

export default useHandleHash;
