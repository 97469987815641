import React from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import { ProfileInfos } from '../../components/profile-infos/profile-infos';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import { ProfileMenu } from './profile-menu';

export const LimitedSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader>
        <ProfileMenu />
      </XChooseHeader>
      <main className="pt-10">
        <h2 className="mb-10 text-4.25xl font-bold text-gray-900">
          {t('auth.profile_menu.settings')}
        </h2>
        <ProfileInfos
          onPostSave={() => {
            toast.success(t('saved'));
          }}
        />
      </main>
    </div>
  );
};
