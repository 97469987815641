import React, { useImperativeHandle } from 'react';
import { SubmitHandler, UseFormGetValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input from '@appchoose/input';

import { OnboardingStepShippingDeliveryServiceForm } from '../onboarding/onboarding-step-shipping/onboarding-step-shipping-delivery-service';
import { OnboardingStepShippingShippingServiceForm } from '../onboarding/onboarding-step-shipping/onboarding-step-shipping-shipping-service';

type AddServiceProps = {
  getValues: UseFormGetValues<
    | OnboardingStepShippingDeliveryServiceForm
    | OnboardingStepShippingShippingServiceForm
  >;
  onSubmit: SubmitHandler<AddServiceForm>;
};

export type AddServiceForm = {
  service: string;
};

export type AddServiceRef = {
  setInputFocus: () => void;
  initForm: () => void;
};

export const AddService = React.forwardRef<AddServiceRef, AddServiceProps>(
  (
    { getValues, onSubmit }: AddServiceProps,
    ref: React.ForwardedRef<AddServiceRef>
  ) => {
    const { t } = useTranslation();
    const form = useForm<AddServiceForm>({
      mode: 'onTouched',
    });

    useImperativeHandle(ref, () => ({
      setInputFocus() {
        form.setFocus('service');
      },
      initForm() {
        form.reset();
      },
    }));

    return (
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return form.handleSubmit(onSubmit)(e);
          }}
          className="flex items-start space-x-1"
        >
          <FormField
            control={form.control}
            name="service"
            rules={{
              required: true,
              validate: {
                alreadyExists: (value: string) => {
                  return !getValues('services')
                    .map((s) => s.text)
                    .includes(value.toLowerCase());
                },
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sr-only">
                  {t('service.new_delivery_service.label')}
                </FormLabel>
                <FormControl className="-mt-2">
                  <Input
                    type="text"
                    placeholder={t('search.delivery')}
                    {...field}
                  />
                </FormControl>
                <FormMessage match="required">
                  {t('service.new_delivery_service.validation_errors.required')}
                </FormMessage>
                <FormMessage match="alreadyExists">
                  {t(
                    'service.new_delivery_service.validation_errors.already_exists'
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
          <button type="submit" className="rounded-lg bg-gray-900">
            <Icon icon="check" size="large" className="m-2 text-white" />
          </button>
        </form>
      </Form>
    );
  }
);

AddService.displayName = 'AddService';
