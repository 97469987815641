import { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import passwordValidator from 'password-validator';

import { OnboardingUpdateProfileForm } from '../../views/onboarding-update-profile/onboarding-update-profile';
import { InputPassword } from '../settings-account/modal-change-password';

type PasswordValidatorError = {
  validation: string;
  arguments?: number;
  message: string;
};

export const PasswordFormField = ({ label }: { label?: string }) => {
  const { t } = useTranslation();

  const form = useFormContext<OnboardingUpdateProfileForm>();

  const schema = new passwordValidator()
    .is()
    .min(8)
    .has()
    .lowercase(1)
    .has()
    .uppercase(1)
    .has()
    .digits(1);

  const [passwordValidatorErrors, setPasswordValidatorErrors] = useState<
    string[]
  >([]);

  const passwordRules: PasswordValidatorError[] = [
    {
      validation: 'min',
      message: t('auth.onboarding_update_profile.fields.password.rules.min', {
        minLength: 8,
      }),
    },
    {
      validation: 'lowercase',
      message: t(
        'auth.onboarding_update_profile.fields.password.rules.lowercase'
      ),
    },
    {
      validation: 'uppercase',
      message: t(
        'auth.onboarding_update_profile.fields.password.rules.uppercase'
      ),
    },
    {
      validation: 'digits',
      message: t('auth.onboarding_update_profile.fields.password.rules.digits'),
    },
  ];

  return (
    <FormField
      control={form.control}
      name="password"
      rules={{
        required: true,
        maxLength: 200,
        validate: {
          min: () => !passwordValidatorErrors?.includes('min'),
          lowercase: () => !passwordValidatorErrors?.includes('lowercase'),
          uppercase: () => !passwordValidatorErrors?.includes('uppercase'),
          digits: () => !passwordValidatorErrors?.includes('digits'),
        },
      }}
      render={({ field, fieldState: { isDirty } }) => (
        <FormItem>
          <FormLabel>
            {label
              ? label
              : t('auth.onboarding_update_profile.fields.password.label')}
          </FormLabel>
          <FormControl>
            <InputPassword
              {...field}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setPasswordValidatorErrors(
                  schema.validate(value, {
                    list: true,
                  }) as string[]
                );
                field.onChange(event);
              }}
              autoComplete="new-password"
            />
          </FormControl>
          <FormMessage match="required">
            {t(
              'auth.onboarding_update_profile.fields.password.validation_errors.required'
            )}
          </FormMessage>
          <FormMessage match="maxLength">
            {t(
              'auth.onboarding_update_profile.fields.password.validation_errors.maxLength',
              {
                maxLength: '200',
              }
            )}
          </FormMessage>
          {isDirty ? (
            <ul className="space-y-1">
              {passwordRules.map((passwordRule, index) => (
                <li
                  key={index}
                  className={cn('flex text-xs', {
                    'text-green-600': !passwordValidatorErrors.includes(
                      passwordRule.validation
                    ),
                    'text-red-600': passwordValidatorErrors.includes(
                      passwordRule.validation
                    ),
                  })}
                >
                  {passwordValidatorErrors.includes(passwordRule.validation) ? (
                    <Icon icon="close" />
                  ) : (
                    <Icon icon="check" />
                  )}
                  {passwordRule.message}
                </li>
              ))}
            </ul>
          ) : null}
        </FormItem>
      )}
    />
  );
};
