import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';
import Icon from '@appchoose/icon';
import {
  Sidebar,
  SidebarContent,
  SidebarTitle,
  SidebarTrigger,
} from '@appchoose/sidebar';

import { activeSaleIdState } from '../../stores/sales';
import { useApiKeyQuery } from '../../types/generated';
import {
  CodeBlock,
  CodeBlockContent,
  CodeBlockHeader,
} from '../code-block/code-block';
import { ShopifyPlugin } from '../shopify-plugin/shopify-plugin';

const BRAND_API_BASE_URL =
  import.meta.env.REACT_APP_STAGE === 'production'
    ? 'https://brand.api.appchoose.io'
    : 'https://brand.api.appchoose.co';

export const SettingsIntegrations: React.FC = () => {
  const { t } = useTranslation();
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const { data: apiKeyData } = useApiKeyQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="mb-8 max-w-4xl space-y-4">
      <ShopifyPlugin />
      <Sidebar>
        <SidebarTrigger className="block w-full cursor-pointer rounded-lg border border-gray-300 bg-white outline-none transition duration-300 focus:border-gray-700 focus:ring-1 focus:ring-gray-700">
          <div className="flex items-center justify-between p-6">
            <div className="flex space-x-4">
              <div className="flex items-center justify-center rounded-full bg-gray-100 p-3">
                <Icon icon="code" className="size-8 text-green-900" />
              </div>
              <div className="flex items-center text-base font-bold">
                {t('settings.integration_tabs.choose_api')}
              </div>
            </div>
            <span className="text-xs font-semibold text-green-900">
              {t('settings.shopify_infos')}
            </span>
          </div>
        </SidebarTrigger>
        <SidebarContent className="p-10 lg:w-[37.5rem]">
          <SidebarTitle className="mb-6">
            {t('api.authentication')}
          </SidebarTitle>
          <div className="space-y-10">
            <Alert
              title="API Choose"
              icon="information"
              appearance="default"
              sideContent={
                // Small hack to center vertically the link
                <div className="flex h-full items-center">
                  <a
                    href={BRAND_API_BASE_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center rounded-lg border-2 border-gray-900 bg-white px-3.5 py-2 text-sm font-bold text-gray-900 outline-none transition-colors duration-300 hover:border-gray-700 hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-300"
                  >
                    <span>{t('api.see')}</span>
                  </a>
                </div>
              }
            >
              <p className="text-sm text-gray-700">
                {t('api.access_our_documentation')}
              </p>
            </Alert>
            <div className="space-y-6">
              <div className="space-y-2">
                <CodeBlock>
                  <CodeBlockHeader>{t('api.api_token')}</CodeBlockHeader>
                  <CodeBlockContent
                    content={apiKeyData?.brand?.api_token ?? ''}
                    secret={true}
                  />
                </CodeBlock>
                <p className="flex items-center space-x-2 text-orange-600">
                  <Icon icon="alert" className="shrink-0" />
                  <span className="text-xs">{t('api.must_remain_secret')}</span>
                </p>
              </div>
              <CodeBlock>
                <CodeBlockHeader>{t('api.selected_sale_id')}</CodeBlockHeader>
                <CodeBlockContent content={activeSaleId ?? ''} />
              </CodeBlock>
            </div>
          </div>
        </SidebarContent>
      </Sidebar>
    </div>
  );
};

SettingsIntegrations.displayName = 'SettingsIntegrations';
