import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../../types/generated';
import {
  Service,
  ServiceOption,
  frDeliveryServices,
  getActionableServices,
  mapServiceOptionsToServices,
  otherDeliveryImage,
  usDeliveryServices,
} from '../../../types/services';
import { EditServices } from '../../edit-services/edit-services';

export type OnboardingStepShippingDeliveryServiceProps = {
  goToNextSubStepOrStep: () => void;
};

export type OnboardingStepShippingDeliveryServiceForm = {
  services: ServiceOption[];
};

export const OnboardingStepShippingDeliveryService: React.FC<
  OnboardingStepShippingDeliveryServiceProps
> = ({ goToNextSubStepOrStep }: OnboardingStepShippingDeliveryServiceProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const deliveryServices =
    brand?.store === StoreRegion.Us ? usDeliveryServices : frDeliveryServices;

  const defaultDeliveryServices = getActionableServices(
    deliveryServices,
    brand?.delivery_services as Service[] | undefined,
    otherDeliveryImage
  );

  const form = useForm<OnboardingStepShippingDeliveryServiceForm>({
    defaultValues: {
      services: defaultDeliveryServices,
    },
  });

  const onSubmit = (data: OnboardingStepShippingDeliveryServiceForm) => {
    if (!brand) return;

    const selectedServices = mapServiceOptionsToServices(
      data.services.filter((s) => s.selected)
    );
    setBrand({
      ...brand,
      delivery_services: selectedServices,
    });
    updateSellerMutation({
      updateSeller: { delivery_services: selectedServices },
    });
    goToNextSubStepOrStep();
  };

  return (
    <>
      <div className="mb-6 sm:mb-10">
        <h2 className="mt-8 text-2xl font-bold sm:mt-20 sm:text-3.5xl">
          {t('onboarding.shipping.sub_steps.2.title')}
        </h2>
        <p className="text-sm text-gray-700 sm:text-base">
          {t('onboarding.shipping.sub_steps.2.subtitle')}
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-7">
          <div>
            <EditServices type="delivery" />
          </div>
          <div className="mt-8 flex sm:mt-10">
            <Button type="submit" size="large">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

OnboardingStepShippingDeliveryService.displayName =
  'OnboardingStepShippingDeliveryService';
