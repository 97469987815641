import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Modal, ModalContent } from '@appchoose/modal';

import { BrandRole, Member } from '../../types/generated-new';
import { AvatarProfile } from '../avatar-profile/avatar-profile';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { ModalDowngradeRole } from './modal-downgrade-role';
import { ModalNoAdminLeft } from './modal-no-admin-left';
import { ModalSuppressionMember } from './modal-suppression';

export type MemberViewProps = {
  member: Member;
  handleUserDelete: (userId: string) => void;
  modifyStatus: (userId: string, role: BrandRole) => void;
  brandHasMoreThanOneAdmin: boolean;
  isMemberActive: boolean;
  isUserAdmin: boolean;
};

export const MemberView = ({
  brandHasMoreThanOneAdmin,
  isMemberActive,
  isUserAdmin,
  handleUserDelete,
  modifyStatus,
  member,
}: MemberViewProps) => {
  const { t } = useTranslation();
  const [isDowngradeModalOpen, setIsDowngradeModalOpen] = useState(false);
  const [isModalSupressionOpen, setIsModalSupressionOpen] = useState(false);
  const [isNoAdminLeftModalOpen, setIsNoAdminLeftModalOpen] = useState(false);

  function handleDowngrade() {
    if (
      brandHasMoreThanOneAdmin &&
      member.role === BrandRole.Admin.toString() &&
      isMemberActive
    ) {
      setIsDowngradeModalOpen(true);
    } else if (
      brandHasMoreThanOneAdmin &&
      member.role === BrandRole.Admin.toString()
    ) {
      modifyStatus(member.userId, BrandRole.Member);
    } else if (
      !brandHasMoreThanOneAdmin &&
      member.role === BrandRole.Admin.toString()
    ) {
      setIsNoAdminLeftModalOpen(true);
    }
  }

  function deleteUser() {
    handleUserDelete(member.userId);
  }

  function handleSuppression() {
    if (
      !brandHasMoreThanOneAdmin &&
      member.role === BrandRole.Admin.toString()
    ) {
      setIsNoAdminLeftModalOpen(true);
    } else {
      setIsModalSupressionOpen(true);
    }
  }

  return (
    <>
      <div className="flex items-center justify-between py-5">
        <div className="flex items-center space-x-2">
          <AvatarProfile className="size-12" pictureUrl={member.picture} />
          <div className="flex flex-col">
            <div className="text-base font-semibold text-gray-700">
              {member.firstname} {member.lastname}
              <span className="ml-1 font-normal text-gray-500">
                {isMemberActive && `(${t('you')})`}
              </span>
            </div>
            <div className="text-xs text-gray-700">{member.email}</div>
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!isUserAdmin}
            className="inline-flex items-center justify-center rounded border border-gray-500 p-2 text-sm font-medium text-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 disabled:cursor-not-allowed"
          >
            <>
              <div className="capitalize">
                {member.role === 'ADMIN'
                  ? t('settings.member_tabs.admin')
                  : t('settings.member_tabs.member')}
              </div>
              {isUserAdmin ? <Icon className="ml-2" icon="arrowDown" /> : null}
            </>
          </DropdownMenuTrigger>

          <DropdownMenuContent
            align="end"
            className="z-10 mt-2 w-[21.25rem] rounded-md bg-white p-0 shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <DropdownMenuItem
              onSelect={() => modifyStatus(member.userId, BrandRole.Admin)}
              className={cn(
                'flex w-full items-start justify-between p-4 hover:bg-gray-50',
                {
                  'bg-green-300': member.role === BrandRole.Admin.toString(),
                }
              )}
            >
              <div className="flex flex-col items-start">
                <div
                  className={cn(
                    'mb-1 text-sm font-bold capitalize',
                    member.role === BrandRole.Admin.toString()
                      ? 'text-green-900'
                      : 'text-gray-900'
                  )}
                >
                  {t('settings.member_tabs.admin')}
                </div>
                <div
                  className={cn(
                    `text-left text-xs`,
                    member.role === BrandRole.Admin.toString()
                      ? 'text-green-900'
                      : 'text-gray-900'
                  )}
                >
                  {t('settings.member_tabs.admin_description')}
                </div>
              </div>
              {member.role === BrandRole.Admin.toString() ? (
                <Icon icon="check" size="large" className="text-green-900" />
              ) : null}
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={handleDowngrade}
              className={cn(
                'flex w-full items-start justify-between p-4 hover:bg-gray-50',
                member.role === BrandRole.Member.toString()
                  ? 'bg-green-300'
                  : ''
              )}
            >
              <div className="flex flex-col items-start">
                <div
                  className={cn(
                    'mb-1 text-sm font-bold capitalize',
                    member.role === BrandRole.Member.toString()
                      ? 'text-green-900'
                      : 'text-gray-900'
                  )}
                >
                  {t('settings.member_tabs.member')}
                </div>
                <div
                  className={cn(
                    'text-left text-xs',
                    member.role === BrandRole.Member.toString()
                      ? 'text-green-900'
                      : 'text-gray-900'
                  )}
                >
                  {t('settings.member_tabs.member_description')}
                </div>
              </div>
              {member.role === BrandRole.Member.toString() ? (
                <Icon icon="check" size="large" className="text-green-900" />
              ) : null}
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={handleSuppression}
              className="flex p-4 text-sm font-bold text-red-600 hover:bg-gray-50"
            >
              {t('settings.member_tabs.remove_from_team')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Modal open={isDowngradeModalOpen} onOpenChange={setIsDowngradeModalOpen}>
        <ModalContent>
          <ModalDowngradeRole
            downgradeToMember={() =>
              modifyStatus(member.userId, BrandRole.Member)
            }
            closeModal={() => setIsDowngradeModalOpen(false)}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={isNoAdminLeftModalOpen}
        onOpenChange={setIsNoAdminLeftModalOpen}
      >
        <ModalContent>
          <ModalNoAdminLeft
            closeModal={() => setIsNoAdminLeftModalOpen(false)}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={isModalSupressionOpen}
        onOpenChange={setIsModalSupressionOpen}
      >
        <ModalContent>
          <ModalSuppressionMember
            handleDowngrade={deleteUser}
            closeModal={() => setIsModalSupressionOpen(false)}
          />
        </ModalContent>
      </Modal>
    </>
  );
};
