import React from 'react';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import i18n, { t } from 'i18next';

import { brandState } from '../../stores/brand';
import { RawSale, activeSaleIdState } from '../../stores/sales';
import { transformDateSale, transformDateSimple } from '../../utils/date';

import './sales-selector.scss';

type SalesSelectorCurrentSaleProps = {
  sale?: RawSale;
  className?: string;
  onPress: () => void;
};

const CurrentSale = ({ sale }: { sale: RawSale }) => {
  const brand = useRecoilValue(brandState);
  return (
    <>
      <p className="leading-4 group-hover:hidden">
        <span className="text-sm font-bold text-green-900">
          {sale.selection_name ||
            transformDateSale(
              new Date(sale.start_at ?? ''),
              i18n.language === 'fr' ? 'fr' : 'en',
              brand?.timezone ?? undefined
            )}
        </span>
      </p>
      <p className="hidden group-hover:block">
        <span className="mt-1 text-sm text-green-900">
          {transformDateSimple(
            new Date(sale.start_at ?? ''),
            i18n?.language === 'fr' ? 'fr' : 'en',
            true,
            brand?.timezone ?? undefined
          )}{' '}
          →{' '}
          {sale.end_at
            ? transformDateSimple(
                new Date(sale.end_at),
                i18n?.language === 'fr' ? 'fr' : 'en',
                true,
                brand?.timezone ?? undefined
              )
            : ''}
        </span>
      </p>
    </>
  );
};

const NoSale = () => {
  return (
    <p className="leading-4">
      <span className="text-sm font-bold text-green-900">
        {t('selector.choose_a_sale')}
      </span>
    </p>
  );
};

export const SalesSelectorCurrentSale: React.FC<
  SalesSelectorCurrentSaleProps
> = ({ sale, className, onPress }: SalesSelectorCurrentSaleProps) => {
  const activeSaleId = useRecoilValue(activeSaleIdState);

  return (
    <div
      className={`group relative mx-6 my-4 box-border flex h-[65px] cursor-pointer flex-col items-start border-t border-gray-300 py-6 ${sale?.id === activeSaleId ? 'active' : ''} ${
        className ?? ''
      }`}
      onClick={onPress}
    >
      <p className="mb-2 text-xs uppercase leading-[14px] tracking-wider text-[#657484]">
        {t('selector.operation')}
      </p>
      {sale ? <CurrentSale sale={sale} /> : <NoSale />}
      <Icon icon="selector" className="arrows text-gray-500" />
    </div>
  );
};
