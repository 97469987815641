import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import * as Accordion from '@radix-ui/react-accordion';

import { activeSaleState } from '../../stores/sales';
import { OrderQuery, useOrdersLinkedQuery } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';

type TrackingOrdersLinkedProps = {
  parcel?:
    | OrderQuery['order']['parcels'][0]
    | OrderQuery['order']['returnParcels'][0];
};

export const TrackingOrdersLinked: React.FC<TrackingOrdersLinkedProps> = ({
  parcel,
}) => {
  const { search } = useLocation();
  const { brandId, orderId } = useParams<BrandMatch>();
  const activeSale = useRecoilValue(activeSaleState);
  const { t } = useTranslation();

  const ordersLinked = useOrdersLinkedQuery(
    {
      input: {
        saleId: activeSale?.id ?? '',
        trackingCourierSlug: parcel?.trackingCourier?.slug ?? '',
        trackingNumber: parcel?.trackingNumber ?? '',
      },
    },
    {
      enabled: !!activeSale?.id && !!parcel?.trackingNumber,
    }
  );

  if (
    !ordersLinked ||
    (ordersLinked.data?.ordersByTracking.filter((order) => order.id !== orderId)
      .length ?? 0) === 0
  )
    return null;

  return (
    <Accordion.Root type="single" collapsible>
      <Accordion.Item value="orders-linked">
        <Accordion.Header>
          <Accordion.Trigger className="flex w-full space-x-2 text-left text-xs text-gray-700 [&[data-state=open]>svg]:rotate-180 [&[data-state=open]]:pb-2">
            <span>
              {t('order.tracking.x_other_order_same_parcel', {
                count: ordersLinked.data?.ordersByTracking.length,
              })}
            </span>
            <Icon
              icon="arrowDown"
              className="text-[#767A7C] transition-transform"
            />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <ul className="space-y-2">
            {ordersLinked.data?.ordersByTracking
              .filter((order) => order.id !== orderId)
              .map((order) => (
                <li key={order.id}>
                  <NavLink
                    to={`/${brandId}/orders/${order.id}${search}`}
                    target="_blank"
                    className="flex items-center space-x-1 text-sm font-semibold text-green-900"
                  >
                    <span>{order.id}</span>
                    <div className="shrink-0">
                      <Icon icon="externalLinkSimple" className="size-3.5" />
                    </div>
                  </NavLink>
                </li>
              ))}
          </ul>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};
