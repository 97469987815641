import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Statistics from '@appchoose/statistics';

import { brandState } from '../../stores/brand';
import {
  activeSaleIdState,
  activeSaleState,
  isWaitingForSaleState,
} from '../../stores/sales';
import {
  StoreCurrency,
  StoreRegion,
  useStatsQuery,
} from '../../types/generated';
import { BrandMatch } from '../../types/navigation';

export const HomeStats: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { brandId = '' } = useParams<BrandMatch>();
  const { i18n, t } = useTranslation();

  const isWaitingForSale = useRecoilValue(isWaitingForSaleState);
  const activeSale = useRecoilValue(activeSaleState);
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const { data: statsData } = useStatsQuery(
    {
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!activeSaleId,
      refetchOnWindowFocus: false,
    }
  );

  if (isWaitingForSale) return null;

  return (
    <>
      <p className="flex flex-col justify-between gap-2 text-xs text-gray-500 sm:flex-row">
        <span>{t('home.overview_stats')}</span>
        <NavLink
          to={`/${brandId}/analytics`}
          className="font-semibold text-gray-700 hover:text-green-900"
        >
          {t('home.btn_more_stats')}
        </NavLink>
      </p>
      <div className="flex flex-col gap-4 sm:flex-row">
        <Statistics
          icon={
            activeSale?.currency === StoreCurrency.Usd
              ? 'dollarCircle'
              : 'euroCircle'
          }
          title={t('analytics.number_total_sale')}
          value={
            brand?.store === StoreRegion.Fr
              ? (statsData?.statsV2?.totalTurnoverTtc ?? 0) / 100
              : (statsData?.statsV2?.totalTurnoverHt ?? 0) / 100
          }
          locale={i18n.language === 'fr' ? 'fr' : 'en'}
          sign={activeSale?.currency === StoreCurrency.Usd ? '$' : '€'}
          showSign={true}
          reverseSignAndValue={i18n.language === 'en' ? true : false}
          tooltipContent={
            <div className="p-1">
              {brand?.store === StoreRegion.Fr
                ? t('analytics.tooltip_total_sale')
                : t('analytics.tooltip_total_sale_ht')}
            </div>
          }
        />
        <Statistics
          icon="package"
          title={t('analytics.number_total_orders')}
          value={statsData?.statsV2?.orderCount ?? 0}
          locale={i18n.language === 'fr' ? 'fr' : 'en'}
          tooltipContent={
            <div className="p-1">{t('analytics.tooltip_total_orders')}</div>
          }
        />
      </div>
    </>
  );
};
