import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

import { OrderTagClaimReason } from '../../types/generated-new';
import { OrderBadge } from './order-badge';

export const getOrderClaimReasonLabelFromTag = (
  tag: OrderTagClaimReason,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagClaimReason.DeliveredButDamaged:
      return t('order.status.claim_reason.delivered_but_damaged');
    case OrderTagClaimReason.DeliveredButIncomplete:
      return t('order.status.claim_reason.delivered_but_incomplete');
    case OrderTagClaimReason.DeliveredButWrong:
      return t('order.status.claim_reason.delivered_but_wrong');
    case OrderTagClaimReason.NotDeliveredAndReturnedToSender:
      return t(
        'order.status.claim_reason.not_delivered_and_returned_to_sender'
      );
    case OrderTagClaimReason.NotDeliveredAndTrackingBlocked:
      return t('order.status.claim_reason.not_delivered_and_tracking_blocked');
    case OrderTagClaimReason.NotDeliveredAndTrackingDelivered:
      return t(
        'order.status.claim_reason.not_delivered_and_tracking_delivered'
      );
    case OrderTagClaimReason.Other:
      return t('order.status.claim_reason.other');
    case OrderTagClaimReason.VoucherNotWorking:
      return t('order.status.claim_reason.voucher_not_working');
  }
};

export const OrderClaimReasonBadge: React.FC<{
  tag: OrderTagClaimReason;
}> = ({ tag }) => {
  const { t } = useTranslation();

  if (tag === OrderTagClaimReason.None) return null;

  return (
    <OrderBadge style="default">
      <div className="flex items-center">
        {getOrderClaimReasonLabelFromTag(tag, t)}
      </div>
    </OrderBadge>
  );
};
