export const SkeletonGraph: React.FC = () => {
  return (
    <svg
      width="791"
      height="334"
      viewBox="0 0 791 334"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.5 46.5L244.25 147.5L391.5 173.5L538.75 181.5L686 221.5"
        stroke="#ECECEC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M244.25 147.5L56.5 46.5V297.5H686V221.5L538.75 181.5L391.5 173.5L244.25 147.5Z"
        fill="url(#paint0_linear)"
        fillOpacity="0.4"
      />
      <rect x="56" y="297" width="735" height="1" rx="0.5" fill="#D8D9DA" />
      <rect x="56" width="1" height="306" rx="0.5" fill="#D8D9DA" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="371.25"
          y1="19.392"
          x2="371.25"
          y2="297.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8D9DA" />
          <stop offset="1" stopColor="#EBF0EE" />
          <stop offset="1" stopColor="#F4F4F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
