import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

import { NoGlobeIllustration } from '../icons/no-globe';

export const OrderTableOffline = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-20 flex w-full flex-col items-center space-y-4">
      <NoGlobeIllustration className="size-20" />
      <div className="space-y-1 text-center">
        <h4 className="text-2xl font-bold text-gray-900">
          {t('orders.table.empty_state.offline.interrupted_connection')}
        </h4>
      </div>
      <Button type="button" onClick={() => window.location.reload()}>
        {t('orders.table.empty_state.offline.try_again')}
      </Button>
    </div>
  );
};
