import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Rating from '@appchoose/rating';
import i18n from 'i18next';

import { brandState } from '../../stores/brand';
import { Rating as RawRating } from '../../types/generated';
import { BrandMatch } from '../../types/navigation';
import { transformDateWithYear } from '../../utils/date';
import { SkeletonReview } from './skeleton-review';

interface IProduct {
  id: string;
  image: string;
}

type StatisticsRatingProps = {
  rate?: RawRating;
  variant?: 'skeleton';
};

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

type ProductsProps = {
  orderId?: string | null;
  products?: IProduct[] | null;
};

const NB_PRODUCTS_TO_DISPLAY = 4;

const Products: React.FC<ProductsProps> = ({
  orderId,
  products,
}: ProductsProps) => {
  const { brandId = '' } = useParams<BrandMatch>();

  const diff = (products?.length ?? 0) - NB_PRODUCTS_TO_DISPLAY;

  return (
    <div className="flex w-[40%] flex-wrap justify-end space-x-0.5">
      {products?.slice(0, NB_PRODUCTS_TO_DISPLAY)?.map((product, index) => {
        return (
          <NavLink
            key={index}
            to={`/${brandId}/orders/${orderId ?? ''}`}
            className="active:opacity-80"
          >
            <img
              src={product.image}
              className="size-14 rounded border border-gray-300 object-cover"
            />
          </NavLink>
        );
      })}
      {(products?.length ?? 0) > NB_PRODUCTS_TO_DISPLAY ? (
        <NavLink
          to={`/${brandId}/orders/${orderId ?? ''}`}
          className="active:opacity-80"
        >
          <p className="size-14 rounded bg-gray-700 object-cover text-center text-xs font-bold uppercase leading-[56px] tracking-wider text-white">
            +{diff}
          </p>
        </NavLink>
      ) : null}
    </div>
  );
};

export const StatisticsRating: React.FC<StatisticsRatingProps> = (
  props: StatisticsRatingProps
) => {
  const { search } = useLocation();
  const { brandId: id = '' } = useParams<BrandMatch>();
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();

  if (props.variant === 'skeleton') {
    return (
      <div className="mb-6 border-b border-gray-300 pb-6">
        <SkeletonReview />
      </div>
    );
  }

  return props.rate ? (
    <div className="mb-6 border-b border-gray-300 pb-6">
      <header
        className={cn('flex flex-row', {
          '-mb-6': !props.rate.rate_message,
        })}
      >
        <div className="flex flex-1 flex-col">
          <Rating value={(props.rate?.rate ?? 0) + 1} />
          {props.rate.rate_message ? (
            <p className="mb-4 mt-3 leading-5.5 text-gray-900">
              {props.rate.rate_message}
            </p>
          ) : null}
        </div>
        <Products
          orderId={props.rate.order_id}
          products={props.rate.products}
        />
      </header>
      <div className="flex flex-row items-center space-x-2">
        {props.rate.avatar ? (
          <img
            src={props.rate.avatar}
            alt=""
            className="size-10 rounded-full bg-beige-300 object-cover"
          />
        ) : (
          <div className="size-10 rounded-full bg-beige-300 object-cover">
            <p className="text-center text-sm leading-10 tracking-wider text-[#ab9773]">
              {getInitials(props.rate?.name ?? '')}
            </p>
          </div>
        )}
        <div className="flex flex-col">
          <div className="mb-0.5 text-sm font-semibold capitalize text-gray-700">
            {props.rate.name}
          </div>
          <p className="space-x-1 text-xs text-gray-500">
            <span>
              {transformDateWithYear(
                new Date(props.rate?.rate_updated_at ?? new Date()),
                i18n?.language === 'fr' ? 'fr' : 'en',
                brand?.timezone ?? undefined
              )}
            </span>
            <span>•</span>
            <NavLink
              to={`/${id}/orders/${props.rate?.order_id ?? ''}${search}`}
              className="text-green-900"
            >
              {t('rating.open_order')}
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  ) : null;
};

export default StatisticsRating;
