import { TFunction } from 'i18next';

export const PROC_SHOPIFY_FR =
  'https://choose.notion.site/Proc-dure-d-synchro-Shopify-FR-48b150f463134b2f9c9244b06d4c914d';
export const PROC_SHOPIFY_EN =
  'https://choose.notion.site/Shopify-desynchronization-ENG-45449d5553974de8a34fdd4b7456d41e';

export const getProcShopifyUrl = (language: string) => {
  return language === 'fr' ? PROC_SHOPIFY_FR : PROC_SHOPIFY_EN;
};

export const getShopifyAdminUrl = (shopifyUrl: string) => {
  const shopifyId = shopifyUrl
    .replace('http://', '')
    .replace('https://', '')
    .replace('.myshopify.com', '');
  return `https://admin.shopify.com/store/${shopifyId}/products`;
};

export enum ShopifyErrorCode {
  OutOfStock,
  ProductNotFound,
  Unhandled,
}

export const getShopifyErrors = (exportedErrorCode: string) => {
  if (exportedErrorCode.includes('Product(s) not found')) {
    return ShopifyErrorCode.ProductNotFound;
  }
  if (exportedErrorCode.includes('product quantity is 0')) {
    return ShopifyErrorCode.OutOfStock;
  }
  return ShopifyErrorCode.Unhandled;
};

export const getShopifyLabelFromError = (
  shopifyError: ShopifyErrorCode,
  t: TFunction
) => {
  switch (shopifyError) {
    case ShopifyErrorCode.ProductNotFound:
      return t('orders.shopify_errors.product_not_found');
    case ShopifyErrorCode.OutOfStock:
      return t('orders.shopify_errors.out_of_stock');
    default:
      return t('orders.shopify_errors.unhandled');
  }
};
