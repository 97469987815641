type TranslateProps = React.ComponentPropsWithoutRef<'svg'>;

export const Translate: React.FC<TranslateProps> = ({
  ...props
}: TranslateProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.43335 10.4467C8.52669 10.2067 8.46669 9.93334 8.28002 9.74667L6.88669 8.37334L6.90669 8.35334C8.06669 7.06 8.89335 5.57334 9.38002 4H10.6734C11.0334 4 11.3334 3.7 11.3334 3.34V3.32667C11.3334 2.96667 11.0334 2.66667 10.6734 2.66667H6.66669V2C6.66669 1.63334 6.36669 1.33334 6.00002 1.33334C5.63335 1.33334 5.33335 1.63334 5.33335 2V2.66667H1.32669C0.966687 2.66667 0.666687 2.96667 0.666687 3.32667C0.666687 3.69334 0.966687 3.98667 1.32669 3.98667H8.11335C7.66669 5.28 6.96002 6.5 6.00002 7.56667C5.46002 6.97334 5.00669 6.32667 4.62669 5.64667C4.52002 5.45334 4.32669 5.33334 4.10669 5.33334C3.64669 5.33334 3.35335 5.83334 3.58002 6.23334C4.00002 6.98667 4.51335 7.70667 5.11335 8.37334L2.20002 11.2467C1.93335 11.5067 1.93335 11.9333 2.20002 12.1933C2.46002 12.4533 2.88002 12.4533 3.14669 12.1933L6.00002 9.33334L7.34669 10.68C7.68669 11.02 8.26669 10.8933 8.43335 10.4467ZM11.6667 6.66667C11.2667 6.66667 10.9067 6.91334 10.7667 7.29334L8.32002 13.8267C8.16002 14.2333 8.46669 14.6667 8.90002 14.6667C9.16002 14.6667 9.39335 14.5067 9.48669 14.26L10.08 12.6667H13.2467L13.8467 14.26C13.94 14.5 14.1734 14.6667 14.4334 14.6667C14.8667 14.6667 15.1734 14.2333 15.02 13.8267L12.5734 7.29334C12.4267 6.91334 12.0667 6.66667 11.6667 6.66667ZM10.5867 11.3333L11.6667 8.44667L12.7467 11.3333H10.5867Z"
        fill="#13482B"
      />
    </svg>
  );
};
