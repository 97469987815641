type NoResultIllutrationProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const NoResultIllustration: React.FC<NoResultIllutrationProps> = (
  props: NoResultIllutrationProps
) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70 70L55.5228 55.5228M55.5228 55.5228C60.3486 50.6971 63.3333 44.0305 63.3333 36.6667C63.3333 21.9391 51.3943 10 36.6667 10C21.9391 10 10 21.9391 10 36.6667C10 51.3943 21.9391 63.3333 36.6667 63.3333C44.0305 63.3333 50.6971 60.3486 55.5228 55.5228Z"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6874 28.3046C46.9607 28.0312 46.9607 27.588 46.6874 27.3146C46.414 27.0412 45.9708 27.0412 45.6974 27.3146L46.6874 28.3046ZM27.3126 45.6994C27.0393 45.9727 27.0393 46.416 27.3126 46.6893C27.586 46.9627 28.0292 46.9627 28.3026 46.6893L27.3126 45.6994ZM45.6974 46.6893C45.9708 46.9627 46.414 46.9627 46.6874 46.6893C46.9607 46.416 46.9607 45.9727 46.6874 45.6994L45.6974 46.6893ZM28.3026 27.3146C28.0292 27.0412 27.586 27.0412 27.3126 27.3146C27.0393 27.588 27.0393 28.0312 27.3126 28.3046L28.3026 27.3146ZM45.6974 27.3146L36.505 36.507L37.495 37.4969L46.6874 28.3046L45.6974 27.3146ZM36.505 36.507L27.3126 45.6994L28.3026 46.6893L37.495 37.4969L36.505 36.507ZM36.505 37.4969L45.6974 46.6893L46.6874 45.6994L37.495 36.507L36.505 37.4969ZM46.6874 45.6994L28.3026 27.3146L27.3126 28.3046L45.6974 46.6893L46.6874 45.6994Z"
        fill="#0B1115"
      />
    </svg>
  );
};
