import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pageView } from '../utils/analytics';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const locationData = location.pathname
      .split('/')
      .slice(2)
      .filter((part) => !!part);
    if (locationData.length > 0) {
      let pageName = locationData.join('_');
      if (
        locationData.length === 2 &&
        locationData[0] === 'orders' &&
        locationData[1]
      ) {
        pageName = 'order_details';
      }
      pageView(pageName);
    }
  }, [location]);
};

export default usePageTracking;
