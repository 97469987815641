import { useTranslation } from 'react-i18next';

import { ConfirmPasswordlessCodeForm } from '../../confirm-passwordless-code-form/confirm-passwordless-code-form';

export type OnboardingStepAuthConfirmPasswordlessCodeProps = {
  goToNextSubStepOrStep: (email: string) => void;
};

export const OnboardingStepAuthConfirmPasswordlessCode: React.FC<
  OnboardingStepAuthConfirmPasswordlessCodeProps
> = () => {
  const { t } = useTranslation();

  return (
    <section className="mt-8 sm:mt-20">
      <h1 className="mb-4 text-3.5xl font-bold">
        {t('auth.confirm_passwordless_code.verify_code_in_your_inbox')}
      </h1>
      <ConfirmPasswordlessCodeForm />
    </section>
  );
};
