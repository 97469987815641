import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

import { CrossIllutration } from '../icons/cross';

export const OrderTableError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-20 flex w-full flex-col items-center space-y-4">
      <CrossIllutration className="size-20" />
      <h4 className="text-2xl font-bold text-gray-900">
        {t('orders.table.empty_state.error.an_error_occurred')}
      </h4>
      <Button type="button" onClick={() => window.location.reload()}>
        {t('orders.table.empty_state.error.refresh_page')}
      </Button>
    </div>
  );
};
