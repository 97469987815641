import Flag, { FlagType } from '@appchoose/flag';
import i18n from 'i18next';

import countries from '../assets/json/countries.json';

export const getCountryOptions = () => {
  return countries
    .map((country) => ({
      value: country.code,
      label: i18n.language === 'fr' ? country.fr : country.en,
      leftSection: (
        <div className="mr-2 flex h-4 items-center">
          <Flag flag={country.code as FlagType} />
        </div>
      ),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
