import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';
import { TFunction } from 'i18next';

import { getI18nLanguageFullFromISOLanguage } from '../../lang/i18n';
import { brandState } from '../../stores/brand';
import { useTranslateClaimMutation } from '../../types/generated';
import {
  ClaimReason,
  OrderQuery,
  OrderTagClaim,
} from '../../types/generated-new';
import { transformDate } from '../../utils/date';
import { Translate } from '../icons/translate';
import { ModalOrderClaimBadge } from '../order-status-badge/modal-order-claim-badge';
import { ClaimActions } from './claim-actions';
import { ProductErrorDetails } from './product-error-details';
import { ProofDetails } from './proof-details';

const getClaimReason = (
  reason: ClaimReason | null | undefined,
  t: TFunction
) => {
  switch (reason) {
    case ClaimReason.DeliveredButDamaged:
      return t('claim.reasons.delivered_but_damaged');
    case ClaimReason.DeliveredButIncomplete:
      return t('claim.reasons.delivered_but_incomplete');
    case ClaimReason.DeliveredButWrong:
      return t('claim.reasons.delivered_but_wrong');
    case ClaimReason.DigitalCouponCodeNotWorking:
      return t('claim.reasons.digital_coupon_code_not_working');
    case ClaimReason.NotDeliveredAndReturnedToSender:
      return t('claim.reasons.not_delivered_and_returned_to_sender');
    case ClaimReason.NotDeliveredAndTrackingBlocked:
      return t('claim.reasons.not_delivered_and_tracking_blocked');
    case ClaimReason.NotDeliveredAndTrackingDelivered:
      return t('claim.reasons.not_delivered_and_tracking_delivered');
    case ClaimReason.Other:
      return t('claim.reasons.other');
    default:
      return '';
  }
};

export const getClaimReasonOrOther = (
  reason: ClaimReason,
  otherReasonLabel: string | null | undefined,
  t: TFunction
) => {
  if (reason === ClaimReason.Other && otherReasonLabel) {
    return otherReasonLabel;
  }
  return getClaimReason(reason, t);
};

export const getClaimOrStatusMessage = (
  claim: OrderQuery['order']['claims'][0],
  t: TFunction
) => {
  const claimReason = getClaimReasonOrOther(
    claim?.reason,
    claim?.otherReasonLabel ?? '',
    t
  );
  if (claimReason) {
    return claimReason;
  }

  return '';
};

type ClaimDetailsProps = {
  claim: OrderQuery['order']['claims'][0];
  order?: OrderQuery['order'];
  parcel: OrderQuery['order']['parcels'][0];
  tags: OrderQuery['order']['tags'];
};

const MessageFromSupport = ({
  claim,
}: {
  claim: OrderQuery['order']['claims'][0];
}) => {
  const { user } = useAuth0();
  const { t } = useTranslation();

  const [isOriginal, setIsOriginal] = useState(true);

  const { mutateAsync: translateClaimMutation, data: translateClaimData } =
    useTranslateClaimMutation();

  useUpdateEffect(() => {
    setIsOriginal(false);
  }, [translateClaimData]);

  const getOriginalLanguage = (lang: string) => {
    if (lang === 'en') return t('claim.translate.english');
    return t('claim.translate.french');
  };

  const preferredLanguage =
    (user?.['https://appchoose.io/claims/preferredLanguage'] as
      | string
      | undefined) ?? navigator.language;

  return (
    <>
      {claim?.messageFromSupport ? (
        <p
          dangerouslySetInnerHTML={{
            __html:
              isOriginal || !translateClaimData
                ? claim.messageFromSupport.replaceAll('\n\n', '\n')
                : (translateClaimData?.translateClaim.translation ?? ''),
          }}
          className="whitespace-pre-line border-l-2 border-gray-100 pl-2 text-sm"
        />
      ) : null}
      <div className="space-x-1">
        <Translate className="inline-block size-4" />
        {!isOriginal ? (
          <span className="text-xs text-gray-700">
            {t('claim.translate.original_language')}{' '}
            {getOriginalLanguage(
              translateClaimData?.translateClaim.originalLanguage ?? 'fr'
            )}
          </span>
        ) : null}
        <button
          type="button"
          className="text-xs font-semibold text-green-900"
          onClick={() => {
            if (isOriginal && !translateClaimData)
              translateClaimMutation({
                claimId: claim?.id ?? '',
                to: getI18nLanguageFullFromISOLanguage(preferredLanguage),
              });
            setIsOriginal(!isOriginal);
          }}
        >
          {isOriginal
            ? t('claim.translate.to_current_language')
            : t('claim.translate.original_text')}
        </button>
      </div>
    </>
  );
};

export const ModalOrderClaimDetails: React.FC<ClaimDetailsProps> = ({
  claim,
  order,
  parcel,
  tags,
}: ClaimDetailsProps) => {
  const { i18n, t } = useTranslation();
  const [displayDetail, setDisplayDetail] = useState(false);

  const brand = useRecoilValue(brandState);

  if (!order || !claim || tags.claim?.[0] === OrderTagClaim.None) return null;

  if (!claim?.isAccepted && !claim?.isDeclined) {
    return (
      <div className="rounded border border-gray-300">
        <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
          <ModalOrderClaimBadge tag={tags?.claim?.[0]} fullLabel />
        </div>
        <div className="p-6 pt-4">
          <div>
            <div className="mb-2 text-sm text-gray-900">
              <div className="mb-4 font-semibold">
                {getClaimOrStatusMessage(claim, t)}
              </div>
              <MessageFromSupport claim={claim} />
            </div>
            <div className="space-x-1">
              <span className="text-xs text-gray-500">
                {transformDate(
                  new Date(claim.createdAt),
                  i18n?.language === 'fr' ? 'fr' : 'en',
                  brand?.timezone ?? undefined
                )}
              </span>
              {!order?.isFullDigital && parcel?.trackingUrl ? (
                <>
                  <span className="text-xs text-gray-500">•</span>
                  <a
                    href={parcel?.trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs font-semibold text-green-900"
                  >
                    {t('order.follow')}
                  </a>
                </>
              ) : null}
            </div>
            <ProductErrorDetails claim={claim} />
            <ProofDetails claim={claim} />
          </div>

          <ClaimActions
            isFullDigital={order.isFullDigital}
            claim={claim}
            order={order}
            orderId={order.id}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="rounded border border-gray-300">
      <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
        <ModalOrderClaimBadge tag={tags.claim?.[0]} fullLabel />
      </div>
      <div className="p-6 pt-4">
        <div className="mb-2 text-sm text-gray-900">
          <div className="mb-4 font-semibold">
            {getClaimOrStatusMessage(claim, t)}
          </div>
        </div>

        {claim?.acceptedMessage ? (
          <p
            className="text-sm text-[#767A7C]"
            dangerouslySetInnerHTML={{
              __html: `“${claim.acceptedMessage}“`,
            }}
          />
        ) : null}

        <ProductErrorDetails claim={claim} />

        <button
          type="button"
          className="mt-4 text-xs font-semibold text-green-900"
          onClick={() => setDisplayDetail(!displayDetail)}
        >
          {displayDetail
            ? t('order.claim.hide_detail')
            : t('order.claim.show_detail')}
        </button>
        {displayDetail ? (
          <div className="mt-4">
            <MessageFromSupport claim={claim} />

            <ProofDetails claim={claim} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ModalOrderClaimDetails.displayName = 'ClaimDetails';
