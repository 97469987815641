import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import {
  Item,
  useAcceptOrderClaimWithVoucherReplacementCodesMutation,
} from '../../types/generated-new';
import { OrderQuery } from '../../types/generated-new';
import { ClaimedProduct } from './claimed-product';

type ModalNewDigitalCodeProps = {
  claim: OrderQuery['order']['claims'][0];
  orderId?: OrderQuery['order']['id'];
  setIsOpen: (isOpen: boolean) => void;
};

type NewDigitalCodeForm = {
  digital_codes: {
    new: string;
    current: string;
    product: Item;
  }[];
};

export const ModalNewDigitalCode: React.FC<ModalNewDigitalCodeProps> = ({
  claim,
  orderId,
  setIsOpen,
}: ModalNewDigitalCodeProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    mutateAsync: acceptOrderClaimWithVoucherReplacementCodesMutation,
    isPending,
    error,
  } = useAcceptOrderClaimWithVoucherReplacementCodesMutation();

  const form = useForm<NewDigitalCodeForm>({
    mode: 'onTouched',
    defaultValues: {
      digital_codes: claim?.items?.map((item) => ({
        current: item.digitalCouponCode ?? '',
        new: '',
        product: item,
      })),
    },
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'digital_codes',
  });

  useEffect(() => {
    if (error) {
      toast.error(t('order.modals.modal_new_digital_code.accept_claim_error'));
    }
  }, [error]);

  const onSubmit = async (data: NewDigitalCodeForm) => {
    await acceptOrderClaimWithVoucherReplacementCodesMutation({
      input: {
        orderId: orderId ?? '',
        claimId: claim?.id ?? '',
        voucherReplacementCodes: data.digital_codes.map((digitalCode) => ({
          originalCode: digitalCode.current,
          replacementCode: digitalCode.new,
        })),
      },
    });

    queryClient.invalidateQueries({
      queryKey: ['order', { orderId: orderId }],
    });
    queryClient.invalidateQueries({
      queryKey: ['orders'],
    });
    setIsOpen(false);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col overflow-hidden"
      >
        <div className="h-full overflow-y-auto p-6 md:p-10">
          <h3
            id="modal-new-digital-code-title"
            className="mb-6 text-2xl font-bold text-gray-900"
          >
            {t('order.modals.modal_new_digital_code.send_new_digital_code')}
          </h3>
          <ul className="space-y-6">
            {fields.map((field, index) => (
              <li key={field.id} className="space-y-6">
                <ClaimedProduct
                  appearance="small"
                  product={form.getValues(`digital_codes.${index}.product`)}
                />
                <FormField
                  control={form.control}
                  name={`digital_codes.${index}.new`}
                  rules={{
                    required: true,
                    validate: {
                      alreadyExists: (value) =>
                        value !==
                        form.getValues(`digital_codes.${index}.current`),
                    },
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">
                        {t(
                          'order.modals.modal_new_digital_code.new_digital_code.label'
                        )}
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={t(
                            'order.modals.modal_new_digital_code.new_digital_code.placeholder'
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'order.modals.modal_new_digital_code.new_digital_code.validation_errors.required'
                        )}
                      </FormMessage>
                      <FormMessage match="alreadyExists">
                        {t(
                          'order.modals.modal_new_digital_code.new_digital_code.validation_errors.already_exists'
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
          <Button
            type="button"
            appearance="outline"
            onClick={() => setIsOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={isPending}>
            {t('confirm')}
          </Button>
        </div>
      </form>
    </Form>
  );
};
