import { BrowserRouter as Router } from 'react-router-dom';

import useHandleHash from './useHandleHash';
import usePageTracking from './usePageTracking';

export const ExtraRoutingFeatures = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  useHandleHash();
  usePageTracking();

  return children;
};

export const ChooseRouter = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <Router>
      <ExtraRoutingFeatures>{children}</ExtraRoutingFeatures>
    </Router>
  );
};
