type MediumCsvProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const MediumCsv: React.FC<MediumCsvProps> = (props: MediumCsvProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3H35V37H5V10M12 3L5 10M12 3V10H5"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5172 22.9573C15.1277 22.8787 14.7636 23.1528 14.651 23.5338C14.4136 24.3376 13.9235 24.9061 13.0142 24.9061C11.8855 24.9061 10.9196 24.0334 10.9196 21.8807C10.9196 19.7512 11.932 18.9483 13.0142 18.9483C13.9069 18.9483 14.3717 19.5045 14.6041 20.1982C14.7126 20.5223 15.0209 20.756 15.3586 20.7034C15.8108 20.633 16.1387 20.2123 15.9897 19.7796C15.5452 18.4895 14.417 17.6799 13.0025 17.6799C11.2222 17.6799 9.5 19.0298 9.5 21.8574C9.5 24.8828 11.0942 26.1745 13.0142 26.1745C14.605 26.1745 15.7054 25.2282 16.1011 23.8238C16.2141 23.4228 15.9256 23.0396 15.5172 22.9573Z"
        fill="black"
      />
      <path
        d="M17.9158 23.0912C17.493 23.1832 17.1998 23.5871 17.3219 24.0022C17.7475 25.4479 18.8337 26.1745 20.4389 26.1745C22.2891 26.1745 23.3829 25.0923 23.3829 23.7192C23.3829 22.5207 22.8709 21.9039 20.9044 21.2407C19.1124 20.6356 18.8447 20.4494 18.8447 19.9258C18.8447 19.3905 19.252 18.9599 20.1713 18.9599C20.9058 18.9599 21.3184 19.2409 21.5668 19.6617C21.7438 19.9616 22.0696 20.1744 22.408 20.092C22.8192 19.9919 23.0968 19.581 22.9194 19.1967C22.4369 18.1523 21.3682 17.6799 20.1713 17.6799C18.4491 17.6799 17.4949 18.7621 17.4949 19.9374C17.4949 20.9963 18.1349 21.741 19.8804 22.2879C21.7538 22.8697 22.0331 23.1374 22.0331 23.8007C22.0331 24.4756 21.4513 24.9061 20.4505 24.9061C19.4515 24.9061 18.9993 24.4791 18.7575 23.637C18.6509 23.266 18.2929 23.0091 17.9158 23.0912Z"
        fill="black"
      />
      <path
        d="M26.3128 25.3768C26.4468 25.7506 26.8011 25.9999 27.1981 25.9999C27.5965 25.9999 27.9516 25.749 28.0847 25.3735L30.4315 18.7494C30.5862 18.3128 30.2623 17.8545 29.7991 17.8545C29.5112 17.8545 29.2553 18.0382 29.1634 18.3111L27.2535 23.9763C27.249 23.9896 27.2366 23.9985 27.2226 23.9985C27.2087 23.9985 27.1964 23.9897 27.1918 23.9766L25.2166 18.3087C25.1219 18.0367 24.8654 17.8545 24.5774 17.8545C24.1089 17.8545 23.782 18.3189 23.9401 18.7599L26.3128 25.3768Z"
        fill="black"
      />
    </svg>
  );
};
