import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

export type OnboardingStepShippingHasShippingProps = {
  skipNextSubStep: () => void;
  goToNextSubStepOrStep: () => void;
};

export const OnboardingStepShippingHasShipping: React.FC<
  OnboardingStepShippingHasShippingProps
> = ({
  skipNextSubStep,
  goToNextSubStepOrStep,
}: OnboardingStepShippingHasShippingProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-6 sm:mb-10">
        <h2 className="mt-8 text-2xl font-bold sm:mt-20 sm:text-3.5xl">
          {t('onboarding.shipping.sub_steps.3.title')}
        </h2>
        <p className="text-sm text-gray-700 sm:text-base">
          {t('onboarding.shipping.sub_steps.3.subtitle')}
        </p>
      </div>
      <div className="flex space-x-4">
        <Button
          type="button"
          size="large"
          onClick={skipNextSubStep}
          className="capitalize"
        >
          {t('no')}
        </Button>
        <Button
          type="button"
          size="large"
          onClick={goToNextSubStepOrStep}
          className="capitalize"
        >
          {t('yes')}
        </Button>
      </div>
    </>
  );
};

OnboardingStepShippingHasShipping.displayName =
  'OnboardingStepShippingHasShipping';
