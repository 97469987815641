import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { BrandMatchWithSubStep } from '../../types/navigation';
import { getCorrectStep } from '../../utils/utils';
import {
  OnboardingStepShippingCountry,
  OnboardingStepShippingCountryProps,
} from './onboarding-step-shipping/onboarding-step-shipping-country';
import {
  OnboardingStepShippingDeliveryService,
  OnboardingStepShippingDeliveryServiceProps,
} from './onboarding-step-shipping/onboarding-step-shipping-delivery-service';
import {
  OnboardingStepShippingHasShipping,
  OnboardingStepShippingHasShippingProps,
} from './onboarding-step-shipping/onboarding-step-shipping-has-shipping';
import {
  OnboardingStepShippingShippingDelay,
  OnboardingStepShippingShippingDelayProps,
} from './onboarding-step-shipping/onboarding-step-shipping-shipping-delay';
import {
  OnboardingStepShippingShippingService,
  OnboardingStepShippingShippingServiceProps,
} from './onboarding-step-shipping/onboarding-step-shipping-shipping-service';

export type OnboardingStepShippingProps = {
  goToNextStep: () => void;
};

export const OnboardingStepShipping: React.FC<OnboardingStepShippingProps> = ({
  goToNextStep,
}: OnboardingStepShippingProps) => {
  const brand = useRecoilValue(brandState);
  const subSteps =
    brand?.store === StoreRegion.Fr
      ? [
          OnboardingStepShippingCountry,
          OnboardingStepShippingDeliveryService,
          OnboardingStepShippingHasShipping,
          OnboardingStepShippingShippingService,
          OnboardingStepShippingShippingDelay,
        ]
      : [
          OnboardingStepShippingCountry,
          OnboardingStepShippingDeliveryService,
          OnboardingStepShippingShippingDelay,
        ];

  const navigate = useNavigate();
  const params = useParams<BrandMatchWithSubStep>();

  const [subStep, setSubStep] = useState(
    getCorrectStep(params.substep, subSteps.length - 1)
  );

  useUpdateEffect(() => {
    setSubStep(getCorrectStep(params.substep, subSteps.length - 1));
  }, [params.substep]);

  const goToNextSubStepOrStep = () => {
    if (subStep === subSteps.length - 1) {
      goToNextStep();
    } else {
      navigate(
        `/${params.brandId}/onboarding/${params.step ?? ''}/${subStep + 1}${location.search}`
      );
      setSubStep(subStep + 1);
    }
  };

  const skipNextSubStep = () => {
    setSubStep(subStep + 2);
  };

  const Step = subSteps[subStep] as React.ElementType<
    | OnboardingStepShippingCountryProps
    | OnboardingStepShippingDeliveryServiceProps
    | OnboardingStepShippingHasShippingProps
    | OnboardingStepShippingShippingServiceProps
    | OnboardingStepShippingShippingDelayProps
  >;

  return (
    <div className="sm:col-11 w-full">
      <Step
        goToNextSubStepOrStep={goToNextSubStepOrStep}
        skipNextSubStep={skipNextSubStep}
      />
    </div>
  );
};

OnboardingStepShipping.displayName = 'OnboardingStepShipping';
