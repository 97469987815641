import React from 'react';

import cn from '@appchoose/cn';

export type ProgressBarProps = {
  /**
   * Define the appearance of the progress bar
   */
  appearance?: 'default' | 'success' | 'warning' | 'error';
  /**
   * Define the value of the progress bar
   */
  value: number;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Progress bar component.
 */
export const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  appearance = 'default',
  value,
  ...props
}: ProgressBarProps) => {
  return (
    <div className="flex h-1 overflow-hidden rounded-full bg-gray-300">
      <div
        role="progressbar"
        style={{ width: `${value}%` }}
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        className={cn(
          'ease flex justify-center overflow-hidden rounded-full bg-gray-700 text-center transition-all duration-500',
          {
            'bg-green-900': appearance === 'success',
            'bg-orange-600': appearance === 'warning',
            'bg-red-600': appearance === 'error',
          }
        )}
        {...props}
      />
    </div>
  );
};

ProgressBar.displayName = 'ProgressBar';
