import cn from '@appchoose/cn';

import './middle-truncate.scss';

type MiddleTruncateProps = {
  className?: string;
  endWrap?: number;
  text: string;
};

export const MiddleTruncate: React.FC<MiddleTruncateProps> = ({
  className,
  endWrap = 16,
  text,
}) => {
  return (
    <span
      className={cn(
        'flex flex-row flex-nowrap justify-start truncate',
        className,
        'text-ellipsis-middle'
      )}
    >
      <span>
        {text.length > endWrap
          ? text.substring(0, text.length - endWrap)
          : text}
      </span>
      <span>
        {text.length > endWrap ? text.substring(text.length - endWrap) : null}
      </span>
    </span>
  );
};
