import { atom } from 'recoil';

export const stockUpdateOpenIdState = atom<string | undefined>({
  key: 'stockUpdateOpenIdState',
  default: undefined,
});

export const newStockState = atom<number | undefined>({
  key: 'newStockState',
  default: undefined,
});
