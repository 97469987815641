import { useTranslation } from 'react-i18next';

import { AuthForm } from '../../auth-form/auth-form';

export type OnboardingStepAuthSignupProps = {
  goToNextSubStepOrStep: (email: string) => void;
};

export const OnboardingStepAuthSignup: React.FC<
  OnboardingStepAuthSignupProps
> = ({ goToNextSubStepOrStep }: OnboardingStepAuthSignupProps) => {
  const { t } = useTranslation();

  return (
    <section className="mt-8 sm:mt-20">
      <h1 className="mb-4 text-3.5xl font-bold">
        {t('auth.signup.start_by_creating_your_account')}
      </h1>
      <div className="space-y-10">
        <p className="m-0 text-base leading-5.5 text-gray-700">
          {t('auth.signup.infos_connect_dashboard')}
          <br />
          {t('auth.signup.infos_advice_email')}
        </p>
        <AuthForm
          showLoginWithPassword={false}
          success={(result) => {
            goToNextSubStepOrStep(encodeURIComponent(result.email));
          }}
        />
      </div>
    </section>
  );
};
