import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { brandState } from '../../stores/brand';
import { OrderQuery, OrderTagReship } from '../../types/generated-new';
import { transformDateSimple } from '../../utils/date';
import { ModalOrderReshipBadge } from '../order-status-badge/modal-order-reship-badge';
import { TrackingViewReship } from './tracking-view-reship';

type ModalOrderReshipDetailsProps = {
  tags: OrderQuery['order']['tags'];
  reshipOrder: OrderQuery['order']['reshipOrder'];
  reshipOrderId?: OrderQuery['order']['reshipOrderId'];
};

export const ModalOrderReshipDetails: React.FC<
  ModalOrderReshipDetailsProps
> = ({ reshipOrder, reshipOrderId, tags }: ModalOrderReshipDetailsProps) => {
  const brand = useRecoilValue(brandState);
  const { i18n, t } = useTranslation();

  if (tags.reship === OrderTagReship.None) return null;

  return (
    <div className="rounded border border-gray-300">
      <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
        <ModalOrderReshipBadge tag={tags.reship} />

        {reshipOrder?.createdAt ? (
          <p className="text-xs text-[#767A7C]">
            {t('order.reship.created_at', {
              date: transformDateSimple(
                new Date(reshipOrder.createdAt || ''),
                i18n?.language === 'fr' ? 'fr' : 'en',
                undefined,
                brand?.timezone ?? undefined
              ),
              interpolation: { escapeValue: false },
            })}
          </p>
        ) : null}
      </div>
      <div className="p-6 pt-4">
        <div className="flex flex-col">
          <p className="text-sm text-gray-900">
            {t('order.reship.created_following_claim')}
          </p>
          <TrackingViewReship reshipOrderId={reshipOrderId} />
        </div>
      </div>
    </div>
  );
};
