type SortProps = React.ComponentPropsWithoutRef<'svg'>;

export const Sort: React.FC<SortProps> = ({ ...props }: SortProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4714 6.47173C12.211 6.73208 11.7889 6.73208 11.5286 6.47173L7.99996 2.94313L4.47136 6.47173C4.21101 6.73208 3.7889 6.73208 3.52855 6.47173C3.2682 6.21138 3.2682 5.78927 3.52856 5.52892L7.52856 1.52892C7.7889 1.26857 8.21101 1.26857 8.47136 1.52892L12.4714 5.52892C12.7317 5.78927 12.7317 6.21138 12.4714 6.47173Z"
        fill="#D8D9DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52864 9.52827C3.78899 9.26792 4.2111 9.26792 4.47145 9.52827L8.00004 13.0569L11.5286 9.52827C11.789 9.26792 12.2111 9.26792 12.4714 9.52827C12.7318 9.78862 12.7318 10.2107 12.4714 10.4711L8.47145 14.4711C8.2111 14.7314 7.78899 14.7314 7.52864 14.4711L3.52864 10.4711C3.26829 10.2107 3.26829 9.78862 3.52864 9.52827Z"
        fill="#D8D9DA"
      />
    </svg>
  );
};
