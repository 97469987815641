import React, { useEffect, useRef, useState } from 'react';

import Lottie from 'lottie-web';

import EarlyAfternoon from './EarlyAfternoon.svg';
import animationMorning from './EarlyMorning.json';
import Evening from './Evening.svg';
import LateAfternoon from './LateAfternoon.svg';
import LateMorning from './LateMorning.svg';
import Night from './Night.svg';
import animationSmiley from './Smiley.json';

interface DaytimeProps {
  date: Date;
  showSmiley?: boolean;
}

export const Daytime: React.FC<DaytimeProps> = ({
  date,
  showSmiley,
}: DaytimeProps) => {
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const h = date.getHours();
    setDisplayAnimation(showSmiley || (5 <= h && h < 10));
  }, [date, showSmiley]);

  useEffect(() => {
    if (displayAnimation) {
      Lottie.loadAnimation({
        container: ref?.current as any,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: showSmiley ? animationSmiley : animationMorning,
      });
    }
  }, [displayAnimation]);

  const getImage = () => {
    const h = date.getHours();
    if (10 <= h && h < 12) {
      return LateMorning;
    }
    if (12 <= h && h < 15) {
      return EarlyAfternoon;
    }
    if (15 <= h && h < 17) {
      return LateAfternoon;
    }
    if (17 <= h && h < 21) {
      return Evening;
    }
    return Night;
  };

  return displayAnimation ? (
    <div ref={ref} className="h-12 w-12" />
  ) : (
    <img className="h-12 w-12" src={getImage()} />
  );
};
