import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import { TFunction } from 'i18next';

import { OrderTagRefund } from '../../types/generated';
import { OrderBadge } from './order-badge';

export const getOrderRefundLabelFromTag = (
  tag: OrderTagRefund,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagRefund.PartiallyRefunded:
      return t('order.status.refund.partial');
    case OrderTagRefund.TotallyRefunded:
      return t('order.status.refund.total');
  }
};

export const OrderRefundBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagRefund;
}> = ({ isCancelled, tag }) => {
  const { t } = useTranslation();

  if (tag === OrderTagRefund.None) return null;

  return (
    <OrderBadge>
      <div className="flex items-center gap-x-1">
        <span className={cn({ 'line-through': isCancelled })}>
          {getOrderRefundLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
