import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

type ModalNoAdminLeftProps = {
  closeModal: () => void;
};

export const ModalNoAdminLeft = ({ closeModal }: ModalNoAdminLeftProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col justify-between overflow-hidden">
      <div className="p-10">
        <h2 id="modal-no-admin-left-title" className="mb-4 text-2xl font-bold">
          {t('settings.member_tabs.need_action_title')}
        </h2>
        <div className="mb-12 text-sm text-gray-700">
          {t('settings.member_tabs.need_action_info')}
        </div>
      </div>
      <footer className="flex shrink-0 space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
        <Button type="submit" appearance="primary" onClick={closeModal}>
          {t('close')}
        </Button>
      </footer>
    </div>
  );
};
