import React from 'react';
import { useTranslation } from 'react-i18next';

import ProgressBar from '@appchoose/progress-bar';

export type Step = {
  label: string;
};

export type StepperProps = {
  steps: Step[];
  currentStep: number;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Stepper component.
 */
export const Stepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
  className,
}: StepperProps) => {
  const { t } = useTranslation();

  const progressBarValue = ((currentStep + 1) * 100) / steps.length;
  const currentStepItem = steps[currentStep];

  return (
    <div className={['space-y-2', className].join(' ')}>
      <div className="flex justify-between text-xs font-semibold tracking-wider">
        <div className="uppercase text-green-900">{currentStepItem.label}</div>
        <div className="text-gray-900">
          {currentStep + 1}/{steps.length}
        </div>
      </div>
      <ProgressBar
        value={progressBarValue}
        appearance="success"
        title={t('progress')}
      />
    </div>
  );
};

Stepper.displayName = 'Stepper';
