type MediumPlusProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const MediumPlus: React.FC<MediumPlusProps> = (
  props: MediumPlusProps
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="20"
        cy="20"
        r="18"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 14V26"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 20L14 20"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
