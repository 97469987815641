import { Auth0ContextInterface } from '@auth0/auth0-react';
import Backoff from 'backo2';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export const getSubscriptionClient = (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']
) => {
  const minTimeout = 30000 + Math.floor(Math.random() * 30000);
  const maxTimeout = 5 * 60000;

  const client = new SubscriptionClient(
    import.meta.env.REACT_APP_WS_URL as string,
    {
      lazy: true,
      reconnect: true,
      minTimeout,
      timeout: maxTimeout,
      connectionParams: async () => {
        return {
          authorization: await getAccessTokenSilently(),
          brandId: window.location.pathname.split('/')[1],
        };
      },
      connectionCallback(error) {
        if (error) {
          getAccessTokenSilently({ cacheMode: 'off' });
        }
      },
    },
    WebSocket
  );

  // maxConnectTimeGenerator is private, so we have to cast to any
  (client as any).maxConnectTimeGenerator = new Backoff({
    min: minTimeout,
    max: maxTimeout,
    factor: 1.5,
  });

  return client;
};
