import { FC, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { TabCounter } from '../../views/orders-page/tab-counter';
import { useTabs } from '../../views/orders-page/use-tabs';
import { useResetList } from '../order-table/use-reset-list';
import { getVisibleAndHiddenTabIndices } from './get-visible-and-hidden-tab-indices';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './order-tab-dropdown';
import { TabsList, TabsTrigger } from './order-tabs';
import { TabMeasurer } from './tab-measurer';
import { TabMeasurements, TabProps } from './types';

export type SmartTabsProps = {
  className?: string;
  selected: number;
  tabs: TabProps[];
};

export type TabsState = {
  disclosureWidth: number;
  tabWidths: number[];
  visibleTabs: number[];
  hiddenTabs: number[];
  containerWidth: number;
  showDisclosure: boolean;
  tabToFocus: number;
  isTabPopoverOpen: boolean;
  isTabModalOpen: boolean;
  isNewViewModalActive: boolean;
  modalSubmitted: boolean;
  isTabsFocused: boolean;
};

export const SmartTabs: FC<SmartTabsProps> = ({
  className,
  selected,
  tabs,
}: SmartTabsProps) => {
  const { t } = useTranslation();
  const { resetList } = useResetList();
  const [state, setState] = useReducer(
    (data: TabsState, partialData: Partial<TabsState>): TabsState => {
      return { ...data, ...partialData };
    },
    {
      disclosureWidth: 0,
      containerWidth: Infinity,
      tabWidths: [],
      visibleTabs: [],
      hiddenTabs: [],
      showDisclosure: false,
      tabToFocus: -1,
      isNewViewModalActive: false,
      modalSubmitted: false,
      isTabsFocused: false,
      isTabPopoverOpen: false,
      isTabModalOpen: false,
    }
  );
  const { trackTab } = useTabs();

  const { tabToFocus, visibleTabs, hiddenTabs } = state;

  const handleMeasurement = useCallback(
    (measurements: TabMeasurements) => {
      const {
        hiddenTabWidths: tabWidths,
        containerWidth,
        disclosureWidth,
      } = measurements;

      const { visibleTabs, hiddenTabs } = getVisibleAndHiddenTabIndices(
        tabs,
        selected,
        disclosureWidth,
        tabWidths,
        containerWidth
      );
      setState({
        visibleTabs,
        hiddenTabs,
        disclosureWidth,
        containerWidth,
        tabWidths,
      });
    },
    [tabs, selected, setState]
  );

  const activator = (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex whitespace-nowrap p-1 text-sm font-semibold text-[#767A7C] focus-visible:rounded-sm focus-visible:shadow-[0_0_0_4px_#ECECEC] focus-visible:outline-none">
        {t('orders.tabs.x_views', { count: hiddenTabs.length })}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {hiddenTabs.map((tabIndex) => (
          <DropdownMenuItem
            key={tabIndex}
            className="flex space-x-2"
            onSelect={() => {
              resetList(tabs[tabIndex].value);
              trackTab(tabs[tabIndex].value);
            }}
          >
            <span className="text-gray-700">{tabs[tabIndex]?.label}</span>
            <TabCounter counter={tabs[tabIndex]?.counter} />
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  const disclosureActivatorVisible = visibleTabs.length < tabs.length;

  const tabMeasurer = (
    <TabMeasurer
      tabToFocus={tabToFocus}
      activator={activator}
      selected={selected}
      tabs={tabs}
      siblingTabHasFocus={tabToFocus > -1}
      handleMeasurement={handleMeasurement}
    />
  );

  const renderTabMarkup = useCallback(
    (tab: TabProps, index: number) => {
      return (
        <TabsTrigger
          {...tab}
          key={`${index}-${tab.id}`}
          id={tab.id}
          value={tab.value}
          className={cn('flex whitespace-nowrap', {
            'flex-auto': disclosureActivatorVisible,
          })}
        >
          <span>{tab.label}</span>
          <TabCounter counter={tab.counter} />
        </TabsTrigger>
      );
    },
    [disclosureActivatorVisible]
  );

  const tabsMarkup = visibleTabs
    .sort((tabA, tabB) => tabA - tabB)
    .filter((tabIndex) => tabs[tabIndex])
    .map((tabIndex) => renderTabMarkup(tabs[tabIndex], tabIndex));

  return (
    <div className={cn('flex-1', className)}>
      <TabsPrimitive.List>{tabMeasurer}</TabsPrimitive.List>
      <div className="flex flex-wrap items-stretch justify-start">
        <TabsList>
          {tabsMarkup}
          {disclosureActivatorVisible ? activator : null}
        </TabsList>
      </div>
    </div>
  );
};
