import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Select, {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';

import { useGetTimezonesQuery } from '../../api/graphql';
import { Brand, brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getPhoneCountryCode, parsePhoneNumber } from '../../utils/phone';
import { removeAllSpaces } from '../../utils/string';
import {
  BrandContactFormData,
  ContactFormFields,
} from '../brand-info/contact-form-fields';

type SettingsGeneralProps = {
  onPostSave: () => void;
};

type SettingsGeneralFormData = {
  timezone?: string;
} & BrandContactFormData;

export const SettingsGeneral: React.FC<SettingsGeneralProps> = ({
  onPostSave,
}) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { i18n, t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();
  const [getTimezones, { data }] = useGetTimezonesQuery();

  const parsedDefaultPhoneNumber = parsePhoneNumber(brand?.contact_phone ?? '');

  const form = useForm<SettingsGeneralFormData>({
    mode: 'onTouched',
    defaultValues: {
      email: brand?.contact_email ?? '',
      phone:
        parsedDefaultPhoneNumber?.formatInternational()?.replace(/\s/g, '') ??
        '',
      phoneCountryCode: getPhoneCountryCode(
        parsedDefaultPhoneNumber,
        brand?.store ?? StoreRegion.Fr
      ),
      timezone:
        brand?.timezone ??
        (brand?.store === StoreRegion.Fr ? 'Europe/Paris' : 'America/New_York'),
    },
  });

  useEffect(() => {
    getTimezones({ locale: i18n.language === 'fr' ? 'fr' : 'en' });
  }, []);

  const onContactInfoUpdate = (
    data: Pick<
      NonNullable<Brand>,
      'contact_phone' | 'contact_email' | 'is_french' | 'timezone'
    >
  ) => {
    if (!brand) return;
    setBrand({
      ...brand,
      contact_email: data.contact_email,
      contact_phone: data.contact_phone,
      is_french: data.is_french,
      timezone: data.timezone,
    });
    updateSellerMutation({
      updateSeller: {
        contact_email: data.contact_email,
        contact_phone: data.contact_phone,
        is_french: data.is_french,
        timezone: data.timezone,
      },
    });
    onPostSave();
  };

  const onSubmit = (data: SettingsGeneralFormData) => {
    onContactInfoUpdate({
      contact_phone: data.phone,
      contact_email: removeAllSpaces(data.email),
      is_french: brand ? brand.is_french : true,
      timezone: data.timezone,
    });
  };

  // Rerender on 'phoneCountryCode' change
  form.watch('phoneCountryCode');

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
        <div className="max-w-2.5xl">
          <h3 className="mb-8 text-2xl font-bold">
            {t('settings.general_tabs.contact')}
          </h3>
          <div className="space-y-6">
            <ContactFormFields />
          </div>
        </div>
        <div className="max-w-2.5xl">
          <h3 className="mb-2 text-2xl font-bold">
            {t('settings.general_tabs.timezone')}
          </h3>
          <p className="mb-8 text-sm text-gray-700">
            {t('settings.general_tabs.timezone_details')}
          </p>

          <FormField
            control={form.control}
            name="timezone"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <FormItem>
                <FormLabel>
                  {t('settings.general_tabs.fields.timezone.label')}
                </FormLabel>
                <Select onValueChange={onChange} value={value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t(
                          'settings.general_tabs.fields.timezone.empty_field'
                        )}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {data?.data?.timeZones?.map((option) => (
                      <SelectItem
                        value={option.olsonName}
                        key={option.olsonName}
                      >
                        {option.description}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage match="required">
                  {t(
                    'settings.general_tabs.fields.timezone.validation_errors.required'
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
        </div>
        <Button type="submit">{t('save')}</Button>
      </form>
    </Form>
  );
};

SettingsGeneral.displayName = 'SettingsGeneral';
