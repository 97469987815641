type NoGlobeIllutrationProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const NoGlobeIllustration: React.FC<NoGlobeIllutrationProps> = (
  props: NoGlobeIllutrationProps
) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="40"
        cy="40"
        rx="14"
        ry="34"
        stroke="#0B1115"
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.7592 12.0744C55.7564 7.3904 48.2041 4.59961 40.0001 4.59961C20.4492 4.59961 4.6001 20.4487 4.6001 39.9996C4.6001 47.8002 7.12318 55.0116 11.3978 60.862L13.4302 58.8932C9.88616 53.9182 7.7045 47.9056 7.42962 41.3996H31.488L34.3783 38.5996H7.42962C8.16289 21.2446 22.4647 7.39961 40.0001 7.39961C47.4141 7.39961 54.25 9.87453 59.7268 14.0433L61.7592 12.0744ZM67.7268 17.9885L65.7051 19.947C69.7575 25.1344 72.274 31.5798 72.5706 38.5996H46.4509L43.5605 41.3996H72.5706C71.8373 58.7547 57.5355 72.5996 40.0001 72.5996C32.0665 72.5996 24.7949 69.7656 19.1422 65.0548L17.1206 67.0133C23.2909 72.2445 31.277 75.3996 40.0001 75.3996C59.551 75.3996 75.4001 59.5505 75.4001 39.9996C75.4001 31.6803 72.5304 24.0314 67.7268 17.9885Z"
        fill="#0B1115"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0051 7.0255C73.5431 7.58084 73.5291 8.46716 72.9737 9.00515L8.97372 71.0051C8.41838 71.5431 7.53206 71.5291 6.99407 70.9737C6.45609 70.4184 6.47015 69.5321 7.0255 68.9941L71.0255 6.99407C71.5808 6.45609 72.4672 6.47015 73.0051 7.0255Z"
        fill="#0B1115"
      />
    </svg>
  );
};
