type ConfettiProps = React.ComponentPropsWithoutRef<'svg'>;

export const Confetti: React.FC<ConfettiProps> = ({
  ...props
}: ConfettiProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 1.99622C13.498 1.44394 13.0485 0.997919 12.4963 1.00001C11.944 1.0021 11.498 1.4515 11.5001 2.00378C11.5047 3.23265 11.4631 3.83577 11.304 4.36619C11.1449 4.89661 10.8476 5.42301 10.1673 6.44639C9.86152 6.90632 9.98651 7.52702 10.4464 7.83278C10.9064 8.13853 11.5271 8.01354 11.8328 7.55361C12.508 6.53791 12.9654 5.78857 13.2197 4.94089C13.474 4.0932 13.5047 3.21587 13.5 1.99622Z"
        fill="currentColor"
      />
      <path
        d="M17.4816 13.8764C18.4721 13.3321 19.0398 13.0854 19.5949 12.9744C20.15 12.8634 20.769 12.8727 21.8926 12.9942C22.4416 13.0536 22.9349 12.6566 22.9943 12.1075C23.0536 11.5584 22.6566 11.0652 22.1075 11.0058C20.9531 10.881 20.0761 10.8386 19.2027 11.0132C18.3293 11.1879 17.5361 11.5644 16.5184 12.1236C16.0344 12.3896 15.8577 12.9976 16.1237 13.4816C16.3896 13.9656 16.9976 14.1424 17.4816 13.8764Z"
        fill="currentColor"
      />
      <path
        d="M22.4436 2.16886C22.6264 2.68967 22.3526 3.26001 21.8321 3.44325C21.5363 3.54723 21.2475 3.67732 20.967 3.81664C20.4504 4.07317 19.9905 4.37153 19.7706 4.63737C19.6083 4.83364 19.5756 5.03813 19.529 5.61046C19.4874 6.12443 19.4167 6.99755 18.7072 7.70711C17.9976 8.41667 17.1501 8.4853 16.6361 8.52693C16.0638 8.57348 15.8337 8.60822 15.6374 8.77056C15.3716 8.99045 15.0732 9.45036 14.8167 9.96692C14.6774 10.2475 14.5499 10.5358 14.4438 10.8307C14.2608 11.3517 13.6899 11.6264 13.1689 11.4436C12.6478 11.2607 12.3736 10.69 12.5565 10.1689C12.688 9.79574 12.8496 9.43139 13.0254 9.07735C13.2996 8.52533 13.7512 7.73524 14.3627 7.22944C15.0739 6.64116 15.8769 6.57956 16.3619 6.54235C16.8946 6.49901 17.1412 6.44466 17.2929 6.29289C17.4447 6.14112 17.4991 5.89452 17.5424 5.36181C17.5796 4.87685 17.6412 4.07383 18.2295 3.36263C18.7353 2.75115 19.5254 2.2995 20.0774 2.02536C20.5947 1.76848 21.0238 1.60887 21.1392 1.56706C21.6629 1.37956 22.2558 1.63367 22.4436 2.16886Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15201 20.2353C0.850834 20.9631 1.00493 21.8 1.54562 22.3728C2.0863 22.9456 2.91287 23.1477 3.65688 22.8891L14.6779 19.057C16.6611 18.3675 16.9399 15.7341 15.2488 14.5992C14.3389 13.9886 13.0249 13.0625 12.2136 12.3188C11.3681 11.5438 10.3105 10.2718 9.65477 9.44622C8.48247 7.9701 6.11047 8.25235 5.36906 10.0441L1.15201 20.2353ZM14.0211 17.168L12.2907 17.7696C11.0148 17.1902 9.69002 16.6226 8.62181 15.6937C7.73637 14.9237 7.0154 13.9309 6.32973 12.9532L7.21709 10.8088C7.36505 10.4512 7.84794 10.387 8.08859 10.69C8.95042 11.7752 9.83904 12.8553 10.8621 13.7931C11.8699 14.7169 13.0009 15.4993 14.1343 16.2599C14.4819 16.4931 14.4165 17.0305 14.0211 17.168ZM7.30941 17.2029C7.99172 17.7962 8.78456 18.2758 9.60856 18.7022L3.00005 21L5.43391 15.1182C6.00163 15.872 6.61005 16.5947 7.30941 17.2029Z"
        fill="currentColor"
      />
      <path
        d="M7.49811 5.49988C8.05039 5.49988 8.49811 5.05216 8.49811 4.49988C8.49811 3.94759 8.05039 3.49988 7.49811 3.49988C6.94582 3.49988 6.49811 3.94759 6.49811 4.49988C6.49811 5.05216 6.94582 5.49988 7.49811 5.49988Z"
        fill="currentColor"
      />
      <path
        d="M20.4981 16.4999C20.4981 17.0522 20.0504 17.4999 19.4981 17.4999C18.9458 17.4999 18.4981 17.0522 18.4981 16.4999C18.4981 15.9476 18.9458 15.4999 19.4981 15.4999C20.0504 15.4999 20.4981 15.9476 20.4981 16.4999Z"
        fill="currentColor"
      />
      <path
        d="M23.0079 8C23.5602 8 24.0079 7.55228 24.0079 7C24.0079 6.44772 23.5602 6 23.0079 6C22.4556 6 22.0079 6.44772 22.0079 7C22.0079 7.55228 22.4556 8 23.0079 8Z"
        fill="currentColor"
      />
    </svg>
  );
};
