import React from 'react';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

const FaqAccordion = AccordionPrimitive.Root;

const FaqAccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-t border-gray-300', className)}
    {...props}
  />
));
FaqAccordionItem.displayName = 'FaqAccordionItem';

const FaqAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'group flex flex-1 items-center justify-between py-6 font-bold text-green-900 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-900/30 data-[state=open]:pb-2',
        className
      )}
      {...props}
    >
      {children}
      <Icon
        icon="plus"
        size="large"
        className="hidden text-gray-500 group-data-[state=closed]:block"
      />
      <Icon
        icon="minus"
        size="large"
        className="hidden text-gray-500 group-data-[state=open]:block"
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
FaqAccordionTrigger.displayName = 'FaqAccordionTrigger';

const FaqAccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm transition-all"
    {...props}
  >
    <div className={cn(className)}>{children}</div>
  </AccordionPrimitive.Content>
));

FaqAccordionContent.displayName = 'FaqAccordionContent';

export {
  FaqAccordion,
  FaqAccordionContent,
  FaqAccordionItem,
  FaqAccordionTrigger,
};
