import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';

import { OrderTagShipment } from '../../types/generated';
import { OrderQuery } from '../../types/generated-new';
import { OrderBadge } from './order-badge';
import {
  getOrderShipmentLabelFromTag,
  orderShipmentVariant,
} from './order-shipment-badge';

export const ModalOrderShipmentBadge: React.FC<{
  hasTrackingStucked: OrderQuery['order']['parcels'][number]['hasTrackingStucked'];
  isCancelled?: boolean;
  tag: OrderTagShipment;
  trackingSubStatus: OrderQuery['order']['parcels'][number]['trackingSubStatus'];
}> = ({ hasTrackingStucked, isCancelled, tag, trackingSubStatus }) => {
  const { t } = useTranslation();

  if (tag === OrderTagShipment.None) return null;

  const variantStyle = orderShipmentVariant(
    {
      isCancelled,
      hasTrackingStucked,
      tag,
      trackingSubStatus,
    },
    'filled'
  );

  return (
    <OrderBadge style={variantStyle} intent="bold">
      <div className="flex flex-nowrap items-center gap-x-1">
        {variantStyle === 'danger' || variantStyle === 'warning' ? (
          <Icon icon="alertCircle" className="size-3.5" />
        ) : (
          <Icon icon="truck" className="size-3.5" />
        )}
        <span className={isCancelled ? 'line-through' : ''}>
          {getOrderShipmentLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
