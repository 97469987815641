import { Key, useEffect, useState } from 'react';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';

import SelectorElement from '../selector-element/selector-element';

import './selector.scss';

type SelectorProps<T extends Key | null | undefined> = {
  selected: T;
  name: string;
  subtext?: string;
  onSelect: (key: T) => void;
  values: {
    value: string;
    disabled?: boolean;
    key: T;
  }[];
};

export function Selector<T extends Key | null | undefined>(
  props: SelectorProps<T>
) {
  const [display, changeDisplay] = useState(false);

  function getValue() {
    if (props.selected) {
      const item = props.values.find((c) => c.key === props.selected);
      if (item) {
        return item.value;
      }
    }
    return '';
  }

  useEffect(() => {
    if (!display) {
      return;
    }

    function handleKeyUp(e: KeyboardEvent) {
      if (e.code === 'Escape') {
        hide();
      }
    }
    window.addEventListener('click', detectClick);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('click', detectClick);
    };
  }, [display]);

  function detectClick(e: Event) {
    const elt = document.querySelector(`[data-key="${props.name}_selector"]`);
    const target = e.target as Node;
    if (elt?.contains(target)) {
      //
    } else {
      hide();
    }
  }

  function hide() {
    changeDisplay(false);
  }

  function show() {
    changeDisplay(true);
  }
  function onClick() {
    if (display) {
      hide();
    } else {
      show();
    }
  }

  function onSelect(key: T, disabled?: boolean) {
    if (!disabled) {
      props.onSelect(key);
      hide();
    }
  }

  return (
    <div className="selector-component" data-key={`${props.name}_selector`}>
      <div className="btn" onClick={onClick}>
        <p>
          {props.subtext ?? ''}
          {getValue()}
        </p>
        <Icon
          icon="arrowDown"
          size="large"
          className={cn(`inline-block transition-transform duration-300`, {
            'rotate-180': display,
          })}
        />
      </div>
      <div className={cn('modal-update', display ? 'fadeIn' : 'fadeOut')}>
        {props.values.map((c) => {
          return (
            <div
              className="item"
              key={c.key}
              data-disabled={c.disabled}
              onClick={() => onSelect(c.key, c.disabled)}
            >
              <SelectorElement
                disabled={c.disabled}
                selected={props.selected === c.key}
              />
              <p>{c.value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
