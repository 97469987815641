export const SkeletonReview: React.FC = () => {
  return (
    <svg
      width="880"
      height="102"
      viewBox="0 0 880 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="82" r="20" fill="#ECECEC" />
      <rect y="30" width="235" height="16" rx="2" fill="#ECECEC" />
      <rect x="824" width="56" height="56" rx="4" fill="#ECECEC" />
      <rect x="50" y="65" width="84" height="12" rx="2" fill="#ECECEC" />
      <rect x="50" y="85" width="195" height="12" rx="2" fill="#ECECEC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99937 0.666016C8.25311 0.666016 8.48486 0.810064 8.59717 1.0376L10.5022 4.89699L14.7625 5.51969C15.0135 5.55639 15.2219 5.73239 15.3002 5.97373C15.3785 6.21507 15.3129 6.4799 15.1312 6.65692L12.049 9.65893L12.7764 13.9C12.8193 14.1501 12.7165 14.4029 12.5112 14.5521C12.3059 14.7012 12.0337 14.7209 11.8091 14.6027L7.99937 12.5992L4.18967 14.6027C3.96506 14.7209 3.69286 14.7012 3.48755 14.5521C3.28223 14.4029 3.1794 14.1501 3.2223 13.9L3.94969 9.65893L0.867547 6.65692C0.685801 6.4799 0.620286 6.21507 0.698538 5.97373C0.776789 5.73239 0.985243 5.55639 1.23628 5.51969L5.49653 4.89699L7.40156 1.0376C7.51388 0.810064 7.74562 0.666016 7.99937 0.666016Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9994 0.666016C26.2531 0.666016 26.4849 0.810064 26.5972 1.0376L28.5022 4.89699L32.7625 5.51969C33.0135 5.55639 33.2219 5.73239 33.3002 5.97373C33.3785 6.21507 33.3129 6.4799 33.1312 6.65692L30.049 9.65893L30.7764 13.9C30.8193 14.1501 30.7165 14.4029 30.5112 14.5521C30.3059 14.7012 30.0337 14.7209 29.8091 14.6027L25.9994 12.5992L22.1897 14.6027C21.9651 14.7209 21.6929 14.7012 21.4875 14.5521C21.2822 14.4029 21.1794 14.1501 21.2223 13.9L21.9497 9.65893L18.8675 6.65692C18.6858 6.4799 18.6203 6.21507 18.6985 5.97373C18.7768 5.73239 18.9852 5.55639 19.2363 5.51969L23.4965 4.89699L25.4016 1.0376C25.5139 0.810064 25.7456 0.666016 25.9994 0.666016Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9994 0.666016C44.2531 0.666016 44.4849 0.810064 44.5972 1.0376L46.5022 4.89699L50.7625 5.51969C51.0135 5.55639 51.2219 5.73239 51.3002 5.97373C51.3785 6.21507 51.3129 6.4799 51.1312 6.65692L48.049 9.65893L48.7764 13.9C48.8193 14.1501 48.7165 14.4029 48.5112 14.5521C48.3059 14.7012 48.0337 14.7209 47.8091 14.6027L43.9994 12.5992L40.1897 14.6027C39.9651 14.7209 39.6929 14.7012 39.4875 14.5521C39.2822 14.4029 39.1794 14.1501 39.2223 13.9L39.9497 9.65893L36.8675 6.65692C36.6858 6.4799 36.6203 6.21507 36.6985 5.97373C36.7768 5.73239 36.9852 5.55639 37.2363 5.51969L41.4965 4.89699L43.4016 1.0376C43.5139 0.810064 43.7456 0.666016 43.9994 0.666016Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9994 0.666016C62.2531 0.666016 62.4849 0.810064 62.5972 1.0376L64.5022 4.89699L68.7625 5.51969C69.0135 5.55639 69.2219 5.73239 69.3002 5.97373C69.3785 6.21507 69.3129 6.4799 69.1312 6.65692L66.049 9.65893L66.7764 13.9C66.8193 14.1501 66.7165 14.4029 66.5112 14.5521C66.3059 14.7012 66.0337 14.7209 65.8091 14.6027L61.9994 12.5992L58.1897 14.6027C57.9651 14.7209 57.6929 14.7012 57.4875 14.5521C57.2822 14.4029 57.1794 14.1501 57.2223 13.9L57.9497 9.65893L54.8675 6.65692C54.6858 6.4799 54.6203 6.21507 54.6985 5.97373C54.7768 5.73239 54.9852 5.55639 55.2363 5.51969L59.4965 4.89699L61.4016 1.0376C61.5139 0.810064 61.7456 0.666016 61.9994 0.666016Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9994 0.666016C80.2531 0.666016 80.4849 0.810064 80.5972 1.0376L82.5022 4.89699L86.7625 5.51969C87.0135 5.55639 87.2219 5.73239 87.3002 5.97373C87.3785 6.21507 87.3129 6.4799 87.1312 6.65692L84.049 9.65893L84.7764 13.9C84.8193 14.1501 84.7165 14.4029 84.5112 14.5521C84.3059 14.7012 84.0337 14.7209 83.8091 14.6027L79.9994 12.5992L76.1897 14.6027C75.9651 14.7209 75.6929 14.7012 75.4875 14.5521C75.2822 14.4029 75.1794 14.1501 75.2223 13.9L75.9497 9.65893L72.8675 6.65692C72.6858 6.4799 72.6203 6.21507 72.6985 5.97373C72.7768 5.73239 72.9852 5.55639 73.2363 5.51969L77.4965 4.89699L79.4016 1.0376C79.5139 0.810064 79.7456 0.666016 79.9994 0.666016Z"
        fill="#ECECEC"
      />
      <rect width="880" height="102" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="0"
          x2="17.2607"
          y2="183.259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.5" stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
