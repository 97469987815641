import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { atom, useRecoilState } from 'recoil';

import Icon from '@appchoose/icon';
import { useKeyboardEvent } from '@react-hookz/web';

import { getSearchParam } from '../../utils/url';

const searchTermState = atom<string | undefined>({
  key: 'stockSearchTermState',
  default: Promise.resolve(getSearchParam('q')),
});

const debouncedSearchTermState = atom<string | undefined>({
  key: 'debouncedStockSearchTermState',
  default: Promise.resolve(getSearchParam('q')),
});

export const useSearch = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermState);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useRecoilState(
    debouncedSearchTermState
  );
  useDebounce(() => setDebouncedSearchTerm(searchTerm), 150, [searchTerm]);

  const resetSearch = () => {
    setSearchTerm(undefined);
  };

  return {
    debouncedSearchTerm,
    resetSearch,
    searchTerm,
    setSearchTerm,
  } as const;
};

export const StockSearch = () => {
  const { searchTerm, setSearchTerm, resetSearch } = useSearch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const clearSearch = () => {
    resetSearch();
    setSearchParams((prev) => {
      prev.delete('q');
      return prev;
    });
  };

  useKeyboardEvent('Escape', (e) => {
    const searchInput =
      (e.target as Element | null)?.nodeName === 'INPUT' &&
      (e.target as HTMLInputElement).type === 'search';
    // On active le raccourci seulement si on est sur l'input de recherche,
    // que la recherche est active et que le champ de recherche est vide
    if (!searchTerm && searchInput) {
      clearSearch();
    }
  });

  return (
    <div className="relative w-full rounded text-[#767A7C] transition-colors duration-300 focus-within:text-gray-900 sm:border-b sm:border-gray-100">
      <div className="absolute inset-y-0 left-0 flex items-center pl-2 sm:pl-0 [&:has(~_:disabled)]:text-gray-300">
        <Icon icon="search" />
      </div>
      <input
        type="search"
        className="no-cancel-search form-input w-full rounded border px-8 pb-[0.44rem] pt-2 text-sm leading-5.5 text-gray-900 outline-none hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 sm:border-none sm:px-6 sm:focus:ring-transparent"
        placeholder={t('stock.search.placeholder')}
        value={searchTerm ?? ''}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setSearchParams((prev) => {
            prev.set('q', e.target.value);
            return prev;
          });
        }}
      />
      {searchTerm ? (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-900 sm:pr-0 [&:has(~_:disabled)]:text-gray-300">
          <button type="button" onClick={() => clearSearch()}>
            <Icon icon="close" />
          </button>
        </div>
      ) : null}
    </div>
  );
};
