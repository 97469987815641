type AdminProps = React.ComponentPropsWithoutRef<'svg'>;

export const Admin: React.FC<AdminProps> = ({ ...props }: AdminProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 5.25C5.25 3.17893 6.92893 1.5 9 1.5C11.0711 1.5 12.75 3.17893 12.75 5.25C12.75 7.32107 11.0711 9 9 9C6.92893 9 5.25 7.32107 5.25 5.25ZM3.34835 11.5984C4.05161 10.8951 5.00544 10.5 6 10.5H12C12.9946 10.5 13.9484 10.8951 14.6517 11.5984C15.3549 12.3016 15.75 13.2554 15.75 14.25V15.75C15.75 16.1642 15.4142 16.5 15 16.5H3C2.58579 16.5 2.25 16.1642 2.25 15.75V14.25C2.25 13.2554 2.64509 12.3016 3.34835 11.5984ZM7.98632 12C8.378 12 8.71498 12.1344 8.99726 12.4033C9.27954 12.1344 9.61651 12 10.0082 12C10.4195 12 10.7705 12.1483 11.0612 12.4449C11.3519 12.7357 11.5 13.0894 11.5 13.5C11.5 13.9106 11.3519 14.2643 11.0612 14.5608C10.7705 14.8517 10.4195 15 10.0082 15C9.61651 15 9.27954 14.8655 8.99726 14.6016C8.71498 14.8655 8.378 15 7.98632 15C7.57498 15 7.22397 14.8517 6.93328 14.5608C6.6426 14.2643 6.5 13.9106 6.5 13.5C6.5 13.0894 6.6426 12.7357 6.93328 12.4449C7.22397 12.1483 7.57498 12 7.98632 12ZM8.25507 14.8403C8.1015 14.8403 7.9589 14.7262 7.82727 14.4981C7.69564 14.27 7.59692 14.0076 7.52562 13.7167C7.4598 13.4201 7.42689 13.1521 7.42689 12.9068C7.42689 12.4106 7.52013 12.1654 7.70661 12.1654C7.85469 12.1654 7.99729 12.2795 8.13441 12.5019C8.27152 12.7243 8.37024 12.9753 8.44154 13.2662C8.51284 13.557 8.55123 13.8194 8.55123 14.0589C8.55123 14.538 8.44703 14.8403 8.25507 14.8403ZM10.2769 14.8403C10.1234 14.8403 9.98077 14.7262 9.84914 14.4981C9.71751 14.27 9.61879 14.0076 9.54749 13.7167C9.48167 13.4201 9.44876 13.1521 9.44876 12.9068C9.44876 12.4106 9.542 12.1654 9.72848 12.1654C9.87656 12.1654 10.0192 12.2795 10.1563 12.5019C10.2934 12.7243 10.3921 12.9753 10.4634 13.2662C10.5347 13.557 10.5731 13.8194 10.5731 14.0589C10.5731 14.538 10.4689 14.8403 10.2769 14.8403Z"
        fill="currentColor"
      />
    </svg>
  );
};
