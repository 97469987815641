import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { useSetRecoilState } from 'recoil';

import Loader from '@appchoose/loader';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { useBrandInfoQuery } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';

type BasicBrandInfoProps = {
  children: React.ReactNode;
};

export const BasicBrandInfo: React.FC<BasicBrandInfoProps> = ({
  children,
}: BasicBrandInfoProps) => {
  const { brandId } = useParams<BrandMatch>();
  const [, setBrandId] = useSessionStorage<string>('brandId');

  const setBrand = useSetRecoilState(brandState);

  useEffect(() => {
    setBrandId(brandId ?? '');
  }, []);

  const { data: brandInfoData, isLoading } = useBrandInfoQuery(
    {
      brandId: brandId ?? '',
    },
    {
      enabled: !!brandId && brandId !== 'undefined',
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (brandInfoData?.brandInfo) {
      setBrand({
        id: brandId ?? '',
        name: brandInfoData.brandInfo.name,
        accept_contract: brandInfoData.brandInfo.acceptContract,
        nbUsers: brandInfoData.brandInfo.nbUsers,
        store: StoreRegion.Fr,
        need_refresh_info: false,
      });
    }
  }, [brandInfoData]);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="h-8 w-8" />
      </div>
    );
  }

  return children;
};

BasicBrandInfo.displayName = 'BasicBrandInfo';
