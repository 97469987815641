import React from 'react';

import cn from '@appchoose/cn';

type ImageWithQuantityProps = {
  imageUrl: string;
  quantity: number;
  className?: string;
};

export const ImageWithQuantity: React.FC<ImageWithQuantityProps> = ({
  imageUrl,
  quantity,
  className,
}: ImageWithQuantityProps) => {
  return (
    <div className="relative shrink-0">
      <img
        src={imageUrl}
        className={cn(
          `rounded border-0.5 border-gray-300 object-cover`,
          className
        )}
      />
      {quantity > 1 ? (
        <p className="absolute left-0 top-2 m-0 rounded-r-sm bg-gray-900 px-1 py-px text-xs text-white">
          x{quantity}
        </p>
      ) : null}
    </div>
  );
};
