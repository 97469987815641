import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Daytime } from '../../components/daytime/daytime';
import { isWaitingForSaleState } from '../../stores/sales';

export const WelcomeMessage: React.FC = () => {
  const { t } = useTranslation();
  const isWaitingForSale = useRecoilValue(isWaitingForSaleState);

  const currentHourOfTheDay = new Date().getHours();

  let welcomeMessage = t('home.morning_message');
  if (currentHourOfTheDay >= 5 && 12 < currentHourOfTheDay) {
    welcomeMessage = t('home.morning_message');
  } else if (12 <= currentHourOfTheDay && currentHourOfTheDay < 17) {
    welcomeMessage = t('home.afternoon_message');
  } else if (17 <= currentHourOfTheDay) {
    welcomeMessage = t('home.evening_message');
  }

  if (isWaitingForSale) {
    welcomeMessage = t('home.welcome_message');
  }

  return (
    <div className="flex flex-row items-center space-x-4">
      <Daytime date={new Date()} showSmiley={isWaitingForSale} />
      <h2 className="text-gray-900">{welcomeMessage}</h2>
    </div>
  );
};

WelcomeMessage.displayName = 'WelcomeMessage';
