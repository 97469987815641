export const DashedLine = () => {
  return (
    <div className="h-px w-full">
      <svg viewBox="0 0 10 1" className="w-full text-gray-300">
        <line
          x1="0"
          x2="10"
          y1="0"
          y2="0"
          stroke="currentColor"
          strokeWidth="1"
          strokeDasharray="4, 4"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );
};
