import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import { isValidIBAN } from 'ibantools';

import { generateIbanPlaceholder } from '../../utils/iban';
import { IbanInput } from '../iban-input/iban-input';

type IbanFormFieldsProps = {
  countryCode: string;
  autoFocus?: boolean;
};

export type IbanFormData = {
  iban: string;
};

export const IbanFormFields: React.FC<IbanFormFieldsProps> = ({
  countryCode,
  autoFocus,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<IbanFormData>();

  return (
    <FormField
      control={form.control}
      name="iban"
      rules={{
        required: true,
        maxLength: 50,
        validate: {
          validIban: (value: string) => isValidIBAN(value),
        },
      }}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t('brand_info.iban_form_fields.iban.label')}</FormLabel>
          <FormControl>
            <IbanInput
              placeholder={generateIbanPlaceholder(countryCode)}
              autoFocus={autoFocus}
              {...field}
            />
          </FormControl>
          <FormMessage match="required">
            {t('brand_info.iban_form_fields.iban.validation_errors.required')}
          </FormMessage>
          <FormMessage match="maxLength">
            {t('brand_info.iban_form_fields.iban.validation_errors.maxLength', {
              maxLength: '50',
            })}
          </FormMessage>
          <FormMessage match="validIban">
            {t('brand_info.iban_form_fields.iban.validation_errors.validIban')}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

IbanFormFields.displayName = 'IbanFormFields';
