import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../../types/generated';
import {
  ShippingDelayFormData,
  ShippingDelayFormFields,
} from '../../shipping-delay-form-fields/shipping-delay-form-fields';

export type OnboardingStepShippingShippingDelayProps = {
  goToNextSubStepOrStep: () => void;
};

export const OnboardingStepShippingShippingDelay: React.FC<
  OnboardingStepShippingShippingDelayProps
> = ({ goToNextSubStepOrStep }: OnboardingStepShippingShippingDelayProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const form = useForm<ShippingDelayFormData>({
    mode: 'onTouched',
    defaultValues: {
      deliveryDelaysMin: brand?.min_delivery_delay ?? undefined,
      deliveryDelaysMax: brand?.max_delivery_delay ?? undefined,
    },
  });

  const onSubmit = (data: ShippingDelayFormData) => {
    if (!brand) return;
    setBrand({
      ...brand,
      min_delivery_delay: data.deliveryDelaysMin,
      max_delivery_delay: data.deliveryDelaysMax,
    });
    updateSellerMutation({
      updateSeller: {
        min_delivery_delay: data.deliveryDelaysMin,
        max_delivery_delay: data.deliveryDelaysMax,
      },
    });
    goToNextSubStepOrStep();
  };

  return (
    <>
      <div className="mb-6 sm:mb-10">
        <h2 className="mt-8 text-2xl font-bold sm:mt-20 sm:text-3.5xl">
          {t('onboarding.shipping.sub_steps.5.title')}
        </h2>
        <p className="text-sm text-gray-700 sm:text-base">
          {brand?.store === StoreRegion.Fr
            ? t('onboarding.shipping.sub_steps.5.subtitle_fr')
            : t('onboarding.shipping.sub_steps.5.subtitle_us')}
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ShippingDelayFormFields autoFocus />
          <Alert
            size="small"
            icon="information"
            title={t(
              'onboarding.shipping.sub_steps.5.average_delivery_time_alert.title'
            )}
            className="mb-10"
          >
            <p className="text-sm text-gray-700">
              {t(
                'onboarding.shipping.sub_steps.5.average_delivery_time_alert.description'
              )}
            </p>
          </Alert>
          <div>
            <Button type="submit" size="large">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

OnboardingStepShippingShippingDelay.displayName =
  'OnboardingStepShippingShippingDelay';
