import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';

import { PasswordFormField } from '../../components/password-form-field/password-form-field';
import { PersonalNameFormFields } from '../../components/personal-name-form-fields/personal-name-form-fields';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import { useCreatePasswordConnectionMutation } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';

export type OnboardingUpdateProfileForm = {
  firstName: string;
  lastName: string;
  password: string;
};

export const OnboardingUpdateProfile: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { brandId = '' } = useParams<BrandMatch>();

  const [redirectUri] = useSessionStorage<string>('redirectUri');

  if (user && user['https://appchoose.io/claims/needOnboarding'] === false) {
    if (redirectUri) {
      navigate(redirectUri, { replace: true });
    } else {
      navigate(`/${brandId}/home`, { replace: true });
    }
  }

  const form = useForm<OnboardingUpdateProfileForm>({
    mode: 'onTouched',
    defaultValues: {
      firstName:
        user?.['https://appchoose.io/claims/firstname'] ??
        user?.given_name ??
        '',
      lastName:
        user?.['https://appchoose.io/claims/lastname'] ??
        user?.family_name ??
        '',
      password: '',
    },
  });

  const { mutateAsync: createPasswordConnectionMutation, isPending } =
    useCreatePasswordConnectionMutation();

  const onSubmit = async (data: OnboardingUpdateProfileForm) => {
    try {
      await createPasswordConnectionMutation({
        userInfo: {
          firstname: data.firstName,
          lastname: data.lastName,
          password: data.password,
        },
      });

      await getAccessTokenSilently({ cacheMode: 'off' });

      if (user && user['https://appchoose.io/claims/isRegistered'] === false) {
        navigate(`/${brandId}/welcome`, { replace: true });
      } else {
        if (redirectUri) {
          navigate(redirectUri, { replace: true });
        } else {
          navigate(`/${brandId}/home`, { replace: true });
        }
      }
    } catch (error) {
      toast.error(t('auth.onboarding_update_profile.generic_error'));
    }
  };

  form.watch('firstName');

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader />
      <main className="mx-2 mt-10 sm:mx-8 sm:mt-20 md:mx-24">
        <h1 className="mb-4 text-3.5xl font-bold">
          {t('auth.onboarding_update_profile.set_personal_information')}
        </h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
            <p className="m-0 text-base leading-5.5 text-gray-700">
              {t('auth.onboarding_update_profile.better_knowing_you')}
            </p>
            <div className="space-y-6">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <PersonalNameFormFields />
              </div>
              <div className="space-y-2">
                <PasswordFormField />
              </div>
            </div>
            <Button
              type="submit"
              appearance="primary"
              size="large"
              disabled={isPending}
            >
              {t('validate')}
            </Button>
          </form>
        </Form>
      </main>
    </div>
  );
};

OnboardingUpdateProfile.displayName = 'OnboardingUpdateProfile';
