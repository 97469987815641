import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useTitle } from 'react-use';
import { useRecoilValue } from 'recoil';

import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';

import { ProtectedRouteWithScopes } from '../../components/protected-route-with-scopes/protected-route-with-scopes';
import { SettingsAccount } from '../../components/settings-account/settings-account';
import { SettingsBilling } from '../../components/settings-billing/settings-billing';
import { SettingsDelivery } from '../../components/settings-delivery/settings-delivery';
import { SettingsGeneral } from '../../components/settings-general/settings-general';
import { SettingsMembers } from '../../components/settings-members/settings-members';
import { SettingsIntegrations } from '../../components/settings-shopify/settings-integrations';
import { brandState } from '../../stores/brand';
import { BrandMatch } from '../../types/navigation';
import { isScopeAuthorized } from '../../utils/auth';

export const SettingsScreen: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { brandId = '' } = useParams<BrandMatch>();
  const { search } = useLocation();
  const { t } = useTranslation();

  const { user } = useAuth0();

  useTitle(`${brand?.name ?? ''} ~ Settings`);

  const onPostSave = () => {
    toast.success(t('saved'));
  };

  return (
    <main className="settings relative flex-1 overflow-y-auto focus:outline-none">
      <div className="m-4 md:m-10">
        <header>
          <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
            {t('sidebar.settings')}
          </h2>
        </header>
        <section>
          <header className="mb-10 mt-8 flex flex-row flex-wrap items-center gap-x-8 border-b border-gray-100 pb-4 lg:flex-nowrap">
            {isScopeAuthorized(user, 'scope.brand.settings.seller.write') && (
              <NavLink
                to={`/${brandId}/settings/general${search}`}
                className="px-1 py-2.5 font-bold leading-6 text-gray-500 hover:text-green-900 aria-[current=page]:text-green-900 min-[961px]:text-2xl min-[961px]:leading-6"
                replace
              >
                {t('settings.general')}
              </NavLink>
            )}
            {isScopeAuthorized(user, 'scope.brand.settings.seller.write') && (
              <NavLink
                to={`/${brandId}/settings/billing${search}`}
                className="px-1 py-2.5 font-bold leading-6 text-gray-500 hover:text-green-900 aria-[current=page]:text-green-900 min-[961px]:text-2xl min-[961px]:leading-6"
                replace
              >
                {t('settings.billing')}
              </NavLink>
            )}
            {isScopeAuthorized(user, 'scope.brand.settings.seller.write') && (
              <NavLink
                to={`/${brandId}/settings/delivery${search}`}
                className="px-1 py-2.5 font-bold leading-6 text-gray-500 hover:text-green-900 aria-[current=page]:text-green-900 min-[961px]:text-2xl min-[961px]:leading-6"
                replace
              >
                {t('settings.delivery')}
              </NavLink>
            )}
            <NavLink
              to={`/${brandId}/settings/account${search}`}
              className="px-1 py-2.5 font-bold leading-6 text-gray-500 hover:text-green-900 aria-[current=page]:text-green-900 min-[961px]:text-2xl min-[961px]:leading-6"
              replace
            >
              {t('settings.account')}
            </NavLink>
            {isScopeAuthorized(user, 'scope.brand.settings.members.view') && (
              <NavLink
                to={`/${brandId}/settings/member${search}`}
                className="px-1 py-2.5 font-bold leading-6 text-gray-500 hover:text-green-900 aria-[current=page]:text-green-900 min-[961px]:text-2xl min-[961px]:leading-6"
                replace
              >
                {t('settings.members')}
              </NavLink>
            )}
            {isScopeAuthorized(user, 'scope.brand.settings.seller.write') && (
              <NavLink
                to={`/${brandId}/settings/integrations${search}`}
                className="px-1 py-2.5 font-bold leading-6 text-gray-500 hover:text-green-900 aria-[current=page]:text-green-900 min-[961px]:text-2xl min-[961px]:leading-6"
                replace
              >
                {t('settings.integrations')}
              </NavLink>
            )}
          </header>
          <Routes>
            <Route
              path="general/*"
              element={
                <ProtectedRouteWithScopes scope="scope.brand.settings.seller.write">
                  <SettingsGeneral onPostSave={onPostSave} />
                </ProtectedRouteWithScopes>
              }
            />
            <Route
              path="billing/*"
              element={
                <ProtectedRouteWithScopes scope="scope.brand.settings.seller.write">
                  <SettingsBilling onPostSave={onPostSave} />
                </ProtectedRouteWithScopes>
              }
            />
            <Route
              path="delivery/*"
              element={
                <ProtectedRouteWithScopes scope="scope.brand.settings.seller.write">
                  <SettingsDelivery onPostSave={onPostSave} />
                </ProtectedRouteWithScopes>
              }
            />
            <Route
              path="account/*"
              element={<SettingsAccount onPostSave={onPostSave} />}
            />
            <Route
              path="member/*"
              element={
                <ProtectedRouteWithScopes scope="scope.brand.settings.members.view">
                  <SettingsMembers onPostSave={onPostSave} />
                </ProtectedRouteWithScopes>
              }
            />
            <Route
              path="integrations/*"
              element={
                <ProtectedRouteWithScopes scope="scope.brand.settings.seller.write">
                  <SettingsIntegrations />
                </ProtectedRouteWithScopes>
              }
            />
            <Route
              path="*"
              element={
                <Navigate
                  to={`/${brandId}/settings/${
                    isScopeAuthorized(user, 'scope.brand.settings.seller.write')
                      ? 'general'
                      : 'account'
                  }${search}`}
                  replace
                />
              }
            />
          </Routes>
        </section>
      </div>
    </main>
  );
};

SettingsScreen.displayName = 'SettingsScreen';
