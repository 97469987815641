import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

type ModalSuppressionProps = {
  closeModal: () => void;
  handleDowngrade: () => void;
};

export const ModalSuppressionMember = ({
  closeModal,
  handleDowngrade,
}: ModalSuppressionProps) => {
  const { t } = useTranslation();

  function handleClick() {
    handleDowngrade();
    closeModal();
  }
  return (
    <div className="flex h-full flex-col justify-between overflow-hidden">
      <div className="p-10">
        <h2 id="modal-suppression-title" className="mb-4 text-2xl font-bold">
          {t('settings.member_tabs.confirm_remove_member_title')}
        </h2>
        <div className="mb-12 text-sm text-gray-700">
          {t('settings.member_tabs.confirm_remove_member_info')}
        </div>
      </div>
      <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
        <Button
          type="button"
          className="border-none text-green-900"
          appearance="outline"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>{' '}
        <Button type="submit" appearance="primary" onClick={handleClick}>
          {t('confirm')}
        </Button>
      </footer>
    </div>
  );
};
