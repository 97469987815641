import { ClaimSellerAction, OrderQuery } from '../../types/generated-new';
import { ClaimedProduct } from './claimed-product';

export type ProductErrorDetailsProps = {
  claim?: OrderQuery['order']['claims'][0];
};

export const ProductErrorDetails: React.FC<ProductErrorDetailsProps> = ({
  claim,
}: ProductErrorDetailsProps) => {
  const getClaimStatus = () => {
    if (
      claim?.isAccepted &&
      claim?.actionFromSeller ===
        ClaimSellerAction.ItemsDigitalCouponCodesResent
    )
      return 'accepted_resent';
    if (
      claim?.isAccepted &&
      claim?.actionFromSeller ===
        ClaimSellerAction.DirectlyHandledWithTheCustomer
    )
      return 'accepted_handled_with_customer';

    return 'pending';
  };

  if (!claim || (claim?.items?.length ?? 0) === 0) return null;

  return (
    <div className="mt-4">
      <div className="space-y-4">
        {claim?.items.map((product, idx) => (
          <ClaimedProduct
            key={idx}
            product={product}
            claim={getClaimStatus()}
          />
        ))}
      </div>
    </div>
  );
};
