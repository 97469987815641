import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import { Auth0Error } from 'auth0-js';
import Mailchecker from 'mailchecker';

import { Google } from '../../components/icons/google/google';
import { Microsoft } from '../../components/icons/microsoft/microsoft';
import { BrandMatch } from '../../types/navigation';
import {
  PasswordlessStartResult,
  authorizeGoogle,
  authorizeMicrosoft,
  passwordlessStart,
} from '../../utils/auth';

type AuthFormProps = {
  showLoginWithPassword: boolean;
  success: (result: PasswordlessStartResult) => void;
};

export type AuthForm = {
  email: string;
};

export const AuthForm: React.FC<AuthFormProps> = ({
  showLoginWithPassword,
  success,
}: AuthFormProps) => {
  const { t } = useTranslation();
  const form = useForm<AuthForm>({
    mode: 'onTouched',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);

  useUpdateEffect(() => {
    form.trigger();
  }, [serverError]);

  const { brandId = '' } = useParams<BrandMatch>();
  const location = useLocation();

  const onSubmit = async (data: AuthForm) => {
    setIsLoading(true);

    try {
      const result = await passwordlessStart({
        email: data.email,
      });

      success(result);
    } catch (error) {
      switch ((error as Auth0Error)?.code) {
        case 'bad.email':
          setServerError(
            t('auth.errors.auth_passwordless_email.invalid_email')
          );
          break;
        case 'unauthorized':
          setServerError(t('auth.errors.auth_passwordless_email.unauthorized'));
          break;
        case 'too_many_attempts':
        case 'too_many_logins':
          setServerError(
            t('auth.errors.auth_passwordless_email.too_many_attempts', {
              x: '10',
            })
          );
          break;
        default:
          setServerError(
            t('auth.errors.auth_passwordless_email.generic_error')
          );
          break;
      }
    }

    setIsLoading(false);
  };

  form.watch('email');

  const queryParams = new URLSearchParams(location.search);
  if (form.getValues('email')) {
    queryParams.set('email', form.getValues('email'));
  }

  return (
    <>
      <div className="space-y-4">
        <Button
          type="button"
          appearance="primary"
          onClick={() =>
            authorizeGoogle({
              redirectUri: `${window.location.origin}/login-successful`,
            })
          }
          className="w-full justify-center text-base font-bold"
        >
          <Google className="my-1.5 mr-2" />
          {t('auth.continue_with', { name: 'Google' })}
        </Button>
        <Button
          type="button"
          appearance="primary"
          onClick={() =>
            authorizeMicrosoft({
              redirectUri: `${window.location.origin}/login-successful`,
            })
          }
          className="w-full justify-center text-base font-bold"
        >
          <Microsoft className="my-1.5 mr-2" />
          {t('auth.continue_with', { name: 'Microsoft' })}
        </Button>
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-px w-full bg-gray-500"></div>
        <div className="flex justify-center text-sm font-semibold uppercase text-gray-500">
          {t('or')}
        </div>
        <div className="h-px w-full bg-gray-500"></div>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
          <FormField
            control={form.control}
            name="email"
            rules={{
              required: true,
              maxLength: 50,
              validate: {
                email: (value) => Mailchecker.isValid(value),
                server: () => serverError === undefined,
                isChoose: (value) => !value.includes('@appchoose.io'),
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('auth.fields.email.label_professional')}
                </FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="example@gmail.com"
                    autoComplete="email"
                    inputMode="email"
                    {...field}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (serverError !== undefined) {
                        setServerError(undefined);
                      }
                      const { value } = event.target;
                      event.target.value = value.trim();
                      field.onChange(event);
                    }}
                  />
                </FormControl>
                <FormMessage match="required">
                  {t(
                    'brand_info.contact_form_fields.email.validation_errors.required'
                  )}
                </FormMessage>
                <FormMessage match="maxLength">
                  {t(
                    'brand_info.contact_form_fields.email.validation_errors.maxLength',
                    {
                      maxLength: '50',
                    }
                  )}
                </FormMessage>
                <FormMessage match="email">
                  {t(
                    'brand_info.contact_form_fields.email.validation_errors.email'
                  )}
                </FormMessage>
                <FormMessage match="isChoose">
                  {t(
                    'brand_info.contact_form_fields.email.validation_errors.isChoose'
                  )}
                </FormMessage>
                <FormMessage match="server">{serverError}</FormMessage>
              </FormItem>
            )}
          />

          <Button
            type="submit"
            appearance="primary"
            size="large"
            disabled={isLoading}
          >
            {t('continue')}
          </Button>
        </form>
      </Form>
      {showLoginWithPassword && (
        <p className="m-0 text-gray-700">
          {t('auth.login.send_magic_link')} {t('auth.login.you_can_also')}{' '}
          <NavLink
            to={`/${brandId}/login-with-password?${queryParams.toString()}`}
            className="font-bold text-green-900"
          >
            {t('auth.login.connect_manually')}
          </NavLink>
          .
        </p>
      )}
    </>
  );
};
