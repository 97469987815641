import React from 'react';

import { ProfileInfos } from '../profile-infos/profile-infos';

interface SettingsAccountProps {
  onPostSave: () => void;
}

export const SettingsAccount: React.FC<SettingsAccountProps> = ({
  onPostSave,
}) => {
  return (
    <div className="max-w-2.5xl pb-6">
      <ProfileInfos onPostSave={onPostSave} />
    </div>
  );
};

SettingsAccount.displayName = 'SettingsAccount';
