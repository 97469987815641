import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';

import { OnboardingUpdateProfileForm } from '../../views/onboarding-update-profile/onboarding-update-profile';

export const PersonalNameFormFields = () => {
  const { t } = useTranslation();
  const form = useFormContext<OnboardingUpdateProfileForm>();

  return (
    <>
      <FormField
        control={form.control}
        name="firstName"
        rules={{
          required: true,
          maxLength: 200,
        }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('address.fields.firstname.label')}</FormLabel>
            <FormControl>
              <Input type="text" autoComplete="given-name" {...field} />
            </FormControl>
            <FormMessage match="required">
              {t('address.fields.firstname.validation_errors.required')}
            </FormMessage>
            <FormMessage match="maxLength">
              {t('address.fields.firstname.validation_errors.maxLength', {
                maxLength: '200',
              })}
            </FormMessage>
          </FormItem>
        )}
      ></FormField>
      <FormField
        control={form.control}
        name="lastName"
        rules={{
          required: true,
          maxLength: 200,
        }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('address.fields.lastname.label')}</FormLabel>
            <FormControl>
              <Input type="text" autoComplete="family-name" {...field} />
            </FormControl>
            <FormMessage match="required">
              {t('address.fields.lastname.validation_errors.required')}
            </FormMessage>
            <FormMessage match="maxLength">
              {t('address.fields.lastname.validation_errors.maxLength', {
                maxLength: '200',
              })}
            </FormMessage>
          </FormItem>
        )}
      />
    </>
  );
};
