const checkSiretOrSiren = (number: string, size: number) => {
  if (number.length != size) return false;
  if (number.split('').every((num) => parseInt(num) === 0)) return false;

  let bal = 0;
  let total = 0;

  // Luhn algorithm
  for (let i = size - 1; i >= 0; i--) {
    const step = (number.charCodeAt(i) - 48) * (bal + 1);

    total += step > 9 ? step - 9 : step;
    bal = 1 - bal;
  }

  return total % 10 === 0;
};

export const formatSiretForHuman = (siret: string) => {
  if (siret.length <= 6) return siret.replace(/^(.{3})(.*)$/, '$1 $2') || '';
  if (siret.length <= 9)
    return siret.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3') || '';
  return siret.replace(/^(.{3})(.{3})(.{3})(.*)$/, '$1 $2 $3 $4') || '';
};

export const siretValidation = (siret: string) => {
  return checkSiretOrSiren(siret, 14);
};
