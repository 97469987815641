import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';
import { PopupButton } from '@typeform/embed-react';

import { brandState } from '../../stores/brand';
import { useMarkUserOnboardingSurveyAsViewedMutation } from '../../types/generated-new';

export const OnboardingSurvey = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { i18n } = useTranslation();

  const { mutateAsync } = useMarkUserOnboardingSurveyAsViewedMutation();

  const brand = useRecoilValue(brandState);

  const [isOpen, setIsOpen] = useState(true);

  const isOnboardingSurveyViewed = user?.[
    'https://appchoose.io/claims/onboardingSurveyViewed'
  ] as boolean | undefined;

  if (
    isOnboardingSurveyViewed === undefined ||
    isOnboardingSurveyViewed === true ||
    !isOpen
  )
    return null;

  const markUserOnboardingSurveyAsViewed = async () => {
    await mutateAsync({});

    await getAccessTokenSilently({ cacheMode: 'off' });
  };

  const onClose = async () => {
    setIsOpen(false);
    await markUserOnboardingSurveyAsViewed();
  };

  return (
    <PopupButton
      hidden={{
        brand_id: brand?.id ?? '',
        email: user?.email ?? '',
        first_name:
          user?.['https://appchoose.io/claims/firstname'] ?? user?.given_name,
      }}
      id={i18n.language === 'fr' ? 'zvg5QOpM' : 'KrR20ufh'}
      open="load"
      onClose={onClose}
      onEndingButtonClick={onClose}
      size={90}
    />
  );
};
