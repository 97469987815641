import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Address } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Alert from '@appchoose/alert';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import i18n from '../../lang/i18n';
import { SwiftInput } from '../swift-input/swift-input';

export type UsBillingFormFieldsProps = {
  autoFocus: boolean;
  formPrefix?: string;
};

export type UsBillingFormData = {
  accountType: AccountType;
  accountNumber: string;
  beneficiaryName: string;
  routingCode: string;
  swiftCode: string;
} & Address;

export enum AccountType {
  Swift = 'swift',
  RoutingCode = 'routing_code',
}

export const accountTypeOptions = [
  { name: i18n.t('brand_info.swift'), value: AccountType.Swift },
  { name: i18n.t('brand_info.routing_code'), value: AccountType.RoutingCode },
];

export const UsBillingFormFields: React.FC<UsBillingFormFieldsProps> = ({
  autoFocus,
  formPrefix,
}) => {
  const { i18n, t } = useTranslation();
  const form = useFormContext<UsBillingFormData>();
  form.watch('accountType');

  return (
    <>
      <div className="space-y-6">
        <h3 className="text-2xl font-semibold text-gray-900">
          {t('onboarding.billing.sub_steps.1.account_information')}
        </h3>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          <FormField
            control={form.control}
            name="accountNumber"
            rules={{
              required: true,
              maxLength: 17,
              pattern: /^[0-9]+$/,
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('brand_info.legal_info_form_fields.account_number.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="XXXXXXXXX"
                    autoFocus={autoFocus}
                    {...field}
                  />
                </FormControl>
                <FormMessage match="required">
                  {t(
                    'brand_info.legal_info_form_fields.account_number.validation_errors.required'
                  )}
                </FormMessage>
                <FormMessage match="maxLength">
                  {t(
                    'brand_info.legal_info_form_fields.account_number.validation_errors.maxLength',
                    {
                      maxLength: 17,
                    }
                  )}
                </FormMessage>
                <FormMessage match="pattern">
                  {t(
                    'brand_info.legal_info_form_fields.account_number.validation_errors.pattern'
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
          <div className="md:col-span-2">
            <FormField
              control={form.control}
              name="beneficiaryName"
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(
                      'brand_info.legal_info_form_fields.beneficiary_name.label'
                    )}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" autoFocus={autoFocus} {...field} />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'brand_info.legal_info_form_fields.beneficiary_name.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="maxLength">
                    {t(
                      'brand_info.legal_info_form_fields.beneficiary_name.validation_errors.maxLength',
                      {
                        maxLength: 100,
                      }
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
      <div className="space-y-6">
        <h3 className="text-2xl font-semibold text-gray-900">
          {t('onboarding.billing.sub_steps.1.bank_information')}
        </h3>
        <FormField
          control={form.control}
          name="accountType"
          rules={{ required: true }}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <RadioGroup
                  className="mb-6 flex space-x-6"
                  {...field}
                  onValueChange={field.onChange}
                >
                  {accountTypeOptions.map((option) => (
                    <div
                      className="group flex items-center space-x-3"
                      key={option.value}
                    >
                      <RadioGroupItem
                        key={option.value}
                        value={option.value}
                        id={`account-type-${option.value}`}
                      />

                      <Label
                        htmlFor={`account-type-${option.value}`}
                        className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                      >
                        {option.name}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />
        {((form.getValues('accountType') === AccountType.Swift &&
          form.getValues('routingCode')) ||
          (form.getValues('accountType') === AccountType.RoutingCode &&
            form.getValues('swiftCode'))) && (
          <Alert
            appearance="warning"
            icon="information"
            title={t('brand_info.alert_account_type_already_filled')}
          >
            <></>
          </Alert>
        )}
        {form.getValues('accountType') === AccountType.Swift ? (
          <FormField
            control={form.control}
            name="swiftCode"
            rules={{
              required: true,
              maxLength: 11,
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('brand_info.legal_info_form_fields.swift_code.label')}
                </FormLabel>
                <FormControl>
                  <SwiftInput
                    placeholder="XXXXXXXXX"
                    autoFocus={autoFocus}
                    {...field}
                  />
                </FormControl>
                <FormMessage match="required">
                  {t(
                    'brand_info.legal_info_form_fields.swift_code.validation_errors.required'
                  )}
                </FormMessage>
                <FormMessage match="maxLength">
                  {t(
                    'brand_info.legal_info_form_fields.swift_code.validation_errors.maxLength',
                    {
                      maxLength: 11,
                    }
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
        ) : null}
        {form.getValues('accountType') === AccountType.RoutingCode && (
          <div className="space-y-6">
            <FormField
              control={form.control}
              name="routingCode"
              rules={{
                required: true,
                validate: {
                  pattern: (value: string) =>
                    !value || !!/^[0-9]+$/.exec(value),
                  correctLength: (value: string) =>
                    !value || value.length === 9,
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('brand_info.legal_info_form_fields.routing_code.label')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="XXXXXXXXX" {...field} />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'brand_info.legal_info_form_fields.routing_code.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="correctLength">
                    {t(
                      'brand_info.legal_info_form_fields.routing_code.validation_errors.correctLength'
                    )}
                  </FormMessage>
                  <FormMessage match="pattern">
                    {t(
                      'brand_info.legal_info_form_fields.account_number.validation_errors.pattern'
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />

            <AddressFormFields
              googleMapsApiKey={
                import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
              }
              locale={i18n.language}
              showCompany={true}
              showName={false}
              showPhone={false}
              formPrefix={formPrefix}
              translations={{
                suggestions: t('suggestions'),
                fieldsNameValidationErrorsRequired: t(
                  'onboarding.billing.sub_steps.1.bank_address.name.validation_errors.required'
                ),
                fieldsNameValidationErrorsMaxLength: t(
                  'onboarding.billing.sub_steps.1.bank_address.name.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsFirstnameValidationErrorsRequired: t(
                  'address.fields.firstname.validation_errors.required'
                ),
                fieldsFirstnameValidationErrorsMaxLength: t(
                  'address.fields.firstname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsLastnameValidationErrorsRequired: t(
                  'address.fields.lastname.validation_errors.required'
                ),
                fieldsLastnameValidationErrorsMaxLength: t(
                  'address.fields.lastname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsPhoneValidationErrorsRequired: t(
                  'address.fields.phone.validation_errors.required'
                ),
                fieldsPhoneValidationErrorsMaxLength: t(
                  'address.fields.phone.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreetValidationErrorsRequired: t(
                  'onboarding.billing.sub_steps.1.bank_address.street.validation_errors.required'
                ),
                fieldsStreetValidationErrorsMaxLength: t(
                  'onboarding.billing.sub_steps.1.bank_address.street.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreet2ValidationErrorsMaxLength: t(
                  'address.fields.street2.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsBpValidationErrorsRequired: t(
                  'address.fields.bp.validation_errors.required'
                ),
                fieldsBpValidationErrorsMaxLength: t(
                  'address.fields.bp.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCityValidationErrorsRequired: t(
                  'address.fields.city.validation_errors.required'
                ),
                fieldsCityValidationErrorsMaxLength: t(
                  'address.fields.city.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsProvinceValidationErrorsRequired: t(
                  'address.fields.province.validation_errors.required'
                ),
                fieldsProvinceValidationErrorsMaxLength: t(
                  'address.fields.province.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCountryValidationErrorsRequired: t(
                  'address.fields.country.validation_errors.required'
                ),
                fieldsNameLabel: t(
                  'onboarding.billing.sub_steps.1.bank_address.name.label'
                ),
                fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                fieldsFirstnameLabel: t('address.fields.firstname.label'),
                fieldsFirstnamePlaceholder: t(
                  'address.fields.firstname.placeholder'
                ),
                fieldsLastnameLabel: t('address.fields.lastname.label'),
                fieldsLastnamePlaceholder: t(
                  'address.fields.lastname.placeholder'
                ),
                fieldsPhoneLabel: t('address.fields.phone.label'),
                fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                fieldsStreetLabel: t(
                  'onboarding.billing.sub_steps.1.bank_address.street.label'
                ),
                fieldsStreetPlaceholder: '',
                fieldsStreet2Label: t(
                  'onboarding.billing.sub_steps.1.bank_address.street2.label'
                ),
                fieldsStreet2Placeholder: '',
                fieldsBpLabel: t('address.fields.bp.label'),
                fieldsBpPlaceholder: '',
                fieldsCityLabel: t('address.fields.city.label'),
                fieldsCityPlaceholder: '',
                fieldsProvinceLabel: t('address.fields.province.label'),
                fieldsProvincePlaceholder: '',
                fieldsProvinceEmptyField: t(
                  'address.fields.province.empty_field'
                ),
                fieldsCountryLabel: t('address.fields.country.label'),
                fieldsCountryPlaceholder: t(
                  'address.fields.country.placeholder'
                ),
                fieldsCountryPlaceholderSearch: t(
                  'address.fields.country.placeholder_search'
                ),
                fieldsCountryEmptyField: t(
                  'address.fields.country.empty_field'
                ),
                fieldsCountryNoResults: t('address.fields.country.no_results'),
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
