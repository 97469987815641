import { checkVAT, countries } from 'jsvat';

const VAT_EXAMPLES = [
  {
    code: 'AT',
    example: 'ATU99999999',
  },
  {
    code: 'BE',
    example: 'BE0999999999',
  },
  {
    code: 'BG',
    example: 'BG999999999',
  },
  {
    code: 'CY',
    example: 'CY99999999L',
  },
  {
    code: 'CZ',
    example: 'CZ999999999',
  },
  {
    code: 'DE',
    example: 'DE999999999',
  },
  {
    code: 'DK',
    example: 'DK99999999',
  },
  {
    code: 'ES',
    example: 'ESX9999999X',
  },
  {
    code: 'EE',
    example: 'EE999999999',
  },
  {
    code: 'FI',
    example: 'FI99999999',
  },
  {
    code: 'FR',
    example: 'FR99999999999',
  },
  {
    code: 'GB',
    example: 'GB999999999',
  },
  {
    code: 'GR',
    example: 'EL999999999',
  },
  {
    code: 'HR',
    example: 'HR99999999999',
  },
  {
    code: 'HU',
    example: 'HU99999999',
  },
  {
    code: 'IE',
    example: 'IE9999999XX',
  },
  {
    code: 'IT',
    example: 'IT99999999999',
  },
  {
    code: 'LV',
    example: 'LV99999999999',
  },
  {
    code: 'LT',
    example: 'LT999999999',
  },
  {
    code: 'LU',
    example: 'LU99999999',
  },
  {
    code: 'MT',
    example: 'MT99999999',
  },
  {
    code: 'NL',
    example: 'NL999999999B99',
  },
  {
    code: 'PL',
    example: 'PL9999999999',
  },
  {
    code: 'PT',
    example: 'PT999999999',
  },
  {
    code: 'RO',
    example: 'RO9999999999',
  },
  {
    code: 'SE',
    example: 'SE999999999901',
  },
  {
    code: 'SI',
    example: 'SI99999999',
  },
  {
    code: 'SK',
    example: 'SK9999999999',
  },
];

export const formatVatForHuman = (vat: string) =>
  vat.replace(/^(.{2})(.*)$/, '$1 $2') || '';

export const vatValidation = (vat: string) => {
  return checkVAT(vat, countries).isValid;
};

export const isSupportedCountry = (countryCode: string) => {
  return (
    countries
      .map((c) => c.codes[0])
      .findIndex((country) => country === countryCode) !== -1
  );
};

export const getExampleNumber = (
  countryCode: string,
  examples: { code: string; example: string }[]
) => {
  return examples.find((example) => example.code === countryCode)?.example;
};

export const generateVatPlaceholder = (countryCode: string) => {
  let placeholder = '';
  if (isSupportedCountry(countryCode)) {
    const ibanNumber = getExampleNumber(countryCode, VAT_EXAMPLES);
    if (ibanNumber) placeholder = formatVatForHuman(ibanNumber);
  }
  return placeholder;
};
