import { forwardRef } from 'react';

import cn from '@appchoose/cn';
import Input from 'input-format/react';

import { formatSiretForHuman } from '../../utils/siret';

export type SiretInputProps = {
  value?: string;
  onChange?(value?: string): void;
  onKeyDown?(value?: string): void;
} & React.ComponentPropsWithRef<'input'>;

export const SiretInput = forwardRef<HTMLInputElement, SiretInputProps>(
  ({ className, ...props }: SiretInputProps, ref) => {
    const format = (value?: string) => {
      if (!value) return { text: '', template: '' };

      const formattedSiret = formatSiretForHuman(value);
      return {
        text: formattedSiret,
        template: formattedSiret.replace(/[a-zA-Z0-9]/g, 'x'),
      };
    };

    return (
      <Input
        ref={ref}
        parse={(character: string) => {
          if (/^[a-z0-9]+$/i.exec(character)) return character;
        }}
        format={format}
        className={cn(
          'form-input block w-full rounded border border-gray-500 p-2 text-sm leading-5.5 text-gray-900 placeholder-gray-500 outline-none transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-300 disabled:placeholder-gray-300 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600',
          className
        )}
        {...props}
      />
    );
  }
);

SiretInput.displayName = 'SiretInput';
