type SortTopProps = React.ComponentPropsWithoutRef<'svg'>;

export const SortTop: React.FC<SortTopProps> = ({ ...props }: SortTopProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 9.70711C18.3166 10.0976 17.6834 10.0976 17.2929 9.70711L12 4.41421L6.70711 9.70711C6.31658 10.0976 5.68342 10.0976 5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289L11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711Z"
        fill="#0B1115"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 14.2929C5.68342 13.9024 6.31658 13.9024 6.70711 14.2929L12 19.5858L17.2929 14.2929C17.6834 13.9024 18.3166 13.9024 18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929Z"
        fill="#D8D9DA"
      />
    </svg>
  );
};
