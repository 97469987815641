import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { brandState, serverTimeState } from '../../stores/brand';
import {
  RawSale,
  activeSaleIdState,
  isCurrent,
  isFuture,
} from '../../stores/sales';
import { transformDateSale, transformDateSimple } from '../../utils/date';
import SelectorElement from '../selector-element/selector-element';

type SalesSelectorSaleProps = {
  idx: number;
  sale: RawSale;
  onSelect: (saleId: string) => void;
};

export const SalesSelectorSale: React.FC<SalesSelectorSaleProps> = ({
  idx,
  onSelect,
  sale,
}: SalesSelectorSaleProps) => {
  const brand = useRecoilValue(brandState);
  const { i18n, t } = useTranslation();

  const serverTime = useRecoilValue(serverTimeState);
  const activeSaleId = useRecoilValue(activeSaleIdState);

  return (
    <div className="list-elt" onClick={() => onSelect(sale.id)}>
      <div className="column">
        <p className="date-name">
          <span className="">
            {sale.selection_name ||
              transformDateSale(
                new Date(sale.start_at ?? ''),
                i18n.language === 'fr' ? 'fr' : 'en',
                brand?.timezone ?? undefined
              )}
          </span>
          {isFuture(sale, serverTime) ? (
            <span className="next">{t('sale.next')}</span>
          ) : null}
          {isCurrent(sale, serverTime) ? (
            <span className="next">{t('sale.current')}</span>
          ) : null}
        </p>
        <p className="range">
          {transformDateSimple(
            new Date(sale.start_at ?? ''),
            i18n?.language === 'fr' ? 'fr' : 'en',
            true,
            brand?.timezone ?? undefined
          )}{' '}
          →{' '}
          {sale.end_at
            ? transformDateSimple(
                new Date(sale.end_at),
                i18n?.language === 'fr' ? 'fr' : 'en',
                true,
                brand?.timezone ?? undefined
              )
            : ''}
        </p>
      </div>
      {sale.id === activeSaleId ? (
        <SelectorElement
          selected={sale.id === activeSaleId}
          idx={idx}
          onSelect={onSelect as any}
        />
      ) : null}
    </div>
  );
};
