import React from 'react';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import Logo from '@appchoose/logo';

import { brandState } from '../../stores/brand';

export type XChooseHeaderProps = {
  brandName?: string;
  children?: React.ReactNode;
};

export const XChooseHeader: React.FC<XChooseHeaderProps> = ({
  brandName,
  children,
}: XChooseHeaderProps) => {
  const brand = useRecoilValue(brandState);
  let brandNameToUse = brandName;
  if (!brandNameToUse) {
    brandNameToUse = brand?.name ?? '';
  }
  return (
    <header className="items-center justify-between sm:flex">
      <div className="mb-4 flex items-center sm:mb-0">
        <Logo role="img" aria-label="Logo Choose" />
        <Icon icon="close" size="small" className="ml-2 text-gray-500" />
        <p className="m-0 ml-1 text-base text-gray-900">{brandNameToUse}</p>
      </div>
      {children}
    </header>
  );
};

XChooseHeader.displayName = 'XChooseHeader';
