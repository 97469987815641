import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';

import { BeforeSale } from '../../components/before-sale/before-sale';
import { useWidgetUpdatedSubscription } from '../../hooks/use-widget-updated-subscription';
import { brandState } from '../../stores/brand';
import { isWaitingForSaleState } from '../../stores/sales';
import { isScopeAuthorized } from '../../utils/auth';
import { HomeOrders } from './home-orders';
import { HomeSaleWidgets } from './home-sale-widgets';
import { HomeStats } from './home-stats';
import { WelcomeMessage } from './welcome-message';

export const HomeScreen: React.FC = () => {
  const { user } = useAuth0();
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();

  const isWaitingForSale = useRecoilValue(isWaitingForSaleState);

  useTitle(`${brand?.name ?? ''} ~ ` + t('sidebar.home'));

  useWidgetUpdatedSubscription();

  return (
    <main className="relative flex-1 overflow-y-auto px-4 pb-4 focus:outline-none sm:px-10 sm:pb-22">
      <section className="mb-10 max-w-[582px] space-y-2 pt-2 sm:mb-12 sm:pt-8">
        {isScopeAuthorized(user, 'scope.brand.analytics.view') && <HomeStats />}
      </section>
      <section className="!mb-6">
        <WelcomeMessage />
      </section>
      {isWaitingForSale ? (
        <section>
          <BeforeSale />
        </section>
      ) : (
        <>
          <HomeSaleWidgets />

          <HomeOrders />
        </>
      )}
    </main>
  );
};

HomeScreen.displayName = 'HomeScreen';
