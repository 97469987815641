import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { Brand, brandState } from '../../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../../types/generated';
import { getPhoneCountryCode, parsePhoneNumber } from '../../../utils/phone';
import { removeAllSpaces } from '../../../utils/string';
import {
  BrandContactFormData,
  ContactFormFields,
} from '../../brand-info/contact-form-fields';

export type UpdatingInfoConfirmationContactProps = {
  goBack: () => void;
};

export const UpdatingInfoConfirmationContact: React.FC<
  UpdatingInfoConfirmationContactProps
> = ({ goBack }: UpdatingInfoConfirmationContactProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const parsedDefaultPhoneNumber = parsePhoneNumber(brand?.contact_phone ?? '');

  const form = useForm<BrandContactFormData>({
    mode: 'onTouched',
    defaultValues: {
      email: brand?.contact_email ?? '',
      phone: parsedDefaultPhoneNumber
        ?.formatInternational()
        ?.replace(/\s/g, ''),
      phoneCountryCode: getPhoneCountryCode(
        parsedDefaultPhoneNumber,
        brand?.store ?? StoreRegion.Fr
      ),
    },
  });

  const onSubmit = (data: BrandContactFormData) => {
    onUpdate({
      contact_phone: data.phone,
      contact_email: removeAllSpaces(data.email),
    });
  };

  const onUpdate = (
    data: Pick<NonNullable<Brand>, 'contact_phone' | 'contact_email'>
  ) => {
    if (!brand) return;
    updateSellerMutation({ updateSeller: data });
    setBrand({
      ...brand,
      contact_email: data.contact_email,
      contact_phone: data.contact_phone,
    });
    goBack();
  };

  return (
    <>
      <h2 className="mb-6 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
        {t('updating_info.confirmation_step.contact.title')}
      </h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-6">
            <ContactFormFields autoFocus />
          </div>
          <div className="mt-8 flex justify-end space-x-6 sm:mt-10">
            <button
              type="button"
              className="cursor-pointer text-sm font-bold text-green-900"
              onClick={goBack}
            >
              {t('updating_info.confirmation_step.return')}
            </button>{' '}
            <Button type="submit" size="large">
              {t('updating_info.confirmation_step.confirm')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
