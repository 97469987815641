type GoogleProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Google: React.FC<GoogleProps> = ({ ...props }: GoogleProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.4999 12.253C23.4999 11.5131 23.4344 10.8106 23.322 10.127H12.7388V14.3509H18.7984C18.5268 15.737 17.7307 16.9077 16.5506 17.7038V20.5135H20.1658C22.2824 18.5561 23.4999 15.6714 23.4999 12.253Z"
        fill="#4285F4"
      />
      <path
        d="M12.7387 23.2389C15.7731 23.2389 18.3112 22.2274 20.1656 20.5134L16.5505 17.7037C15.539 18.3781 14.2559 18.7902 12.7387 18.7902C9.8072 18.7902 7.32529 16.814 6.43555 14.1448H2.70801V17.0388C4.55305 20.7101 8.34615 23.2389 12.7387 23.2389Z"
        fill="#34A853"
      />
      <path
        d="M6.43572 14.1447C6.20157 13.4703 6.07982 12.7492 6.07982 11.9999C6.07982 11.2507 6.21094 10.5295 6.43572 9.85518V6.96118H2.70817C1.94019 8.47842 1.5 10.183 1.5 11.9999C1.5 13.8169 1.94019 15.5214 2.70817 17.0387L6.43572 14.1447Z"
        fill="#FBBC05"
      />
      <path
        d="M12.7387 5.20993C14.3964 5.20993 15.8762 5.78124 17.0469 6.89576L20.2499 3.69269C18.3112 1.87575 15.7731 0.76123 12.7387 0.76123C8.34615 0.76123 4.55305 3.28997 2.70801 6.96132L6.43555 9.85531C7.32529 7.18609 9.8072 5.20993 12.7387 5.20993Z"
        fill="#EA4335"
      />
    </svg>
  );
};
