import React from 'react';
import { useTranslation } from 'react-i18next';

import { differenceInDays } from 'date-fns';

import './countdown.scss';

const useClockDigit = ({ end }: { end: string }) => {
  const [duration, setDuration] = React.useState('days');
  const [currentSeconds, setCurrentSeconds] = React.useState(
    undefined as number | undefined
  );
  const [day2TranslateY, setDay2TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [day3TranslateY, setDay3TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [hour1TranslateY, setHour1TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [hour2TranslateY, setHour2TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [minute1TranslateY, setMinute1TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [minute2TranslateY, setMinute2TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [second1TranslateY, setSecond1TranslateY] = React.useState(
    undefined as number | undefined
  );
  const [second2TranslateY, setSecond2TranslateY] = React.useState(
    undefined as number | undefined
  );

  React.useEffect(() => {
    setCurrentSeconds(0);
  }, [0]);

  function getTime(flipClockInterval?: number) {
    if (
      typeof currentSeconds === 'number' &&
      currentSeconds < 0 &&
      flipClockInterval
    ) {
      clearInterval(flipClockInterval);
      return;
    }
    const days = differenceInDays(new Date(end), new Date());
    const secondToPast = new Date(end).getTime() - Date.now();

    const date = new Date(secondToPast).toISOString().substr(11, 8);

    // const formatted = format(secondToPast, "DDDD:HH:mm:ss");
    // // console.log(formatted,date,new Date(secondToPast).toISOString())
    // // // console.log(formatted, formatted.slice(0, 3),secondToPast, secondToPast/3600000, new Date(end))
    // const day2 = Number(formatted[1]);
    // const day3 = Number(formatted[2]);
    if (days === 365 || days === 0) {
      if (duration !== 'hours') {
        setDuration('hours');
      }
    } else {
      if (duration !== 'days') {
        setDuration('days');
      }
    }
    const hour1 = Number(date[0]);
    const hour2 = Number(date[1]);

    const minute1 = Number(date[3]);
    const minute2 = Number(date[4]);

    const second1 = Number(date[6]);
    const second2 = Number(date[7]);
    if (duration === 'days') {
      if (days > 10) {
        setDay2TranslateY(9 - +('' + days)[0]);
        setDay3TranslateY(9 - +('' + days)[1]);
      } else {
        setDay2TranslateY(9);
        setDay3TranslateY(9 - days);
      }
    }
    if (duration === 'days' || duration === 'hours') {
      setHour1TranslateY(9 - hour1);
      setHour2TranslateY(9 - hour2);
    }
    setMinute1TranslateY(9 - minute1);
    setMinute2TranslateY(9 - minute2);

    setSecond1TranslateY(9 - second1);
    setSecond2TranslateY(9 - second2);

    setCurrentSeconds(secondToPast);
  }
  // getTime()
  React.useEffect(() => {
    getTime();
    const flipClockInterval = window.setInterval(() => {
      getTime(flipClockInterval);
    }, 1000);

    return () => {
      clearInterval(flipClockInterval);
    };
  }, []);

  return {
    day2TranslateY,
    day3TranslateY,
    hour1TranslateY,
    hour2TranslateY,
    minute1TranslateY,
    minute2TranslateY,
    second1TranslateY,
    second2TranslateY,
    duration,
  };
};
interface CountdownProps {
  // start: string;
  end: string;
}

interface ICountdownTimerItem {
  translateY: number;
}
const CountdownTimerItem: React.FC<ICountdownTimerItem> = ({
  translateY,
}: ICountdownTimerItem) => {
  const [isMount, setMount] = React.useState(false);

  React.useEffect(() => {
    setMount(true);
  }, []);

  return (
    <div className="countdown-timer-item">
      <div
        className="digit-wrapper"
        style={{
          transform: `translateY(-${translateY}0%`,
          transitionDuration: isMount
            ? `${translateY === 0 ? 200 : 500}ms`
            : '0',
        }}
      >
        <span>{isMount ? 9 : '-'}</span>
        <span>8</span>
        <span>7</span>
        <span>6</span>
        <span>5</span>
        <span>4</span>
        <span>3</span>
        <span>2</span>
        <span>1</span>
        <span>0</span>
      </div>
    </div>
  );
};

export const Countdown: React.FC<CountdownProps> = (props: CountdownProps) => {
  const { t } = useTranslation();
  const {
    day2TranslateY,
    day3TranslateY,
    hour1TranslateY,
    hour2TranslateY,
    minute1TranslateY,
    minute2TranslateY,
    second1TranslateY,
    second2TranslateY,
    duration,
  } = useClockDigit({ end: props.end });

  return (
    <div className="countdown">
      {duration === 'days' && (
        <>
          <div className="data">
            <div className="column">
              {typeof day2TranslateY === 'number' ? (
                <CountdownTimerItem translateY={day2TranslateY} />
              ) : null}
              {typeof day3TranslateY === 'number' ? (
                <CountdownTimerItem translateY={day3TranslateY} />
              ) : null}
            </div>
            <p>{t('days')}</p>
          </div>
          <span className="countdown-separator">:</span>
        </>
      )}
      <div className="data">
        <div className="column">
          {typeof hour1TranslateY === 'number' ? (
            <CountdownTimerItem translateY={hour1TranslateY} />
          ) : null}
          {typeof hour2TranslateY === 'number' ? (
            <CountdownTimerItem translateY={hour2TranslateY} />
          ) : null}
        </div>
        <p>{t('hours')}</p>
      </div>
      <span className="countdown-separator">:</span>

      <div className="data">
        <div className="column">
          {typeof minute1TranslateY === 'number' ? (
            <CountdownTimerItem translateY={minute1TranslateY} />
          ) : null}
          {typeof minute2TranslateY === 'number' ? (
            <CountdownTimerItem translateY={minute2TranslateY} />
          ) : null}
        </div>
        <p>{t('minutes')}</p>
      </div>
      <span className="countdown-separator">:</span>
      <div className="data">
        <div className="column">
          {typeof second1TranslateY === 'number' ? (
            <CountdownTimerItem translateY={second1TranslateY} />
          ) : null}
          {typeof second2TranslateY === 'number' ? (
            <CountdownTimerItem translateY={second2TranslateY} />
          ) : null}
        </div>
        <p>{t('seconds')}</p>
      </div>
    </div>
  );
};

export default Countdown;
