import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import i18n from 'i18next';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { transformPrice } from '../../utils/currency';

type Property = {
  id: number;
  label: string;
  value: string;
};

export const ShopifyDetails: React.FC = () => {
  const { t } = useTranslation();

  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const properties: Property[] = [
    {
      id: 1,
      label: t('settings.integration_tabs.shopify.fields.shopify_url.label'),
      value: brand?.shopify?.shop ?? '',
    },
    {
      id: 2,
      label: t('settings.integration_tabs.shopify.fields.token.label'),
      value: (brand?.shopify?.token ?? '').concat('••••••••••••••••••••••••••'),
    },
    {
      id: 3,
      label: t('settings.integration_tabs.shopify.fields.shared_secret.label'),
      value: (brand?.shopify?.sharedSecret ?? '').concat(
        '••••••••••••••••••••••••••'
      ),
    },
    brand?.shopify?.carrierCode
      ? {
          id: 4,
          label: t(
            'settings.integration_tabs.shopify.fields.carrier_code.label'
          ),
          value: brand.shopify.carrierCode,
        }
      : null,
    brand?.shopify?.internationalCarrierCode
      ? {
          id: 5,
          label: t(
            'settings.integration_tabs.shopify.fields.international_carrier_code.label'
          ),
          value: brand.shopify.internationalCarrierCode,
        }
      : null,
    brand?.shopify?.mondialRelayCarrierCode
      ? {
          id: 8,
          label: t(
            'settings.integration_tabs.shopify.fields.mondial_relay_carrier_code.label'
          ),
          value: brand.shopify.mondialRelayCarrierCode,
        }
      : null,
    {
      id: 6,
      label: t('settings.integration_tabs.shopify.fields.stock_live.label'),
      value:
        brand?.shopify?.isStockLive === true
          ? t('settings.integration_tabs.shopify.fields.stock_live.options.yes')
          : t('settings.integration_tabs.shopify.fields.stock_live.options.no'),
    },
    brand?.shopify?.isProductPriceKept !== null &&
    brand?.shopify?.isProductPriceKept !== undefined
      ? {
          id: 7,
          label: t(
            'settings.integration_tabs.shopify.fields.is_product_price_kept.title'
          ),
          value: brand?.shopify?.isProductPriceKept
            ? t(
                'settings.integration_tabs.shopify.fields.is_product_price_kept.options.yes'
              )
            : t(
                'settings.integration_tabs.shopify.fields.is_product_price_kept.options.no',
                {
                  amount: transformPrice(
                    0,
                    i18n.language === 'fr' ? 'fr' : 'en',
                    activeSale?.currency?.toString()?.toUpperCase() ?? 'EUR'
                  ),
                }
              ),
        }
      : null,
  ].filter((property): property is Property => !!property);

  return (
    <div>
      <dl className="space-y-4">
        {properties.map((property) => (
          <div key={property.id} className="space-y-1">
            <dt className="text-xs font-semibold uppercase tracking-wider text-gray-500">
              {property.label}
            </dt>
            <dd className="text-sm font-semibold text-gray-900">
              {property.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

ShopifyDetails.displayName = 'ShopifyDetails';
