export const formatNumber = (
  number: number,
  locale: string,
  { maximumFractionDigits } = {} as { maximumFractionDigits?: number }
) => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
  }).format(number);
};

export const formatPercentage = (
  value: number,
  locale: string,
  options: Omit<Intl.NumberFormatOptions, 'style'> = {
    maximumFractionDigits: 2,
  }
) => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    ...options,
  }).format(value);
};
