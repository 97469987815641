import { atom, useRecoilState } from 'recoil';

import { getSearchParam } from '../../utils/url';

type PaginationState = {
  beforeCursor: string | undefined;
  afterCursor: string | undefined;
};

export const paginationState = atom<PaginationState | undefined>({
  key: 'paginationState',
  default: new Promise((resolve) => {
    return resolve({
      beforeCursor: getSearchParam('before'),
      afterCursor: getSearchParam('after'),
    });
  }),
});

export const usePagination = () => {
  const [pagination, setPagination] = useRecoilState(paginationState);

  const resetPagination = () => {
    setPagination({
      afterCursor: undefined,
      beforeCursor: undefined,
    });
  };

  return { pagination, resetPagination, setPagination } as const;
};
