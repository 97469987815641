type CircleStrokeProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const CircleStroke: React.FC<CircleStrokeProps> = ({
  ...props
}: CircleStrokeProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.94921 3.55899C5.82007 4.86014 3.45329 8.78759 2.8231 13.7568C2.19542 18.7062 3.46645 23.4202 6.08468 25.7817C13.7152 32.664 27.0987 29.4696 29.1418 17.1124C30.1231 11.1773 27.195 7.15483 22.8022 4.823C18.221 2.39116 12.4921 2.08579 8.94921 3.55899ZM7.92535 1.09671C16.7169 -2.559 34.1904 2.92459 31.7727 17.5474C29.3551 32.1702 13.353 35.9284 4.29865 27.7619C-2.73477 21.4181 -0.866244 4.75241 7.92535 1.09671Z"
          fill="#13482B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
