type CourierProps = React.ComponentPropsWithoutRef<'svg'>;

export const Courier: React.FC<CourierProps> = ({ ...props }: CourierProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16.9928V9.79099C3.00099 8.82562 3.51799 7.93582 4.35347 7.45349L7.28288 5.88417C7.68961 5.66628 8.19532 5.79298 8.45127 6.17691C8.74513 6.61769 8.59437 7.21582 8.12669 7.46466L5.73487 8.73727L11.9997 12.3613L18.2649 8.73711L16.6745 7.51732C16.295 7.22623 16.2062 6.6907 16.4715 6.29273C16.7592 5.86117 17.3493 5.75774 17.7666 6.0657L19.65 7.45548C20.4938 7.94266 20.999 8.81387 21 9.79099V16.9928C20.999 17.9386 20.4671 18.8592 19.6465 19.3303L13.3484 22.9293C12.4946 23.4214 11.5036 23.4209 10.65 22.9283L4.35 19.3283C3.52991 18.8549 3.00097 17.9398 3 16.9928ZM18.75 17.7695L12.9 21.1123V13.92L19.2 10.2756V16.991C19.1997 17.3062 19.0228 17.6118 18.75 17.7695ZM4.8 10.2759L11.1 13.9203V21.1123L5.24858 17.7687C4.97612 17.6107 4.80046 17.3063 4.8 16.9914V10.2759Z"
        fill="currentColor"
      />
      <path
        d="M10.1499 4.88129L12.2686 7.00003L14.3874 4.88129"
        stroke="currentColor"
        strokeWidth="1.24999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2688 2V6.99998"
        stroke="currentColor"
        strokeWidth="1.24999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
