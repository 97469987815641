import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormLabelCheckbox,
  FormMessage,
} from '@appchoose/form';

import {
  generateVatPlaceholder,
  vatValidation,
} from '../../utils/intracommunity-vat';
import { VatInput } from '../vat-input/vat-input';

export type TaxFormData = {
  intracommunityVat: string;
  isNotSubjectVat: boolean;
};

export type TaxFormFieldsProps = {
  countryCode: string;
};

export const TaxFormFields: React.FC<TaxFormFieldsProps> = ({
  countryCode,
}: TaxFormFieldsProps) => {
  const { t } = useTranslation();

  const form = useFormContext<TaxFormData>();

  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="intracommunityVat"
        rules={{
          maxLength: 20,
          validate: {
            required: (value: string | null) =>
              !form.getValues('isNotSubjectVat') && !value?.trim()
                ? false
                : true,
            validVat: (value: string) =>
              !form.getValues('isNotSubjectVat') ? vatValidation(value) : true,
          },
        }}
        disabled={form.getValues('isNotSubjectVat')}
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('brand_info.tax_form_fields.intracommunity_vat.label')}
            </FormLabel>
            <FormControl>
              <VatInput
                placeholder={generateVatPlaceholder(countryCode)}
                disabled={form.getValues('isNotSubjectVat')}
                {...field}
              />
            </FormControl>
            <FormMessage match="required">
              {t(
                'brand_info.tax_form_fields.intracommunity_vat.validation_errors.required'
              )}
            </FormMessage>
            <FormMessage match="maxLength">
              {t(
                'brand_info.tax_form_fields.intracommunity_vat.validation_errors.maxLength',
                {
                  maxLength: '20',
                }
              )}
            </FormMessage>
            <FormMessage match="validVat">
              {t(
                'brand_info.tax_form_fields.intracommunity_vat.validation_errors.validVat'
              )}
            </FormMessage>
          </FormItem>
        )}
      />

      {countryCode === 'FR' ? (
        <FormField
          control={form.control}
          name="isNotSubjectVat"
          render={({ field }) => (
            <FormItem className="flex space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value=""
                  checked={field.value}
                  onCheckedChange={(e) => {
                    field.onChange(e);
                    form.trigger('intracommunityVat');
                  }}
                />
              </FormControl>
              <FormLabelCheckbox>
                {t('brand_info.tax_form_fields.is_not_subject_vat.label')}
              </FormLabelCheckbox>
            </FormItem>
          )}
        />
      ) : null}
    </div>
  );
};

TaxFormFields.displayName = 'TaxFormFields';
