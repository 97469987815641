import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';

import { siretValidation } from '../../utils/siret';
import { SiretInput } from '../siret-input/siret-input';

export type LegalInfoFormFieldsProps = {
  hasSiret: boolean;
  hasTaxId: boolean;
  autoFocus?: boolean;
};

export type LegalInfoFormData = {
  legalEntityName: string;
  siret?: string;
  taxId?: string;
};

export const LegalInfoFormFields: React.FC<LegalInfoFormFieldsProps> = ({
  hasSiret,
  hasTaxId,
  autoFocus,
}: LegalInfoFormFieldsProps) => {
  const { t } = useTranslation();

  const form = useFormContext<LegalInfoFormData>();

  return (
    <>
      <FormField
        control={form.control}
        name="legalEntityName"
        rules={{
          required: true,
          maxLength: 50,
        }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('brand_info.legal_info_form_fields.legal_entity_name.label')}
            </FormLabel>
            <FormControl>
              <Input
                placeholder={t(
                  'brand_info.legal_info_form_fields.legal_entity_name.placeholder'
                )}
                autoFocus={autoFocus}
                {...field}
              />
            </FormControl>
            <FormMessage match="required">
              {t(
                'brand_info.legal_info_form_fields.legal_entity_name.validation_errors.required'
              )}
            </FormMessage>
            <FormMessage match="maxLength">
              {t(
                'brand_info.legal_info_form_fields.legal_entity_name.validation_errors.maxLength',
                {
                  maxLength: '50',
                }
              )}
            </FormMessage>
          </FormItem>
        )}
      />

      {hasSiret ? (
        <FormField
          control={form.control}
          name="siret"
          rules={{
            required: true,
            maxLength: 50,
            validate: {
              validSiret: (value?: string) =>
                value ? siretValidation(value) : true,
            },
          }}
          render={({ field }) => (
            <FormItem className="mt-6">
              <FormLabel>
                {t('brand_info.legal_info_form_fields.siret.label')}
              </FormLabel>
              <FormControl>
                <SiretInput placeholder="999 999 999 99999" {...field} />
              </FormControl>
              <FormMessage match="required">
                {t(
                  'brand_info.legal_info_form_fields.siret.validation_errors.required'
                )}
              </FormMessage>
              <FormMessage match="maxLength">
                {t(
                  'brand_info.legal_info_form_fields.siret.validation_errors.maxLength',
                  {
                    maxLength: '50',
                  }
                )}
              </FormMessage>
              <FormMessage match="validSiret">
                {t(
                  'brand_info.legal_info_form_fields.siret.validation_errors.validSiret'
                )}
              </FormMessage>
            </FormItem>
          )}
        />
      ) : null}
      {hasTaxId ? (
        <FormField
          control={form.control}
          name="taxId"
          rules={{
            maxLength: 50,
          }}
          render={({ field }) => (
            <FormItem className="mt-6">
              <FormLabel>
                {t('brand_info.legal_info_form_fields.tax_id.label')}
              </FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage match="maxLength">
                {t(
                  'brand_info.legal_info_form_fields.tax_id.validation_errors.maxLength',
                  {
                    maxLength: '50',
                  }
                )}
              </FormMessage>
            </FormItem>
          )}
        />
      ) : null}
    </>
  );
};

LegalInfoFormFields.displayName = 'LegalInfoFormFields';
