import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Icon from '@appchoose/icon';
import { TFunction } from 'i18next';

import { getOrderClaimLabelFromTag } from '../../components/order-status-badge/order-claim-badge';
import { getOrderClaimReasonLabelFromTag } from '../../components/order-status-badge/order-claim-reason-badge';
import { getOrderFulfillmentLabelFromTag } from '../../components/order-status-badge/order-fulfillment-badge';
import { getOrderRefundLabelFromTag } from '../../components/order-status-badge/order-refund-badge';
import { getOrderReshipLabelFromTag } from '../../components/order-status-badge/order-reship-badge';
import { getOrderReturnLabelFromTag } from '../../components/order-status-badge/order-return-badge';
import { getOrderShipmentLabelFromTag } from '../../components/order-status-badge/order-shipment-badge';
import {
  OrderFiltering,
  useFilters,
} from '../../components/order-table/use-filters';
import { useResetList } from '../../components/order-table/use-reset-list';
import { useSorting } from '../../components/order-table/use-sorting';
import { OrderTagShopify } from '../../types/generated';
import { formatDateFilter } from '../../utils/date';
import { formatList } from '../../utils/string';
import { parseJSON } from '../../utils/url';
import { usePagination } from './use-pagination';

export const getShopifyLabelFromTag = (tag: OrderTagShopify, t: TFunction) => {
  switch (tag) {
    case OrderTagShopify.Unsynchronized:
      return t('order.status.shopify.unsynchronized');
  }
};

type OrderFilterDateProps = {
  dateRange: {
    from?: string;
    to?: string;
  };
  onClick: () => void;
};

export const OrderFilterDate: React.FC<OrderFilterDateProps> = ({
  dateRange,
  onClick,
}: OrderFilterDateProps) => {
  const { i18n, t } = useTranslation();

  let label = t('orders.filters.date');
  if (dateRange.from && !dateRange.to) label = t('orders.filters.start_date');
  if (!dateRange.from && dateRange.to) label = t('orders.filters.end_date');

  return (
    <button
      type="button"
      className="flex min-w-0 items-center gap-2 truncate rounded border border-gray-300 bg-[#FBFBFB] p-2 text-sm text-gray-900 focus-visible:ring-2 focus-visible:ring-[#ECECEC]"
      onClick={onClick}
    >
      <div className="flex gap-2 truncate">
        <span>{label}</span>{' '}
        <span className="flex items-center gap-1 truncate font-semibold">
          {dateRange.from ? (
            <span className="text-sm font-semibold">
              {formatDateFilter(new Date(dateRange.from), i18n.language)}
            </span>
          ) : null}
          {dateRange.from && dateRange.to ? (
            <Icon icon="back" className="size-3.5 shrink-0 rotate-180" />
          ) : null}
          {dateRange.to ? (
            <span className="text-sm font-semibold">
              {formatDateFilter(new Date(dateRange.to), i18n.language)}
            </span>
          ) : null}
        </span>
      </div>

      <Icon icon="close" className="shrink-0" />
    </button>
  );
};

type OrderFilterProps = {
  filter: string;
  tags: string[];
  onClick: () => void;
};

export const OrderFilter: React.FC<OrderFilterProps> = ({
  filter,
  tags,
  onClick,
}: OrderFilterProps) => {
  const { i18n } = useTranslation();

  return (
    <button
      type="button"
      className="flex min-w-0 items-center gap-2 truncate rounded border border-gray-300 bg-[#FBFBFB] p-2 text-sm text-gray-900 focus-visible:ring-2 focus-visible:ring-[#ECECEC]"
      onClick={onClick}
    >
      <div className="truncate">
        <span>{filter}</span>{' '}
        <span className="truncate font-semibold">
          {formatList(tags, i18n.language, {
            style: 'narrow',
          })}
        </span>
      </div>

      <Icon icon="close" className="shrink-0" />
    </button>
  );
};

type OrderFiltersProps = {
  totalCount?: number;
};

export const OrderFilters: React.FC<OrderFiltersProps> = ({
  totalCount,
}: OrderFiltersProps) => {
  const { filters, hasActiveFilters, setFilters } = useFilters();
  const { resetPagination } = usePagination();
  const { resetList } = useResetList();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { resetSorting } = useSorting();
  const { t } = useTranslation();

  if (!hasActiveFilters) return null;

  return (
    <div className="mt-4 flex flex-wrap items-center gap-3">
      {totalCount !== undefined ? (
        <span className="text-sm text-gray-700">
          {t('orders.search.x_results', { count: totalCount })}
        </span>
      ) : null}
      {filters.creationDate?.from || filters.creationDate?.to ? (
        <OrderFilterDate
          dateRange={filters.creationDate}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, ...filteredFilters } = filters;
            if (
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => {
                return {
                  ...prev,
                  creationDate: {
                    from: undefined,
                    to: undefined,
                  },
                };
              });
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).creationDate = {
                    from: undefined,
                    to: undefined,
                  };
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.fulfillment.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.fulfillment')}
          tags={filters.fulfillment.map(
            (tag) => getOrderFulfillmentLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, fulfillment, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                fulfillment: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).fulfillment = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.shipment.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.shipment')}
          tags={filters.shipment.map(
            (tag) => getOrderShipmentLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, shipment, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                shipment: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).shipment = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.claim.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.claim')}
          tags={filters.claim.map(
            (tag) => getOrderClaimLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, claim, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                claim: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).claim = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.claimReason.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.claim_reason')}
          tags={filters.claimReason.map(
            (tag) => getOrderClaimReasonLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, claimReason, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                claimReason: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).claimReason = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.refund.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.refund')}
          tags={filters.refund.map(
            (tag) => getOrderRefundLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, refund, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                refund: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).refund = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.reship.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.reship')}
          tags={filters.reship.map(
            (tag) => getOrderReshipLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, reship, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                reship: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).reship = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.return.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.return')}
          tags={filters.return.map(
            (tag) => getOrderReturnLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, return: _, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                return: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).return = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}
      {filters.shopify.length > 0 ? (
        <OrderFilter
          filter={t('orders.filters.shopify')}
          tags={filters.shopify.map(
            (tag) => getShopifyLabelFromTag(tag, t) ?? ''
          )}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { creationDate, shopify, ...filteredFilters } = filters;
            if (
              !creationDate?.from &&
              !creationDate?.to &&
              Object.values(filteredFilters).every(
                (filter) => filter.length === 0
              )
            ) {
              resetList();
            } else {
              resetPagination();
              resetSorting();
              setFilters((prev) => ({
                ...prev,
                shopify: [],
              }));
              setSearchParams((prev) => {
                prev.delete('before');
                prev.delete('after');
                prev.delete('sort');
                const filters = parseJSON(prev.get('filters') ?? '');
                if (filters) {
                  (filters as OrderFiltering).shopify = [];
                  prev.set('filters', JSON.stringify(filters));
                }

                return prev;
              });
            }
          }}
        />
      ) : null}

      <button
        type="button"
        onClick={() => resetList()}
        className="rounded text-sm font-semibold text-green-900 focus-visible:ring-2 focus-visible:ring-[#ECECEC]"
      >
        {t('orders.filters.actions.clear_all')}
      </button>
    </div>
  );
};
