import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../../stores/brand';
import { useUpdateSellerMutation } from '../../../types/generated';
import {
  Service,
  ServiceOption,
  getActionableServices,
  mapServiceOptionsToServices,
  otherShippingImage,
  shippingServices,
} from '../../../types/services';
import { EditServices } from '../../edit-services/edit-services';

export type OnboardingStepShippingShippingServiceProps = {
  goToNextSubStepOrStep: () => void;
};

export type OnboardingStepShippingShippingServiceForm = {
  services: ServiceOption[];
};

export const OnboardingStepShippingShippingService: React.FC<
  OnboardingStepShippingShippingServiceProps
> = ({ goToNextSubStepOrStep }: OnboardingStepShippingShippingServiceProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const defaultShippingServices = getActionableServices(
    shippingServices,
    brand?.shipping_services as Service[] | undefined,
    otherShippingImage
  );

  const form = useForm<OnboardingStepShippingShippingServiceForm>({
    defaultValues: {
      services: defaultShippingServices,
    },
  });

  const onSubmit = (data: OnboardingStepShippingShippingServiceForm) => {
    if (!brand) return;
    const selectedServices = mapServiceOptionsToServices(
      data.services.filter((s) => s.selected)
    );
    setBrand({
      ...brand,
      delivery_services: selectedServices,
    });
    updateSellerMutation({
      updateSeller: { shipping_services: selectedServices },
    });
    goToNextSubStepOrStep();
  };

  return (
    <>
      <div className="mb-6 sm:mb-10">
        <h2 className="mt-8 text-2xl font-bold sm:mt-20 sm:text-3.5xl">
          {t('onboarding.shipping.sub_steps.4.title')}
        </h2>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-7">
          <div>
            <EditServices type="shipping" />
          </div>
          <div className="mt-8 flex sm:mt-10">
            <Button type="submit" size="large">
              {t('continue')}
            </Button>
          </div>
          <button
            type="button"
            className="mt-4 text-sm font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
            onClick={goToNextSubStepOrStep}
          >
            {t('onboarding.shipping.sub_steps.4.no_external_service')}
          </button>
        </form>
      </Form>
    </>
  );
};

OnboardingStepShippingShippingService.displayName =
  'OnboardingStepShippingShippingService';
