import * as React from 'react';

import cn from '@appchoose/cn';

import './selector-element.scss';

interface ISelectorElementProps {
  selected: boolean;
  idx?: number | string;
  disabled?: boolean;
  children?: React.ReactNode;
  onSelect?: (idx?: number | string) => void;
}

export default class SelectorElement extends React.PureComponent<ISelectorElementProps> {
  onClick = () => {
    if (!this.props.disabled && typeof this.props.onSelect === 'function') {
      this.props.onSelect(this.props.idx);
    }
  };

  get getSelected() {
    if (this.props.selected) {
      return <div className="circle active" />;
    }
    return <div className="circle inactive" />;
  }

  render() {
    return (
      <div
        className={cn('selector-element', {
          disabled: this.props.disabled,
        })}
        onClick={this.onClick}
      >
        {this.getSelected}
        {this.props.children}
      </div>
    );
  }
}
