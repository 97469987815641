import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { brandState } from '../../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../../types/generated';
import { track } from '../../../utils/analytics';
import {
  ShippingDelayFormData,
  ShippingDelayFormFields,
} from '../../shipping-delay-form-fields/shipping-delay-form-fields';

export type UpdatingInfoConfirmationShippingDelaysProps = {
  goBack: () => void;
};

export const UpdatingInfoConfirmationShippingDelays: React.FC<
  UpdatingInfoConfirmationShippingDelaysProps
> = ({ goBack }: UpdatingInfoConfirmationShippingDelaysProps) => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  useEffect(() => {
    track('EditShippingDelays', {});
  }, []);

  const form = useForm<ShippingDelayFormData>({
    mode: 'onTouched',
    defaultValues: {
      deliveryDelaysMin: brand?.min_delivery_delay ?? undefined,
      deliveryDelaysMax: brand?.max_delivery_delay ?? undefined,
    },
  });

  const onSubmit = (data: ShippingDelayFormData) => {
    if (!brand) return;
    setBrand({
      ...brand,
      min_delivery_delay: data.deliveryDelaysMin,
      max_delivery_delay: data.deliveryDelaysMax,
    });
    updateSellerMutation({
      updateSeller: {
        min_delivery_delay: data.deliveryDelaysMin,
        max_delivery_delay: data.deliveryDelaysMax,
      },
    });
    goBack();
  };

  return (
    <>
      <div className="mb-6 sm:mb-10">
        <h2 className="text-2xl font-bold sm:text-3.5xl">
          {t('updating_info.confirmation_step.shipping_delays.title')}
        </h2>
        <p className="text-sm text-gray-700 sm:text-base">
          {brand?.store === StoreRegion.Fr
            ? t('onboarding.shipping.sub_steps.5.subtitle_fr')
            : t('onboarding.shipping.sub_steps.5.subtitle_us')}
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ShippingDelayFormFields autoFocus />
          <div className="mt-8 flex justify-end space-x-6 sm:mt-10">
            <button
              type="button"
              className="cursor-pointer text-sm font-bold text-green-900"
              onClick={goBack}
            >
              {t('updating_info.confirmation_step.return')}
            </button>
            <Button type="submit" size="large">
              {t('updating_info.confirmation_step.confirm')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

UpdatingInfoConfirmationShippingDelays.displayName =
  'UpdatingInfoConfirmationShippingDelays';
