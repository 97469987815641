import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Illustration from '@appchoose/illustration';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreRegion } from '../../types/generated';
import { BrandMatch } from '../../types/navigation';
import { track } from '../../utils/analytics';
import Countdown from '../countdown/countdown';
import {
  FaqAccordion,
  FaqAccordionContent,
  FaqAccordionItem,
  FaqAccordionTrigger,
} from '../faq-accordion/faq-accordion';

export const BeforeSale: React.FC = () => {
  const { t } = useTranslation();
  const activeSale = useRecoilValue(activeSaleState);
  const brand = useRecoilValue(brandState);

  const { brandId = '' } = useParams<BrandMatch>();
  const { search } = useLocation();

  const [beforeSaleAccordion, setBeforeSaleAccordion] = useState<string[]>([]);

  return (
    <div className="flex flex-col items-center rounded-lg border border-gray-300 bg-white pt-20">
      {activeSale?.start_at ? <Countdown end={activeSale?.start_at} /> : null}
      <div className="mt-2 text-xs text-gray-700">
        {brand?.store === StoreRegion.Fr
          ? t('before_sale.hour_opening_fr')
          : t('before_sale.hour_opening_us')}
      </div>

      <h4 className="mb-4 mt-8 text-2xl font-bold text-gray-900">
        {t('orders.before_sale.sale_not_yet_active')}
      </h4>
      <h5 className="max-w-[572px] text-balance text-center text-sm text-gray-700">
        {t('orders.before_sale.read_our_advices')}
      </h5>
      <div className="my-14 w-full rounded bg-gray-50 p-6 lg:w-[600px]">
        <div className="flex justify-center">
          <Illustration illustration="help" />
        </div>

        <h4 className="mb-6 mt-4 text-center text-2xl font-bold text-gray-900">
          {t('faq.orders.title')}
        </h4>

        <FaqAccordion
          type="multiple"
          value={beforeSaleAccordion}
          onValueChange={(value) => {
            const openedValues = value.filter(
              (x) => !beforeSaleAccordion.includes(x)
            );
            openedValues.forEach((openedValue) => {
              track('OpenFAQ', {
                questionTitle: openedValue,
              });
            });
            setBeforeSaleAccordion(value);
          }}
        >
          <FaqAccordionItem value="when_ship">
            <FaqAccordionTrigger>
              {t('faq.orders.when_ship_orders_question')}
            </FaqAccordionTrigger>
            <FaqAccordionContent>
              <p className="whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                {t('faq.orders.when_ship_orders_answer')}
              </p>
            </FaqAccordionContent>
          </FaqAccordionItem>
          <FaqAccordionItem value="article_out_of_stock">
            <FaqAccordionTrigger>
              {t('faq.orders.article_out_of_stock_question')}
            </FaqAccordionTrigger>

            <FaqAccordionContent>
              <p className="whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                {t('faq.orders.article_out_of_stock_answer')}
              </p>
            </FaqAccordionContent>
          </FaqAccordionItem>
          <FaqAccordionItem value="customer_disputes">
            <FaqAccordionTrigger>
              {t('faq.sav.handling_customer_disputes_question')}
            </FaqAccordionTrigger>
            <FaqAccordionContent>
              <p className="whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                {t('faq.sav.handling_customer_disputes_answer')}
              </p>
            </FaqAccordionContent>
          </FaqAccordionItem>
          <FaqAccordionItem value="different_claims">
            <FaqAccordionTrigger>
              {t('faq.sav.kinds_of_claim_encountered_question')}
            </FaqAccordionTrigger>
            <FaqAccordionContent>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('faq.sav.kinds_of_claim_encountered_answer'),
                }}
                className="whitespace-pre-wrap py-3.5 text-sm text-gray-700"
              />
            </FaqAccordionContent>
          </FaqAccordionItem>
          <FaqAccordionItem value="returns_work">
            <FaqAccordionTrigger>
              {t('faq.sav.how_returns_work_question')}
            </FaqAccordionTrigger>
            <FaqAccordionContent>
              <p className="whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                {brand?.store === StoreRegion.Fr
                  ? t('faq.sav.how_returns_work_answer_fr')
                  : t('faq.sav.how_returns_work_answer_us')}
              </p>
            </FaqAccordionContent>
          </FaqAccordionItem>
        </FaqAccordion>
        <p className="mt-10 text-sm text-gray-700">
          {t('faq.others')}{' '}
          <NavLink
            to={`/${brandId}/faq${search}`}
            className="font-bold text-green-900"
          >
            {t('faq.visit')}
          </NavLink>
        </p>
      </div>
    </div>
  );
};

BeforeSale.displayName = 'BeforeSale';
