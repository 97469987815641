import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import Loader from '@appchoose/loader';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import i18n from 'i18next';

import { getI18nLanguageFromISOLanguage } from '../../lang/i18n';
import { useJoinBrandMutation } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';
import { hasBrandAccess } from '../../utils/brandsAccess';

export const ProtectedRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const { getAccessTokenSilently, logout, isLoading, isAuthenticated, user } =
    useAuth0();

  const { brandId = '' } = useParams<BrandMatch>();
  const [inviteCode, setInviteCode] = useSessionStorage<string>('inviteCode');

  const { mutateAsync: joinBrandMutation, error: joinBrandError } =
    useJoinBrandMutation();
  const [permissionLoading, setPermissionLoading] = useState(false);

  useEffect(() => {
    if (queryParams.has('invite-code')) {
      setInviteCode(queryParams.get('invite-code') as string);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const preferredLanguage = user?.[
        'https://appchoose.io/claims/preferredLanguage'
      ] as string | undefined;
      if (preferredLanguage) {
        const languageToSet = getI18nLanguageFromISOLanguage(preferredLanguage);
        if (languageToSet !== i18n.language) {
          i18n.changeLanguage(languageToSet);
        }
      }

      const handlePermission = async () => {
        setPermissionLoading(true);
        if (queryParams.has('invite-code') || inviteCode) {
          if (
            user &&
            user['https://appchoose.io/claims/isInternalUser'] === false &&
            !hasBrandAccess(user, brandId || '')
          ) {
            await joinBrandMutation({
              brandId: brandId || '',
              inviteCode: queryParams.has('invite-code')
                ? (queryParams.get('invite-code') as string)
                : inviteCode,
            });
            setInviteCode('');
            await getAccessTokenSilently({ cacheMode: 'off' });
          }
        } else {
          if (
            user &&
            user['https://appchoose.io/claims/isInternalUser'] === false
          ) {
            if (
              !hasBrandAccess(user, brandId || '') &&
              !location.pathname.includes(`${brandId}/limited-access`)
            ) {
              Sentry.captureException({
                name: 'noBrandAccessError',
                message: JSON.stringify({
                  brandAccess:
                    user?.['https://appchoose.io/claims/brandsAccess'],
                }),
              });
              navigate(`/${brandId}/limited-access`, { replace: true });
            }
            if (
              hasBrandAccess(user, brandId || '') &&
              location.pathname.includes(`${brandId}/limited-access`)
            ) {
              navigate(`/${brandId}/home`, { replace: true });
            }
          }
        }

        setPermissionLoading(false);
      };

      handlePermission();

      Sentry.setUser({ id: user?.sub, email: user?.email });
    } else {
      Sentry.setUser(null);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (joinBrandError) {
      Sentry.captureException(joinBrandError);
      navigate(`/${brandId}/limited-access`, { replace: true });
    }
  }, [joinBrandError]);

  if (isLoading || permissionLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="h-8 w-8" />
      </div>
    );
  }

  console.log('user', user);

  if (isAuthenticated) {
    return children;
  }

  sessionStorage.setItem(
    'redirectUri',
    `"${location.pathname}/${location.search}${location.hash}"`
  );

  logout({
    logoutParams: {
      returnTo: `${window.location.origin}/logout-successful${location.search}`,
    },
  });

  return (
    <div className="flex h-screen items-center justify-center">
      <Loader className="h-8 w-8" />
    </div>
  );
};

ProtectedRoute.displayName = 'ProtectedRoute';
