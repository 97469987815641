import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderQuery, OrderTagReturn } from '../../types/generated-new';
import { ModalOrderReturnBadge } from '../order-status-badge/modal-order-return-badge';
import { TrackingViewLink } from './tracking-view-link';

export type ModalOrderReturnParcelProps = {
  returnParcel?: OrderQuery['order']['returnParcels'][0];
  tags: OrderQuery['order']['tags'];
};

export const ModalOrderReturnParcel: React.FC<ModalOrderReturnParcelProps> = ({
  returnParcel,
  tags,
}: ModalOrderReturnParcelProps) => {
  const { t } = useTranslation();

  if (!returnParcel || tags.return?.[0] === OrderTagReturn.None) return null;

  return (
    <div className="rounded border border-gray-300">
      <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
        <ModalOrderReturnBadge tag={tags.return[0]} />
      </div>

      <div className="space-y-4 p-6 pt-4">
        <span className="text-sm text-gray-900">
          {tags.return[0] === OrderTagReturn.Delivered
            ? t('order.return.return_problem_contact_us')
            : null}
        </span>
        <TrackingViewLink parcel={returnParcel} />
      </div>
    </div>
  );
};
