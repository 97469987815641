import { Key } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';

import cn from '@appchoose/cn';
import Combobox, {
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxPopover,
  ComboboxSection,
  HighlightText,
  InputContainer,
} from '@appchoose/combobox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input from '@appchoose/input';
import { useQueryClient } from '@tanstack/react-query';

import { CouriersQuery, useCouriersQuery } from '../../types/generated-new';
import { TrackingInfoFormData } from './tracking-info-form';

type TrackingNumberFormFieldsProps = {
  appearance?: 'warning' | 'danger';
  detectedCouriers?: CouriersQuery['searchCouriers'];
  onReset?: () => void;
  previousValues?: TrackingInfoFormData;
};

export const selectedCourierState = atom<
  CouriersQuery['searchCouriers'][number] | null
>({
  key: 'selectedCourierState',
  default: null,
});

export const searchCourierState = atom<string>({
  key: 'searchCourierState',
  default: '',
});

const TRACKING_NUMBER_MIN_LENGTH = 4;
const TRACKING_NUMBER_MAX_LENGTH = 100;

export const TrackingNumberFormFields: React.FC<
  TrackingNumberFormFieldsProps
> = ({
  appearance,
  detectedCouriers,
  onReset,
  previousValues,
}: TrackingNumberFormFieldsProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { control, formState, getValues, setValue, trigger } =
    useFormContext<TrackingInfoFormData>();

  const [selectedCourier, setSelectedCourier] =
    useRecoilState(selectedCourierState);
  const [searchCourier, setSearchCourier] = useRecoilState(searchCourierState);

  const { data: couriers } = useCouriersQuery(
    {
      input: {
        name: searchCourier,
      },
    },
    {
      select: (data) => data.searchCouriers,
      enabled: !!searchCourier,
    }
  );

  const onSelectionChange = (key: Key | null) => {
    if (key === selectedCourier?.slug) return;

    const foundCourier = couriers?.find((f) => f.slug === key) ?? null;
    clearQueries();
    setSearchCourier(foundCourier?.name ?? '');
    setSelectedCourier(foundCourier);
    setValue('trackingCourierSlug', key as string, { shouldValidate: true });
    trigger('trackingNumber');
  };

  const onClear = () => {
    setSearchCourier('');
    setTimeout(() => {
      setSelectedCourier(null);
    }, 150);
    setValue('trackingCourierSlug', '');
    clearQueries();
  };

  const clearQueries = () => {
    queryClient.resetQueries({
      queryKey: ['detectCouriers'],
    });
    queryClient.resetQueries({
      queryKey: ['ordersByTracking'],
    });
    onReset?.();
  };

  return (
    <>
      <div className="space-y-2">
        <div className="flex flex-col gap-4 sm:flex-row">
          <FormField
            control={control}
            name="trackingNumber"
            rules={{
              required: true,
              minLength: TRACKING_NUMBER_MIN_LENGTH,
              maxLength: TRACKING_NUMBER_MAX_LENGTH,
              validate: {
                sameValue: (value) => {
                  if (!previousValues) return true;

                  if (
                    previousValues.trackingNumber === value &&
                    previousValues.trackingCourierSlug ===
                      getValues('trackingCourierSlug')
                  ) {
                    return false;
                  }
                },
              },
            }}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel
                  className={cn({
                    'text-orange-600': appearance === 'warning',
                    'text-red-600': appearance === 'danger',
                  })}
                >
                  {t('order.tracking.fields.tracking_number.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder={t(
                      'order.tracking.fields.tracking_number.placeholder'
                    )}
                    className={cn({
                      'border-orange-600': appearance === 'warning',
                      'border-red-600': appearance === 'danger',
                    })}
                    {...field}
                    onChange={(e) => {
                      clearQueries();
                      field.onChange(e);
                      trigger('trackingCourierSlug');
                    }}
                  />
                </FormControl>
                <FormMessage match="required">
                  {t(
                    'order.tracking.fields.tracking_number.validation_errors.required'
                  )}
                </FormMessage>
                <FormMessage match="minLength">
                  {t(
                    'order.tracking.fields.tracking_number.validation_errors.minLength',
                    { minLength: TRACKING_NUMBER_MIN_LENGTH }
                  )}
                </FormMessage>
                <FormMessage match="maxLength">
                  {t(
                    'order.tracking.fields.tracking_number.validation_errors.maxLength',
                    { maxLength: TRACKING_NUMBER_MAX_LENGTH }
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="trackingCourierSlug"
            rules={{
              required: true,
              validate: {
                sameValue: (value) => {
                  if (!previousValues) return true;

                  if (
                    previousValues.trackingNumber ===
                      getValues('trackingNumber') &&
                    previousValues.trackingCourierSlug === value
                  ) {
                    return false;
                  }
                },
              },
            }}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel
                  className={cn({
                    'text-orange-600': appearance === 'warning',
                    'text-red-600': appearance === 'danger',
                  })}
                >
                  {t('order.tracking.fields.carrier.label')}
                </FormLabel>

                <Combobox
                  selectedKey={selectedCourier?.slug}
                  inputValue={searchCourier}
                  onSelectionChange={onSelectionChange}
                  onInputChange={(value: string) => {
                    setSearchCourier(value);
                  }}
                  items={couriers}
                >
                  <InputContainer>
                    <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                      {selectedCourier ? (
                        <img
                          alt=""
                          src={selectedCourier.logoUrl}
                          className="size-5"
                        />
                      ) : (
                        <Icon icon="search" />
                      )}
                    </div>
                    <FormControl>
                      <ComboboxInput
                        className={cn('truncate pl-7', {
                          'pl-8 pr-8': selectedCourier,
                          'border-orange-600': appearance === 'warning',
                          'border-red-600': appearance === 'danger',
                        })}
                        placeholder={t(
                          'order.tracking.fields.carrier.placeholder'
                        )}
                        autoComplete="off"
                        disabled={field.disabled}
                        name={field.name}
                        onBlur={field.onBlur}
                        ref={field.ref}
                      />
                    </FormControl>
                    {selectedCourier ? (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <button
                          onClick={onClear}
                          type="button"
                          className="text-sm font-semibold"
                        >
                          <Icon icon="close" />
                        </button>
                      </div>
                    ) : null}
                  </InputContainer>
                  <ComboboxPopover className="!max-h-80">
                    <ComboboxList
                      renderEmptyState={() => (
                        <ComboboxEmpty>
                          {t('order.tracking.fields.carrier.no_result')}
                        </ComboboxEmpty>
                      )}
                    >
                      {couriers?.length ? (
                        <ComboboxSection>
                          {couriers.map((courier) => (
                            <ComboboxItem
                              id={courier.slug}
                              key={courier.slug}
                              textValue={courier.name}
                            >
                              {courier.logoUrl && (
                                <div className="mr-2 shrink-0">
                                  <img
                                    src={courier.logoUrl}
                                    alt=""
                                    className="size-5"
                                  />
                                </div>
                              )}
                              <div>
                                <HighlightText
                                  textToHighlight={searchCourier}
                                  value={courier.name}
                                  className="data-[user-value=true]:font-semibold"
                                />
                              </div>
                            </ComboboxItem>
                          ))}
                        </ComboboxSection>
                      ) : (
                        []
                      )}
                    </ComboboxList>
                  </ComboboxPopover>
                </Combobox>

                <FormMessage match="required">
                  {t(
                    'order.tracking.fields.carrier.validation_errors.required'
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
        </div>
        {formState.errors.trackingNumber?.type === 'sameValue' ? (
          <p className="text-xs text-red-600">
            {t('order.tracking.errors.tracking_number_must_be_different')}
          </p>
        ) : null}
        {detectedCouriers &&
        (detectedCouriers?.length ?? 0) === 0 &&
        appearance === 'warning' ? (
          <p className="text-sm font-semibold text-orange-600">
            {t(
              'order.tracking.errors.tracking_number_format_does_not_match_any_courier'
            )}
          </p>
        ) : null}
        {detectedCouriers &&
        (detectedCouriers?.length ?? 0) > 0 &&
        appearance === 'warning' ? (
          <p className="text-sm font-semibold text-orange-600">
            {t(
              'order.tracking.errors.tracking_number_format_does_not_match_the_courier'
            )}
          </p>
        ) : null}
        {appearance === 'danger' ? (
          <p className="text-sm font-semibold text-red-600">
            {t('order.tracking.errors.tracking_number_cannot_be_added')}
          </p>
        ) : null}
      </div>
      {detectedCouriers &&
      (detectedCouriers?.length ?? 0) > 0 &&
      appearance === 'warning' ? (
        <div className="space-y-2">
          <p className="text-sm text-gray-900">
            {t(
              'order.tracking.modal_add_tracking_number.several_carriers_compatible'
            )}
          </p>
          <ul className="flex flex-wrap gap-2">
            {detectedCouriers.slice(0, 4).map((detectedCourier) => (
              <li key={detectedCourier.slug}>
                <button
                  type="button"
                  onClick={() => {
                    setSearchCourier(detectedCourier.name);
                    setSelectedCourier(detectedCourier);
                    setValue('trackingCourierSlug', detectedCourier.slug);
                  }}
                  className="inline-flex items-center gap-2 rounded-sm border border-gray-100 px-2 py-1 text-sm"
                >
                  <img
                    alt=""
                    src={detectedCourier.logoUrl}
                    className="size-6"
                  />
                  <span className="font-semibold">{detectedCourier.name}</span>
                </button>
              </li>
            ))}
            {detectedCouriers.length > 4 ? (
              <li className="inline-flex items-center gap-2 px-2 py-1 text-sm">
                {t('order.tracking.x_more_carriers', {
                  count: detectedCouriers.length - 4,
                })}
              </li>
            ) : null}
          </ul>
        </div>
      ) : null}
    </>
  );
};
