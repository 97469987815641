import { useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import Illustration from '@appchoose/illustration';

import { useUploadCsvMutation } from '../../api/graphql';
import { activeSaleState } from '../../stores/sales';
import {
  useConfirmCsvMutation,
  useRemoveCsvMutation,
} from '../../types/generated';
import { BrandMatch } from '../../types/navigation';
import { ModalImportCsvDragAndDrop } from './modal-import-csv-drag-and-drop';

type ModalImportCsvProps = {
  onClose: () => void;
  onValid?: (url: string) => void;
};

export const ModalImportCsv = (props: ModalImportCsvProps) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<FileWithPath | undefined>(undefined);
  const [isEnded, setEnded] = useState(false);
  const [url, setUrl] = useState<string | undefined>(undefined);

  const { search } = useLocation();
  const { brandId = '' } = useParams<BrandMatch>();

  const activeSale = useRecoilValue(activeSaleState);

  const { mutateAsync: confirmCsvMutation } = useConfirmCsvMutation();
  const { mutateAsync: removeCsvMutation } = useRemoveCsvMutation();
  const [uploadCsvMutation, { data, loading, error }] = useUploadCsvMutation();

  useEffect(() => {
    if (data?.uploadedCsvUrl) {
      setUrl(data.uploadedCsvUrl);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setUrl(undefined);
    }
  }, [error]);

  const onDrop = useCallback(async (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length) {
      await uploadFile(acceptedFiles[0]);
    }
  }, []);

  const uploadFile = async (file: FileWithPath) => {
    setFile(file);
    if (activeSale) {
      await uploadCsvMutation({
        saleId: activeSale?.id,
        file: file,
      });
    }
  };

  const onValid = async () => {
    if (url && activeSale) {
      await confirmCsvMutation({
        saleId: activeSale.id,
        url: url,
      });
      setEnded(true);
    }
  };

  const onRemoveFile = async () => {
    if (url && activeSale) {
      await removeCsvMutation({
        saleId: activeSale.id,
        url: url,
      });
      setUrl('');
      setFile(undefined);
    }
  };

  return (
    <div className="flex h-full flex-col overflow-hidden">
      {!isEnded ? (
        <>
          <div className="h-full overflow-y-auto p-6 md:p-10">
            <h3
              id="modal-import-csv-title"
              className="mb-6 text-2xl font-bold text-gray-900"
            >
              {t('orders.modals.import.title')}
            </h3>
            <p className="text-sm text-gray-700">
              {t('orders.modals.import.download1')}
              <a
                className="text-sm font-semibold text-green-900"
                href="https://storage.cloud.google.com/public.appchoose.io/csv/import_tracking_template.csv"
                target="_blank"
                rel="noreferrer"
              >
                {t('orders.modals.import.download2')}
              </a>
              {t('orders.modals.import.download3')}
            </p>
            <p className="mb-6 text-sm text-gray-700">
              {t('orders.modals.import.warning')}
            </p>
            <div className="center">
              <ModalImportCsvDragAndDrop
                file={file}
                isUploading={loading}
                onRemoveFile={onRemoveFile}
                onDrop={onDrop}
                accept={{
                  'text/csv': ['.csv'],
                  'application/vnd.ms-excel': ['.xls'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    ['.xlsx'],
                }}
                maxFiles={1}
              />
              {error ? (
                Array.isArray(error) ? (
                  <p className="mt-4 text-xs text-red-600">{error[0]}</p>
                ) : (
                  <p className="mt-4 text-xs text-red-600">{error.message}</p>
                )
              ) : null}
            </div>
            <NavLink
              to={`/${brandId}/settings/integrations${search}`}
              target="_blank"
              className="mb-6 mt-4 inline-flex items-center rounded bg-green-100 px-2 py-1 text-xs text-green-900 transition-colors duration-300 hover:bg-green-900/[0.32]"
            >
              <Icon icon="code" className="mr-1" />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('orders.modals.import.api'),
                }}
              />
            </NavLink>
          </div>
          <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
            <Button type="button" appearance="outline" onClick={props.onClose}>
              {t('cancel')}
            </Button>{' '}
            <Button
              type="button"
              disabled={!url}
              onClick={onValid}
              appearance="primary"
            >
              {t('submit')}
            </Button>
          </footer>
        </>
      ) : (
        <div className="h-full overflow-y-auto p-6 md:p-10">
          <div className="flex flex-col items-center">
            <Illustration illustration="checked" className="mt-6" />
            <h3 className="mb-6 text-2xl font-bold text-gray-900">
              {t('orders.modals.import.title_valid')}
            </h3>
            <p className="text-sm text-gray-700">
              {t('orders.modals.import.p_valid')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
