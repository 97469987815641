import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '@appchoose/button';

import { BrandMatch } from '../../types/navigation';
import { SaleInfo } from '../sale-info/sale-info';

export const InvoiceReady: React.FC = () => {
  const { brandId = '' } = useParams<BrandMatch>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onPressBilling = () => {
    navigate({
      pathname: `/${brandId}/billing`,
      search: search,
    });
  };

  return (
    <SaleInfo
      variant="invoice-ready"
      title={t('orders.ended')}
      subtitle={t('invoice.create_invoice_text')}
    >
      <Button type="button" onClick={onPressBilling}>
        {t('invoice.create_invoice')}
      </Button>
    </SaleInfo>
  );
};

InvoiceReady.displayName = 'InvoiceReady';
