import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';

import { toast } from '@appchoose/toast';

import { Stepper } from '../../components/stepper/Stepper';
import { UpdatingInfoStepConfirmation } from '../../components/updating-info-screen/updating-info-step-confirmation';
import {
  UpdatingInfoProps,
  UpdatingInfoStepIntroduction,
} from '../../components/updating-info-screen/updating-info-step-introduction';
import { UpdatingInfoThankYou } from '../../components/updating-info-screen/updating-info-thank-you';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import { brandState } from '../../stores/brand';
import { useSetInfoAsUpToDateMutation } from '../../types/generated';
import { BrandMatch } from '../../types/navigation';
import { getCorrectStep } from '../../utils/utils';

export const UpdatingInfoScreen: React.FC = () => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { brandId = '', step: stepParam } = useParams<BrandMatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const steps = [
    {
      label: t('updating_info.introduction.name'),
      component: UpdatingInfoStepIntroduction,
    },
    {
      label: t('updating_info.confirmation.name'),
      component: UpdatingInfoStepConfirmation,
    },
  ];

  const [step, setStep] = useState<number>(
    getCorrectStep(stepParam, steps.length - 1)
  );
  const [isInfoUpToDate, setIsInfoUpToDate] = useState(false);

  const {
    mutateAsync: setInfoAsUpToDateMutation,
    isPending: loadingSetInfoAsUpToDate,
    error: errorSetInfoAsUpToDate,
  } = useSetInfoAsUpToDateMutation();

  useUpdateEffect(() => {
    setStep(getCorrectStep(stepParam, steps.length - 1));
  }, [stepParam]);

  useEffect(() => {
    if (errorSetInfoAsUpToDate) {
      toast.error(t('updating_info.confirmation_step.generic_error'));
    }
  }, [errorSetInfoAsUpToDate]);

  const goToNextStep = () => {
    if (step === 1) {
      onEndOnboarding();
    } else {
      navigate(`/${brandId}/updating-info/${step + 1}`);
      setStep(step + 1);
    }
  };

  const onEndOnboarding = async () => {
    await setInfoAsUpToDateMutation({});

    setIsInfoUpToDate(true);
    if (!brand) return;
    setBrand({
      ...brand,
      need_refresh_info: false,
    });
  };

  const Step = steps[step].component as React.ElementType<UpdatingInfoProps>;

  return (
    <>
      {isInfoUpToDate ? (
        <UpdatingInfoThankYou />
      ) : (
        <div className="onboarding mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
          <XChooseHeader brandName={brand?.name ?? undefined}>
            <Stepper
              steps={steps}
              currentStep={step}
              className="w-full sm:max-w-[22.5rem]"
            />
          </XChooseHeader>
          <main className="sm:px-[4.75rem]">
            <Routes>
              <Route
                path="*"
                element={
                  <Step
                    goToNextStep={goToNextStep}
                    loading={loadingSetInfoAsUpToDate}
                  />
                }
              />
            </Routes>
          </main>
        </div>
      )}
    </>
  );
};

UpdatingInfoScreen.displayName = 'UpdatingInfoScreen';
