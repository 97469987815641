import cn from '@appchoose/cn';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from './pagination';
import { DOTS, usePagination } from './use-pagination';

type PaginationListProps = {
  className?: string;
  currentPage: number;
  onChange: (page: number) => void;
  pageSize: number;
  totalCount: number;
};

export const PaginationList: React.FC<PaginationListProps> = ({
  className,
  currentPage,
  onChange,
  pageSize,
  totalCount,
}) => {
  const paginationRange = usePagination({
    totalCount,
    currentPage,
    pageSize,
    siblingCount: 0,
  });

  if (!paginationRange || paginationRange.length < 2) return null;

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Pagination className={className}>
      <PaginationContent>
        <PaginationItem
          aria-disabled={currentPage === 1}
          className={cn({
            'cursor-not-allowed': currentPage === 1,
          })}
        >
          <PaginationPrevious
            onClick={() => onChange(currentPage - 1)}
            className={cn('cursor-pointer', {
              'pointer-events-none opacity-30': currentPage === 1,
            })}
          />
        </PaginationItem>
        {paginationRange.map((page) => (
          <PaginationItem key={page}>
            {page === DOTS ? (
              <PaginationEllipsis className="text-gray-700" />
            ) : (
              <PaginationLink
                isActive={page === currentPage}
                onClick={() => onChange(page as number)}
                className={cn(
                  'cursor-pointer rounded p-1.5 px-2.5 text-sm font-bold',
                  {
                    'bg-green-900 text-white': page === currentPage,
                    'text-gray-700 hover:bg-green-300': page !== currentPage,
                  }
                )}
              >
                {page}
              </PaginationLink>
            )}
          </PaginationItem>
        ))}
        <PaginationItem
          aria-disabled={currentPage === lastPage}
          className={cn({
            'cursor-not-allowed': currentPage === lastPage,
          })}
        >
          <PaginationNext
            onClick={() => onChange(currentPage + 1)}
            className={cn('cursor-pointer', {
              'pointer-events-none opacity-30': currentPage === lastPage,
            })}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};
