import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import { TFunction } from 'i18next';

import { useSupportChat } from '../../hooks/use-support-chat';
import {
  OrderQuery,
  TrackingStatus,
  TrackingSubStatus,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { ModalOrderFulfillmentBadge } from '../order-status-badge/modal-order-fulfillment-badge';
import { ModalOrderShipmentBadge } from '../order-status-badge/modal-order-shipment-badge';
import { orderShipmentVariant } from '../order-status-badge/order-shipment-badge';
import { EstimatedDeliveryDates } from './estimated-delivery-dates';
import { ModalOrderDeliveredAt } from './modal-order-delivered-at';
import { TrackingInfoForm } from './tracking-info-form';
import { TrackingOrdersLinked } from './tracking-orders-linked';
import { TrackingViewLink } from './tracking-view-link';

type TrackingErrorVariables = {
  trackingSubStatus: OrderQuery['order']['parcels'][number]['trackingSubStatus'];
  hasTrackingStucked: OrderQuery['order']['parcels'][number]['hasTrackingStucked'];
};

export const getTrackingErrors = (
  { trackingSubStatus, hasTrackingStucked }: TrackingErrorVariables,
  t: TFunction
) => {
  if (!trackingSubStatus && !hasTrackingStucked) return [];

  const statusList: {
    id: string;
    title: string;
    description: string;
    toDo?: string;
    showContact?: boolean;
  }[] = [];

  if (hasTrackingStucked) {
    statusList.push({
      id: 'pending_tracking_stucked',
      title: t('shipment.errors.has_tracking_stucked_title'),
      description: t('shipment.errors.has_tracking_stucked_description'),
    });
  }

  switch (trackingSubStatus) {
    case TrackingSubStatus.ExceptionShippingException:
      statusList.push({
        id: 'exception_shipping_exception',
        title: t('shipment.errors.exception_shipping_exception_title'),
        description: t(
          'shipment.errors.exception_shipping_exception_description'
        ),
        toDo: t('shipment.errors.exception_shipping_exception_to_do'),
      });
      break;
    case TrackingSubStatus.ExceptionRecipientRelocated:
      statusList.push({
        id: 'exception_recipient_relocated',
        title: t('shipment.errors.exception_recipient_relocated_title'),
        description: t(
          'shipment.errors.exception_recipient_relocated_description'
        ),
        toDo: t('shipment.errors.exception_recipient_relocated_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionRecipientRefused:
      statusList.push({
        id: 'exception_recipient_refused',
        title: t('shipment.errors.exception_recipient_refused_title'),
        description: t(
          'shipment.errors.exception_recipient_refused_description'
        ),
        toDo: t('shipment.errors.exception_recipient_refused_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionDelayedCustomsClearance:
      statusList.push({
        id: 'exception_delayed_customs_clearance',
        title: t('shipment.errors.exception_delayed_customs_clearance_title'),
        description: t(
          'shipment.errors.exception_delayed_customs_clearance_description'
        ),
        toDo: t('shipment.errors.exception_delayed_customs_clearance_to_do'),
      });
      break;
    case TrackingSubStatus.ExceptionUnforseenReason:
      statusList.push({
        id: 'exception_unforeseen_reason',
        title: t('shipment.errors.exception_unforeseen_reason_title'),
        description: t(
          'shipment.errors.exception_unforeseen_reason_description'
        ),
        toDo: t('shipment.errors.exception_unforeseen_reason_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionIncorrectRecipientAddress:
      statusList.push({
        id: 'exception_incorrect_recipient_address',
        title: t('shipment.errors.exception_incorrect_recipient_address_title'),
        description: t(
          'shipment.errors.exception_incorrect_recipient_address_description'
        ),
        toDo: t('shipment.errors.exception_incorrect_recipient_address_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionPendingPayment:
      statusList.push({
        id: 'exception_pending_payment',
        title: t('shipment.errors.exception_pending_payment_title'),
        description: t('shipment.errors.exception_pending_payment_description'),
        toDo: t('shipment.errors.exception_pending_payment_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionPickupNotCollectedByRecipient:
      statusList.push({
        id: 'exception_pickup_not_collected_by_recipient',
        title: t(
          'shipment.errors.exception_pickup_not_collected_by_recipient_title'
        ),
        description: t(
          'shipment.errors.exception_pickup_not_collected_by_recipient_description'
        ),
        toDo: t(
          'shipment.errors.exception_pickup_not_collected_by_recipient_to_do'
        ),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionRejectedByCarrier:
      statusList.push({
        id: 'exception_rejected_by_carrier',
        title: t('shipment.errors.exception_rejected_by_carrier_title'),
        description: t(
          'shipment.errors.exception_rejected_by_carrier_description'
        ),
        toDo: t('shipment.errors.exception_rejected_by_carrier_to_do'),
      });
      break;
    case TrackingSubStatus.ExceptionBackToSender:
      statusList.push({
        id: 'exception_back_to_sender',
        title: t('shipment.errors.exception_back_to_sender_title'),
        description: t('shipment.errors.exception_back_to_sender_description'),
        toDo: t('shipment.errors.exception_back_to_sender_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionBackToSenderReceived:
      statusList.push({
        id: 'exception_back_to_sender_received',
        title: t('shipment.errors.exception_back_to_sender_received_title'),
        description: t(
          'shipment.errors.exception_back_to_sender_received_description'
        ),
        toDo: t('shipment.errors.exception_back_to_sender_received_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.ExceptionDamaged:
      statusList.push({
        id: 'exception_damaged',
        title: t('shipment.errors.exception_damaged_title'),
        description: t('shipment.errors.exception_damaged_description'),
        toDo: t('shipment.errors.exception_damaged_to_do'),
      });
      break;
    case TrackingSubStatus.ExceptionLost:
      statusList.push({
        id: 'exception_lost',
        title: t('shipment.errors.exception_lost_title'),
        description: t('shipment.errors.exception_lost_description'),
        toDo: t('shipment.errors.exception_lost_to_do'),
      });
      break;
    case TrackingSubStatus.ExpiredNoTrackingInformation:
      statusList.push({
        id: 'expired_no_tracking_information',
        title: t('shipment.errors.expired_no_tracking_information_title'),
        description: t(
          'shipment.errors.expired_no_tracking_information_description'
        ),
        toDo: t('shipment.errors.expired_no_tracking_information_to_do'),
        showContact: true,
      });
      break;
    case TrackingSubStatus.PendingCarrierUnrecognized:
      statusList.push({
        id: 'pending_carrier_unrecognized',
        title: t('shipment.errors.pending_carrier_unrecognized_title'),
        description: t(
          'shipment.errors.pending_carrier_unrecognized_description'
        ),
        showContact: true,
      });
      break;
    case TrackingSubStatus.PendingWrongCarrier:
      statusList.push({
        id: 'pending_wrong_carrier',
        title: t('shipment.errors.pending_wrong_carrier_title'),
        description: t('shipment.errors.pending_wrong_carrier_description'),
        showContact: true,
      });
      break;
  }

  return statusList;
};

export type ModalOrderParcelProps = {
  orderId: string;
  isFullDigital: boolean;
  shipping?: OrderQuery['order']['shipping'];
  parcel?: OrderQuery['order']['parcels'][0];
  tags: OrderQuery['order']['tags'];
};

export const ModalOrderParcel: React.FC<ModalOrderParcelProps> = ({
  orderId,
  isFullDigital,
  shipping,
  parcel,
  tags,
}: ModalOrderParcelProps) => {
  const { openSupportChatWithPrePopulatedContent } = useSupportChat();
  const { t } = useTranslation();

  if (isFullDigital) return null;

  if (
    (!parcel || parcel?.trackingStatus !== TrackingStatus.Pending) &&
    !parcel?.trackingNumber
  ) {
    return (
      <div className="rounded border border-gray-300">
        <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
          <ModalOrderFulfillmentBadge tag={tags.fulfillment} />
        </div>
        <div className="space-y-4 p-6 pt-4">
          <TrackingInfoForm
            orderId={orderId}
            parcel={parcel}
            shipping={shipping}
          />
        </div>
      </div>
    );
  }

  const trackingErrors = getTrackingErrors(
    {
      hasTrackingStucked: parcel.hasTrackingStucked,
      trackingSubStatus: parcel.trackingSubStatus,
    },
    t
  );
  const variantStyle = orderShipmentVariant({
    hasTrackingStucked: parcel.hasTrackingStucked,
    tag: tags.shipment[0],
    trackingSubStatus: parcel.trackingSubStatus,
  });

  const onOpenChat = () => {
    track('OpenHelpChat', {
      from: 'ShipmentPendingError',
    });

    openSupportChatWithPrePopulatedContent(
      t('support_chat.intercom_message_prefill', {
        orderId,
        customer: parcel?.recipient?.fullName,
      })
    );
  };

  return (
    <div className="rounded border border-gray-300">
      <div className="flex items-center justify-between border-b border-gray-300 px-6 py-4">
        <ModalOrderShipmentBadge
          hasTrackingStucked={parcel.hasTrackingStucked}
          tag={tags.shipment[0]}
          trackingSubStatus={parcel.trackingSubStatus}
        />

        <ModalOrderDeliveredAt parcel={parcel} />
      </div>

      <div className="space-y-4 p-6 pt-4">
        {trackingErrors.length > 0 && (
          <div
            className={cn('space-y-4 rounded border p-4', {
              'border-[#F0C5C5] bg-red-50': variantStyle === 'danger',
              'border-[#F6E1C2] bg-orange-300': variantStyle === 'warning',
            })}
          >
            {trackingErrors?.map((trackingError) => (
              <div key={trackingError.id} className="space-y-4">
                <div className="space-y-1">
                  <div className="text-sm font-bold text-gray-700">
                    {trackingError.title}
                  </div>
                  <div className="text-sm text-gray-700">
                    {trackingError.description}
                  </div>
                </div>
                {trackingError.toDo ? (
                  <div className="text-sm font-bold text-gray-700">
                    {trackingError.toDo}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}

        <div className="text-sm text-gray-700">
          {t('order.announced_delivery')}{' '}
          <EstimatedDeliveryDates
            history={shipping?.deliveryDateRangeHistory}
          />
        </div>

        <TrackingViewLink parcel={parcel} />
        <TrackingOrdersLinked parcel={parcel} />
        {parcel?.trackingStatus === TrackingStatus.Delivered ? (
          <div className="flex items-center space-x-1 text-sm">
            <span className="text-gray-700">
              {t('order.tracking.have_a_problem')}
            </span>

            <button
              type="button"
              onClick={onOpenChat}
              className="text-sm font-semibold text-green-900"
            >
              {t('contact_us')}
            </button>
          </div>
        ) : null}
        {trackingErrors.some((error) => error.showContact) ? (
          <div className="border-t border-gray-300 pt-4">
            <Button
              type="button"
              onClick={onOpenChat}
              className="w-full justify-center"
            >
              {t('order.actions_contact_us_via_chat')}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
