import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app/App';
import { rudderInitialize } from './utils/analytics';
import { loadPolyfills } from './utils/polyfills';

import './index.css';

rudderInitialize();
loadPolyfills();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
