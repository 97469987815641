import { getCountryFromCountryName } from '@appchoose/address';
import { isPast } from 'date-fns';

import { Brand } from '../stores/brand';
import { StoreRegion } from '../types/generated';
import { isInUE } from './utils';

export const isMissingBillingInformations = (brand: Brand) => {
  return (
    !brand?.billing_address ||
    (brand?.store === StoreRegion.Fr &&
      isInUE(getCountryFromCountryName(brand.billing_address.country)?.code) &&
      !brand.intracommunity_vat &&
      !brand.is_autoentrepreneur)
  );
};

export const hasSaleStarted = (startDate?: string) => {
  if (!startDate) return false;

  return isPast(new Date(startDate));
};

export const hasSaleEnded = (endDate?: string) => {
  if (!endDate) return false;

  return isPast(new Date(endDate));
};
