import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import Loader from '@appchoose/loader';
import { useAuth0 } from '@auth0/auth0-react';

import { useUploadPictureMutation } from '../../api/graphql';
import { AvatarProfile } from '../avatar-profile/avatar-profile';

type UploadPictureFormData = {
  file: File;
};

export const UploadPictureForm: React.FC = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { t } = useTranslation();

  const [pictureUrl, setPictureUrl] = useState(
    user?.['https://appchoose.io/claims/picture']
  );

  const form = useForm<UploadPictureFormData>();

  const fileInput = useRef<HTMLInputElement>(null);

  const [uploadPictureMutation, { data, loading, error }] =
    useUploadPictureMutation();

  useEffect(() => {
    if (data?.uploadedPictureUrl) {
      setPictureUrl(data?.uploadedPictureUrl);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      form.setError('file', { type: 'generic' });
    }
  }, [error]);

  const handleFileExplorerOpen = () => {
    if (!loading) fileInput.current?.click();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    form.clearErrors();

    if (file) {
      form.setValue('file', file);
      form.handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data: UploadPictureFormData) => {
    await uploadPictureMutation({
      file: data.file,
    });

    await getAccessTokenSilently({ cacheMode: 'off' });
  };

  form.register('file', {
    validate: {
      fileSizeExceeded: (value: File) => value.size <= 1024 * 1024 * 3,
    },
  });

  const ErrorFileMessage =
    (form.formState.errors.file?.type === 'fileSizeExceeded' &&
      `${t(
        'settings.account_tabs.picture.validation_errors.file_size_exceeded',
        { maxFileSize: '3' }
      )}`) ||
    (form.formState.errors.file?.type === 'generic' &&
      t('settings.account_tabs.picture.generic_error'));

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex items-center space-x-4"
      >
        <AvatarProfile className="size-22" pictureUrl={pictureUrl} />
        <div>
          <input
            ref={fileInput}
            className="sr-only"
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleFileUpload}
          />
          <Button
            type="button"
            appearance="outline"
            onClick={handleFileExplorerOpen}
            className="mb-2 cursor-pointer"
          >
            {t('settings.account_tabs.import_photo')}
            {loading && <Loader className="ml-3 h-4 w-4" />}
          </Button>
          <p className="m-0 text-xs">
            {ErrorFileMessage ? (
              <span className="text-red-600">{ErrorFileMessage}</span>
            ) : (
              <span className="text-gray-500">
                {t('settings.account_tabs.max_image_size', {
                  maxFileSize: '3',
                })}
              </span>
            )}
          </p>
        </div>
      </form>
    </Form>
  );
};

UploadPictureForm.displayName = 'UploadPictureForm';
