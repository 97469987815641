import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import Icon from '@appchoose/icon';

import { OrderQuery } from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';

type TrackingViewReshipProps = {
  reshipOrderId?: OrderQuery['order']['reshipOrderId'];
};

export const TrackingViewReship: React.FC<TrackingViewReshipProps> = ({
  reshipOrderId,
}: TrackingViewReshipProps) => {
  const { brandId } = useParams<BrandMatch>();
  const { search } = useLocation();
  const { t } = useTranslation();

  return (
    <NavLink
      to={`/${brandId}/orders/${reshipOrderId}${search}`}
      className="mt-4 flex flex-row items-center rounded bg-gray-100 text-left"
    >
      <div className="flex flex-1 flex-col p-4">
        <span className="text-xs text-gray-500">{t('order.num_order')}</span>
        <span className="text-sm text-gray-900">{reshipOrderId}</span>
      </div>
      <div className="m-4 shrink-0">
        <Icon icon="externalLinkDefault" size="large" />
      </div>
    </NavLink>
  );
};
