type RefundProps = React.ComponentPropsWithoutRef<'svg'>;

export const Refund: React.FC<RefundProps> = ({ ...props }: RefundProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583313 3.5C0.583313 2.5335 1.36681 1.75 2.33331 1.75H11.6666C12.6331 1.75 13.4166 2.5335 13.4166 3.5V10.5C13.4166 11.4665 12.6331 12.25 11.6666 12.25H7.29165C6.96948 12.25 6.70831 11.9888 6.70831 11.6667C6.70831 11.3445 6.96948 11.0833 7.29165 11.0833H11.6666C11.9888 11.0833 12.25 10.8222 12.25 10.5V6.41667H1.74998V6.70833C1.74998 7.0305 1.48881 7.29167 1.16665 7.29167C0.84448 7.29167 0.583313 7.0305 0.583313 6.70833V3.5ZM1.74998 5.25H12.25V3.5C12.25 3.17783 11.9888 2.91667 11.6666 2.91667H2.33331C2.01115 2.91667 1.74998 3.17783 1.74998 3.5V5.25Z"
        fill="currentColor"
      />
      <path
        d="M5.10246 10.6451C5.42463 10.6451 5.68579 10.3839 5.68579 10.0617C5.68579 9.73958 5.42463 9.47841 5.10246 9.47841H2.24015L2.8468 8.87177C3.37877 8.3398 2.55381 7.51484 2.02184 8.04681L0.426253 9.6424C0.199252 9.86194 0.188194 10.2519 0.419266 10.4743L2.02184 12.0769C2.55381 12.6089 3.37877 11.7839 2.8468 11.252L2.23992 10.6451H5.10246Z"
        fill="currentColor"
      />
    </svg>
  );
};
