import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';
import { isAfter } from 'date-fns';

import { brandState } from '../stores/brand';
import { RawSale } from '../stores/sales';
import { useCurrentUserQuery } from '../types/generated-new';
import { openCrispChat } from '../utils/crisp';
import {
  initIntercom,
  openIntercom,
  openIntercomWithPrePopulatedContent,
} from '../utils/intercom';

const SALE_INTERCOM_ACTIVATION_DATE = '2024-10-09';

const isIntercomSupportProvider = (saleStartAt?: string | null) => {
  return (
    !saleStartAt ||
    isAfter(new Date(saleStartAt), new Date(SALE_INTERCOM_ACTIVATION_DATE))
  );
};

const supportProviderState = atom<'intercom' | 'crisp' | undefined>({
  key: 'supportProvider',
  default: undefined,
});

export const useSupportChat = () => {
  const { user } = useAuth0();
  const { data: userData } = useCurrentUserQuery();
  const brand = useRecoilValue(brandState);
  const [supportProvider, setSupportProvider] =
    useRecoilState(supportProviderState);

  const setSupportPlatform = (sale: RawSale | undefined) => {
    if (!isIntercomSupportProvider(sale?.start_at)) {
      setSupportProvider('crisp');
      return;
    }

    initIntercom({
      user_id: user?.sub ?? 'no_info',
      name: user?.name ?? 'No name',
      email: user?.email ?? 'tech@appchoose.io',
      company: {
        company_id: brand?.id ?? '',
        name: brand?.name ?? '',
        contact_person: sale?.logistics_manager ?? '',
        account_manager: sale?.logistics_manager ?? '',
      },
      user_hash: userData?.currentUser?.intercomUserHash ?? '',
    });

    setSupportProvider('intercom');
  };

  const openSupportChat = () => {
    if (supportProvider === 'intercom') {
      openIntercom();
    } else {
      openCrispChat();
    }
  };

  const openSupportChatWithPrePopulatedContent = (message: string) => {
    if (supportProvider === 'intercom') {
      openIntercomWithPrePopulatedContent(message);
    } else {
      openCrispChat();
    }
  };

  return {
    setSupportPlatform,
    openSupportChat,
    openSupportChatWithPrePopulatedContent,
  };
};
