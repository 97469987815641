import { MouseEvent } from 'react';
import { DropzoneOptions, FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';

import { MediumArrow } from './medium-arrow';
import { MediumCsv } from './medium-csv';
import { MediumError } from './medium-error';
import { MediumPlus } from './medium-plus';

type ModalImportCsvDragAndDropProps = {
  file: FileWithPath | undefined;
  isUploading: boolean;
  onAddFile?: (e: MouseEvent<HTMLButtonElement>) => void;
  onRemoveFile?: (e: MouseEvent<HTMLButtonElement>) => void;
} & DropzoneOptions;

export const ModalImportCsvDragAndDrop: React.FC<
  ModalImportCsvDragAndDropProps
> = ({
  file,
  isUploading,
  onAddFile,
  onRemoveFile,
  ...dropzoneOptions
}: ModalImportCsvDragAndDropProps) => {
  const { t } = useTranslation();
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    open,
  } = useDropzone(dropzoneOptions);

  function renderContent() {
    if (file) {
      if (isUploading) {
        return (
          <>
            <Loader className="h-8 w-8" />
            <p className="text-balance text-center text-sm font-semibold text-gray-900">
              {file.path}
            </p>
          </>
        );
      }
      return (
        <>
          <MediumCsv />
          <p className="text-balance text-center text-sm font-semibold text-gray-900">
            {file.path}
          </p>
          <button
            type="button"
            className="text-sm font-bold text-green-900"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              if (onRemoveFile) onRemoveFile(e);
            }}
          >
            {t('orders.modals.import.remove')}
          </button>
        </>
      );
    }
    if (isDragActive) {
      if (isDragAccept) {
        return (
          <>
            <MediumArrow />
            <p className="text-balance text-center text-sm font-semibold text-gray-900">
              {t('orders.modals.import.relache')}
            </p>
          </>
        );
      }
      return (
        <>
          <MediumError />
          <p className="text-balance text-center text-sm font-semibold text-gray-900">
            {t('orders.modals.import.error')}
          </p>
        </>
      );
    }
    return (
      <>
        <MediumPlus />
        <Button
          type="button"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            open();
            if (onAddFile) onAddFile(e);
          }}
        >
          {t('orders.modals.import.add_file')}
        </Button>
        <p className="text-balance text-center text-sm font-semibold text-gray-900">
          {t('orders.modals.import.depose')}
        </p>
        <p className="flex items-center space-x-1 text-sm text-gray-500">
          <Icon icon="clock" />
          <span>{t('orders.modals.import.delay')}</span>
        </p>
      </>
    );
  }
  return (
    <div
      className="flex h-[218px] flex-col items-center justify-center space-y-4 rounded border border-dashed border-beige-400 bg-beige-300 p-6 transition-colors hover:border-solid hover:bg-beige-400/[0.72] data-[active=true]:border-solid data-[rejected=true]:border-solid data-[rejected=true]:border-red-600 data-[active=true]:bg-beige-400/[0.72] data-[rejected=true]:bg-red-300"
      data-active={isDragActive}
      data-rejected={isDragReject}
      {...getRootProps()}
    >
      <input {...getInputProps()} hidden />
      {renderContent()}
    </div>
  );
};
