import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import Alert from '@appchoose/alert';

import { BrandMatch } from '../../types/navigation';

export const OrderShopifyNotConfiguredAlert: React.FC = () => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const { brandId = '' } = useParams<BrandMatch>();

  return (
    <Alert
      size="small"
      appearance="error"
      icon="shopifyError"
      title={t('order.integrations.shopify.shopify_error_installation_title')}
    >
      <p className="text-sm text-gray-700">
        {t('order.integrations.shopify.shopify_error_installation_text')}
        <br />
        <NavLink
          to={`/${brandId}/settings/integrations${search}`}
          className="underline"
        >
          {t('sidebar.settings')}
        </NavLink>
      </p>
    </Alert>
  );
};
