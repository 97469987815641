import { useDebounce } from 'react-use';
import { atom, useRecoilState } from 'recoil';

import { track } from '../../utils/analytics';
import { getSearchParam } from '../../utils/url';

const searchTermState = atom<string | undefined>({
  key: 'searchTermState',
  default: Promise.resolve(getSearchParam('q')),
});

const debouncedSearchTermState = atom<string | undefined>({
  key: 'debouncedSearchTermState',
  default: Promise.resolve(getSearchParam('q')),
});

const isSearchActiveState = atom<boolean>({
  key: 'isSearchActiveState',
  default: Promise.resolve(!!getSearchParam('q')),
});

export const useSearch = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermState);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useRecoilState(
    debouncedSearchTermState
  );
  useDebounce(() => setDebouncedSearchTerm(searchTerm), 150, [searchTerm]);

  const [isSearchActive, setIsSearchActive] =
    useRecoilState(isSearchActiveState);

  const resetSearch = () => {
    setSearchTerm(undefined);
    setIsSearchActive(false);
  };

  const trackOpenSearch = () => {
    track('GoToSearchOrder', {});
  };

  return {
    debouncedSearchTerm,
    isSearchActive,
    isSearchEmpty: isSearchActive && !searchTerm,
    resetSearch,
    searchTerm,
    setIsSearchActive,
    setSearchTerm,
    trackOpenSearch,
  } as const;
};
