import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmPasswordlessCodeForm } from '../../components/confirm-passwordless-code-form/confirm-passwordless-code-form';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';

export const ConfirmPasswordlessCode: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader />
      <main className="mx-2 mt-10 sm:mx-8 sm:mt-20 md:mx-24">
        <h1 className="mb-4 text-3.5xl font-bold">
          {t('auth.confirm_passwordless_code.verify_code_in_your_inbox')}
        </h1>
        <ConfirmPasswordlessCodeForm />
      </main>
    </div>
  );
};

ConfirmPasswordlessCode.displayName = 'ConfirmPasswordlessCode';
