import React from 'react';

export class NotFoundScreen extends React.Component {
  componentDidMount() {
    if (import.meta.env.REACT_APP_ENV === 'production') {
      window.location.replace('https://appchoose.io');
    } else {
      window.location.replace('9281b88e18594576ae19ff83a/orders');
    }
  }

  render() {
    return (
      <div>
        <p
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            /* margin: auto; */
            bottom: 0,
            textAlign: 'center',
            lineHeight: '100vh',
          }}
        >
          404 Not found
        </p>
      </div>
    );
  }
}
