import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '@appchoose/loader';
import {
  addMinutes,
  addSeconds,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

import { passwordlessStart } from '../../utils/auth';
import { pad } from '../../utils/string';

type ExpirationDelayProps = {
  email: string;
};

export const ExpirationDelay: React.FC<ExpirationDelayProps> = ({
  email,
}: ExpirationDelayProps) => {
  const { t } = useTranslation();
  // OTP Expiry: https://manage.auth0.com/dashboard/eu/production-choose/connections/passwordless
  const timeLeftInMinutes = 10;

  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState(
    addMinutes(currentDate, timeLeftInMinutes)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [serverError, setServerError] = useState<string>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(addSeconds(timeLeft, -1));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const sendPasswordlessCode = async () => {
    setIsLoading(true);

    try {
      await passwordlessStart({
        email: email,
      });

      setIsEmailSent(true);

      const updatedDate = new Date();
      setCurrentDate(updatedDate);
      setTimeLeft(addMinutes(updatedDate, timeLeftInMinutes));
    } catch (error) {
      setIsEmailSent(true);

      setServerError(
        t('auth.errors.auth_passwordless_code.resent_email_error')
      );
    }

    setIsLoading(false);
  };

  const diffInMinutes = differenceInMinutes(timeLeft, currentDate);
  const diffInSeconds = differenceInSeconds(timeLeft, currentDate);

  return diffInSeconds > 0 ? (
    <>
      {t('auth.confirm_passwordless_code.code_expire_soon')}{' '}
      <span className={diffInSeconds < 60 ? 'text-red-600' : ''}>
        ({diffInMinutes}:{pad(diffInSeconds - diffInMinutes * 60, 2)})
      </span>
      .
    </>
  ) : (
    <span className="text-red-600">
      {t('auth.confirm_passwordless_code.code_expired')}{' '}
      {!isEmailSent ? (
        isLoading ? (
          <button
            type="button"
            onClick={sendPasswordlessCode}
            className="font-bold text-green-900"
          >
            {t('auth.confirm_passwordless_code.resend_code')}
          </button>
        ) : (
          <Loader className="ml-1 inline-block h-4 w-4" />
        )
      ) : !serverError ? (
        <span>{t('auth.confirm_passwordless_code.new_code_resent')}</span>
      ) : (
        <span>{serverError}</span>
      )}
    </span>
  );
};
