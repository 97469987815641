import { useSearchParams } from 'react-router-dom';

import { usePagination } from '../../views/orders-page/use-pagination';
import { useSearch } from '../../views/orders-page/use-search';
import { TabEntry, useTabs } from '../../views/orders-page/use-tabs';
import { useFilters } from './use-filters';
import { useSorting } from './use-sorting';

export const useResetList = () => {
  const { resetFilters } = useFilters();
  const { resetPagination } = usePagination();
  const { resetSearch } = useSearch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { resetSorting } = useSorting();
  const { setSelectedTab } = useTabs();

  const resetList = (tabEntry: TabEntry = TabEntry.RequiredAction) => {
    resetFilters();
    resetPagination();
    resetSearch();
    resetSorting();
    setSelectedTab(tabEntry);
    setSearchParams((prev) => {
      prev.delete('filters');
      prev.delete('after');
      prev.delete('before');
      prev.delete('q');
      prev.delete('sort');
      prev.set('view', tabEntry.toString());

      return prev;
    });
  };

  return { resetList } as const;
};
