import React, { forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input, { InputAdornment, InputProps } from '@appchoose/input';

import { useChangePasswordMutation } from '../../types/generated-new';
import { PasswordFormField } from '../password-form-field/password-form-field';
import { ModalChangePasswordSuccess } from './modal-change-password-success';

type ChangePasswordFormData = {
  password: string;
  confirmPassword: string;
};

type ModalChangePasswordProps = {
  handleChangePasswordModal: (isOpen: boolean) => void;
};

export const InputPassword = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'type' | 'endAdornment'>
>(({ ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
            tabIndex={-1}
          >
            {showPassword ? <Icon icon="eye" /> : <Icon icon="eyeOff" />}
          </button>
        </InputAdornment>
      }
      {...props}
      ref={ref}
    />
  );
});
InputPassword.displayName = 'InputPassword';

export const ModalChangePassword: React.FC<ModalChangePasswordProps> = ({
  handleChangePasswordModal,
}: ModalChangePasswordProps) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);

  const { mutateAsync: changePassword, isPending } =
    useChangePasswordMutation();

  const form = useForm<ChangePasswordFormData>({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (data: ChangePasswordFormData) => {
    await changePassword({
      newPassword: data.password,
    });

    setSuccess(true);
  };

  form.watch('password');

  return (
    <div className="flex h-full flex-col justify-between overflow-hidden">
      {success ? (
        <ModalChangePasswordSuccess setIsOpen={handleChangePasswordModal} />
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-auto flex-col"
          >
            <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
              <h3
                id="change-password-title"
                className="mb-8 text-2xl font-bold text-gray-900"
              >
                {t('settings.account_tabs.change_password.title')}
              </h3>
              <div className="space-y-6">
                <div className="space-y-2">
                  <PasswordFormField
                    label={t(
                      'settings.account_tabs.change_password.new_password_label'
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="confirmPassword"
                  rules={{
                    required: true,
                    validate: {
                      match: (value) => value === form.getValues('password'),
                    },
                    deps: ['password', 'confirmPassword'],
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t(
                          'settings.account_tabs.change_password.confirm_password_label'
                        )}
                      </FormLabel>
                      <FormControl>
                        <InputPassword {...field} />
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'auth.onboarding_update_profile.fields.password.validation_errors.required'
                        )}
                      </FormMessage>
                      <FormMessage match="match">
                        {t(
                          'auth.change_password.fields.confirmation_password.validation_errors.match'
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
              <Button
                type="button"
                className="border-none text-green-900"
                appearance="outline"
                onClick={() => handleChangePasswordModal(false)}
              >
                {t('cancel')}
              </Button>{' '}
              <Button type="submit" appearance="primary" disabled={isPending}>
                {t('save')}
              </Button>
            </footer>
          </form>
        </Form>
      )}
    </div>
  );
};
