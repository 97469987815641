import { deepCopy } from '../utils/object';
import { uniqueBy } from '../utils/utils';
import otherDeliveryImage from './../assets/img/Delivery.svg';
import otherShippingImage from './../assets/img/Shipping.svg';

export { otherDeliveryImage, otherShippingImage };

export interface Service {
  text?: string;
  type?: string;
}

export type ServiceOption = {
  selected: boolean;
  initialState?: boolean;
  image?: string;
  logo_url?: string;
} & Service;

export const frDeliveryServices: ServiceOption[] = [
  {
    selected: false,
    type: 'colissimo',
    text: 'Colissimo',
    logo_url: 'https://assets.aftership.com/couriers/svg/colissimo.svg',
  },
  {
    selected: false,
    type: 'la-poste-colissimo',
    text: 'La Poste',
    logo_url:
      'https://assets.aftership.com/couriers/svg/la-poste-colissimo.svg',
  },
  {
    selected: false,
    type: 'chronopost-france',
    text: 'Chronopost France',
    logo_url: 'https://assets.aftership.com/couriers/svg/chronopost-france.svg',
  },
  {
    selected: false,
    type: 'ups',
    text: 'UPS',
    logo_url: 'https://assets.aftership.com/couriers/svg/ups.svg',
  },
  {
    selected: false,
    type: 'dhl',
    text: 'DHL Express',
    logo_url: 'https://assets.aftership.com/couriers/svg/dhl.svg',
  },
  {
    selected: false,
    type: 'fedex',
    text: 'FedEx®',
    logo_url: 'https://assets.aftership.com/couriers/svg/fedex.svg',
  },
  {
    selected: false,
    type: 'dpd',
    text: 'DPD',
    logo_url: 'https://assets.aftership.com/couriers/svg/dpd.svg',
  },
  {
    selected: false,
    type: 'colis-prive',
    text: 'Colis Privé',
    logo_url: 'https://assets.aftership.com/couriers/svg/colis-prive.svg',
  },
  {
    selected: false,
    type: 'gls',
    text: 'GLS',
    logo_url: 'https://assets.aftership.com/couriers/svg/gls.svg',
  },
  {
    selected: false,
    type: 'postnl-international',
    text: 'PostNL International',
    logo_url: 'https://assets.aftership.com/couriers/svg/postnl.svg',
  },
  {
    selected: false,
    type: 'cubyn',
    text: 'Cubyn',
    logo_url: 'https://assets.aftership.com/couriers/svg/cubyn.svg',
  },
];

export const usDeliveryServices: ServiceOption[] = [
  {
    selected: false,
    type: 'usps',
    text: 'USPS',
    logo_url: 'https://assets.aftership.com/couriers/svg/usps.svg',
  },
  {
    selected: false,
    type: 'ups',
    text: 'UPS',
    logo_url: 'https://assets.aftership.com/couriers/svg/ups.svg',
  },
  {
    selected: false,
    type: 'fedex',
    text: 'FedEx®',
    logo_url: 'https://assets.aftership.com/couriers/svg/fedex.svg',
  },
  {
    selected: false,
    type: 'dhl-global-mail',
    text: 'DHL eCommerce US',
    logo_url: 'https://assets.aftership.com/couriers/svg/dhl-global-mail.svg',
  },
  {
    selected: false,
    type: 'ontrac',
    text: 'OnTrac',
    logo_url: 'https://assets.aftership.com/couriers/svg/ontrac.svg',
  },
  {
    selected: false,
    type: 'lasership',
    text: 'LaserShip',
    logo_url: 'https://assets.aftership.com/couriers/svg/lasership.svg',
  },
  {
    selected: false,
    type: 'osm-worldwide',
    text: 'OSM Worldwide',
    logo_url: 'https://assets.aftership.com/couriers/svg/osm-worldwide.svg',
  },
  {
    selected: false,
    type: 'yrc',
    text: 'YRC',
    logo_url: 'https://assets.aftership.com/couriers/svg/yrc.svg',
  },
];

export const chooseDeliveryService: ServiceOption = {
  selected: false,
  type: 'choose',
  text: 'Choose',
  initialState: true,
  image: otherDeliveryImage,
};

export const otherDeliveryService: ServiceOption = {
  selected: false,
  type: 'other',
  text: 'other',
  initialState: true,
  image: otherDeliveryImage,
};

export const shippingServices: ServiceOption[] = [
  {
    selected: false,
    type: 'sendcloud',
    text: 'Sendcloud',
    logo_url: 'https://public.choose.app/services/100x100/sendcloud@3x.png',
  },
];

export const otherShippingService: ServiceOption = {
  selected: false,
  type: 'other',
  text: 'other',
  initialState: true,
  image: otherShippingImage,
};

/**
 * Transpose un tableau d'objets {@link ServiceOption} en tableau d'objets {@link Service}
 * @param {ServiceOption[]} services Le tableau d'objets à transposer
 * @returns {Service[]} Le tableau d'objets transposés
 */
export const mapServiceOptionsToServices = (
  services: ServiceOption[]
): Service[] => {
  return services.map((service) => ({
    type: service.type,
    text: service.text,
  }));
};

/**
 * Récupère l'ensemble des services et leur état (sélectionné ou non), que ce soit des services connus ou saisis à la main
 * @param {ServiceOption[]} defaultServices Les services connus
 * @param {Service[] | null} selectedServices Les services déjà sélectionnés par l'utilisateur
 * @param {string} otherImage L'image par défaut à appliquer aux services créés manuellement
 * @returns {ServiceOption[]} Le tableau d'objets avec l'ensemble des services
 */
export const getActionableServices = (
  defaultServices: ServiceOption[],
  selectedServices: Service[] | undefined,
  otherImage: string
): Service[] => {
  let servicesToReturn = [
    ...defaultServices.map((s) => deepCopy<ServiceOption>(s)),
  ];
  const selectedNonNullServices = selectedServices ?? [];

  // Pour chaque service par défaut on vérifie s'ils sont déjà sélectionnés ou pas
  servicesToReturn = servicesToReturn.map((service) => {
    const selectedService = selectedNonNullServices.find(
      ({ type, text }) =>
        (type && type === service.type) || (text && text === service.text)
    );

    if (selectedService) {
      return { ...service, selected: true };
    } else {
      return { ...service, selected: false };
    }
  });

  // On ajoute les services créés manuellement
  selectedServices
    ?.filter((service) => !service.type || service.type === 'custom')
    .map((service) => {
      servicesToReturn.push({
        ...service,
        selected: true,
        image: otherImage,
      });
    });

  return servicesToReturn;
};

export const deliveryServices = uniqueBy(
  frDeliveryServices.concat(usDeliveryServices),
  (a, b) => a.type === b.type
);
