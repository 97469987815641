type DesktopClosedIllutrationProps = {
  title: string;
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const DesktopClosedIllutration: React.FC<
  DesktopClosedIllutrationProps
> = (props: DesktopClosedIllutrationProps) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="desktop-closed-illustration-title"
      role="img"
      {...props}
    >
      <title id="desktop-closed-illustration-title">{props.title}</title>
      <path
        d="M114 26H14C11.7909 26 10 27.7909 10 30V98C10 100.209 11.7909 102 14 102H114C116.209 102 118 100.209 118 98V30C118 27.7909 116.209 26 114 26Z"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="16.5" cy="32.5" r="1.5" fill="#0B1115" />
      <circle cx="21.9286" cy="32.5" r="1.5" fill="#0B1115" />
      <circle cx="27.3574" cy="32.5" r="1.5" fill="#0B1115" />
      <path
        d="M64 48L63.0016 49.0571C63.0312 49.5828 63.4669 50 64 50C64.5331 50 64.9688 49.5828 64.9984 49.0571L64 48Z"
        fill="#0B1115"
      />
      <path
        d="M53.5439 67.7135C52.844 66.3309 50.1951 66.4358 50.1951 68.9798C50.1951 71.5238 52.5162 72.3893 53.5439 70.4485M55.7812 66.8292V71.4844H57.84M65.0523 70.6452C65.7604 72.2604 68.5273 71.4582 67.8192 69.9109C67.3125 68.8037 65.3014 69.1765 65.1965 67.9439C65.0916 66.7112 66.875 66.3441 67.7143 67.5636M72.435 71.4713H70.0615V69.0869M72.3432 66.8384H70.0615V69.0869M70.0615 69.0869H72.0285M64 48C63.4477 48 63 48.4477 63 49C63 49.0192 63.0005 49.0382 63.0016 49.0571M64 48C64.5523 48 65 48.4477 65 49C65 49.0192 64.9995 49.0382 64.9984 49.0571M64 48L63.0016 49.0571M64 48L64.9984 49.0571M55.5 57H49C47.8954 57 47 57.8954 47 59V79C47 80.1046 47.8954 81 49 81H79C80.1046 81 81 80.1046 81 79V59C81 57.8954 80.1046 57 79 57H72.5M55.5 57H72.5M55.5 57L63.0016 49.0571M72.5 57L64.9984 49.0571M63.0016 49.0571C63.0312 49.5828 63.4669 50 64 50C64.5331 50 64.9688 49.5828 64.9984 49.0571M62.9804 69.1765C62.9804 70.2256 62.3772 71.4844 61.2363 71.4844C60.0955 71.4844 59.5447 70.4218 59.5447 69.1765C59.5447 67.9312 60.2791 66.8292 61.2363 66.8292C62.1936 66.8292 62.9804 68.1275 62.9804 69.1765ZM74.7829 71.4713V66.8384C75.8943 66.8384 77.7579 67.0885 77.8041 69.2334C77.8503 71.3783 76.1604 71.4713 74.7829 71.4713Z"
        stroke="#0B1115"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
