import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Tooltip from '@appchoose/tooltip';

import { OrderQuery } from '../../types/generated-new';
import { transformPrice } from '../../utils/currency';
import { Copy } from '../copy/copy';

export type ClaimedProductProps = {
  appearance?: 'default' | 'small';
  claim?: 'pending' | 'accepted_resent' | 'accepted_handled_with_customer';
  product: OrderQuery['order']['claims'][0]['items'][0];
};

export const ClaimedProduct: React.FC<ClaimedProductProps> = ({
  appearance = 'default',
  claim = 'pending',
  product,
}: ClaimedProductProps) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="flex space-x-4">
        <img
          src={product.imageUrl || ''}
          alt=""
          className={`rounded border-0.5 border-gray-300 object-cover ${
            appearance === 'default' ? 'size-20' : 'size-12'
          }`}
        />
        <div className="flex w-full flex-col items-start justify-center space-y-1">
          <p>
            <span className="text-sm font-semibold text-gray-900">
              {product.name}
            </span>{' '}
            <span className="text-sm text-gray-500">
              (
              {transformPrice(
                product.priceSold.valueWithVat,
                i18n.language === 'fr' ? 'fr' : 'en',
                product.priceSold.currency
              )}
              )
            </span>
          </p>
          {product.size ? (
            <p className="text-xs text-gray-700">{product.size}</p>
          ) : null}
          {product.sku ? (
            <p className={'text-xs text-gray-700'}>
              <span className="uppercase">{t('order.sku')}</span> {product.sku}
            </p>
          ) : null}
          {product.digitalCouponCode ? (
            <div className="flex w-full items-start justify-between space-x-4">
              <p
                className={cn(`break-all text-xs`, {
                  'text-red-600': claim === 'pending',
                  'text-[#767A7C]': claim !== 'pending',
                })}
              >
                {claim === 'accepted_resent' ? `${t('order.old_coupon')} ` : ''}
                <span className="uppercase">{product.digitalCouponCode}</span>
              </p>
              <Tooltip
                content={
                  <div className="px-1.5 py-3">
                    {t('order.copy_digital_code_feedback_tooltip')}
                  </div>
                }
                placement="left"
              >
                <Copy
                  className="text-gray-500"
                  tabIndex={-1}
                  value={product.digitalCouponCode ?? ''}
                  successMessage={t('order.copy_digital_code_feedback')}
                  trackLabel="coupon"
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      {claim === 'accepted_resent' && (
        <div className="rounded bg-gray-100 px-4 pb-2.5 pt-3.5">
          <div className="text-xs text-gray-500">
            {t('order.generated_coupon')}
          </div>
          <div className="text-sm text-gray-900">
            {product.newDigitalCouponCode}
          </div>
        </div>
      )}
    </>
  );
};
