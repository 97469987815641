import { OrderDataImage } from './order-table';

type OrderItemImageWithQuantity = {
  item: OrderDataImage;
};

const OrderItemImageWithQuantity: React.FC<OrderItemImageWithQuantity> = ({
  item,
}) => {
  return (
    <div key={item.imageUrl} className="relative size-8">
      <div className="size-8 shrink-0 overflow-hidden rounded-sm border border-gray-100">
        <img src={item.imageUrl} className="size-8 object-cover" />
      </div>
      {item.quantity > 1 ? (
        <p className="absolute left-0 top-1 m-0 bg-gray-900 pl-1 pr-0.5 text-[0.625rem] leading-3 text-white">
          x{item.quantity}
        </p>
      ) : null}
    </div>
  );
};

type OrderItemImagesProps = {
  items: OrderDataImage[];
};

export const OrderItemImages: React.FC<OrderItemImagesProps> = ({ items }) => {
  if (items.length > 3) {
    const itemsToShow = items.slice(0, 2);
    const remainingItems = items.slice(2);
    const remainingItemsCount = remainingItems.reduce(
      (acc, item) => acc + item.quantity,
      0
    );

    return (
      <>
        {itemsToShow.map((item) => (
          <OrderItemImageWithQuantity key={item.imageUrl} item={item} />
        ))}
        <div key={remainingItems[0].imageUrl} className="size-8">
          <div className="relative size-8 shrink-0 overflow-hidden rounded-sm border border-gray-100">
            <img
              src={remainingItems[0].imageUrl}
              className="flex size-8 items-center justify-center object-cover"
            />
            <p className="absolute inset-0 flex items-center justify-center bg-black/50 text-center text-xs font-semibold text-white">
              +{remainingItemsCount}
            </p>
          </div>
        </div>
      </>
    );
  }

  return items.map((item) => (
    <OrderItemImageWithQuantity key={item.imageUrl} item={item} />
  ));
};
