import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import Loader from '@appchoose/loader';

import { useBrandInfoQuery } from '../../types/generated-new';

export const LogoutSuccessful: React.FC = () => {
  const location = useLocation();
  const [brandId] = useSessionStorage<string>('brandId');

  const queryParams = new URLSearchParams(location.search);

  const { data: brandInfoData, isLoading } = useBrandInfoQuery(
    {
      brandId: brandId,
    },
    {
      enabled: !!brandId && brandId !== 'undefined',
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="h-8 w-8" />
      </div>
    );
  }

  if (brandInfoData?.brandInfo && !brandInfoData.brandInfo.acceptContract) {
    return <Navigate to={`/${brandId}/onboarding${location.search}`} replace />;
  }

  return queryParams.has('invite-code') ||
    (brandInfoData?.brandInfo?.nbUsers ?? 0) === 0 ? (
    <Navigate to={`/${brandId}/signup${location.search}`} />
  ) : (
    <Navigate to={`/${brandId}/login${location.search}`} />
  );
};

LogoutSuccessful.displayName = 'LogoutSuccessful';
