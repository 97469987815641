import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';

import { NoResultIllustration } from '../icons/no-result';
import { useResetList } from './use-reset-list';

type OrderTableNoResultProps = {
  type: 'search' | 'filter';
};

export const OrderTableNoResult: React.FC<OrderTableNoResultProps> = ({
  type,
}: OrderTableNoResultProps) => {
  const { t } = useTranslation();
  const { resetList } = useResetList();

  return (
    <div className="mt-20 flex w-full flex-col items-center space-y-4">
      <NoResultIllustration className="size-20" />
      <div className="space-y-1 text-center">
        <h4 className="text-2xl font-bold text-gray-900">
          {t('orders.table.empty_state.no_result.no_result_available')}
        </h4>
        <p className="text-sm">
          {type === 'search'
            ? t(
                'orders.table.empty_state.no_result.change_your_search_criterias'
              )
            : null}
          {type === 'filter'
            ? t(
                'orders.table.empty_state.no_result.change_your_filter_criterias'
              )
            : null}
        </p>
      </div>
      {type === 'filter' ? (
        <Button type="button" onClick={() => resetList()}>
          {t('orders.table.empty_state.no_result.clear_all_filters')}
        </Button>
      ) : null}
    </div>
  );
};
