import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { RecoilRoot } from 'recoil';

import { NotifyUpdate } from '@appchoose/notify';
import Toast from '@appchoose/toast';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { t } from 'i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { BasicBrandInfo } from '../components/basic-brand-info/basic-brand-info';
import { FullBrandInfo } from '../components/full-brand-info/full-brand-info';
import { ProtectedRoute } from '../components/protected-route/protected-route';
import { RedirectIfLogged } from '../components/redirect-if-logged/redirect-if-logged';
import '../lang/i18n';
import { BrandMatch } from '../types/navigation';
import { ConfirmPasswordlessCode } from '../views/confirm-passwordless-code/confirm-passwordless-code';
import { DashboardScreen } from '../views/dashboard-screen/dashboard-screen';
import { ForgotPasswordConfirmation } from '../views/forgot-password-confirmation/forgot-password-confirmation';
import { ForgotPasswordEmail } from '../views/forgot-password-email/forgot-password-email';
import { LimitedAccess } from '../views/limited-access/limited-access';
import { LimitedSettings } from '../views/limited-access/limited-settings';
import { LoginSuccessful } from '../views/login-successful/login-successful';
import { LoginWithPassword } from '../views/login-with-password/login-with-password';
import { Login } from '../views/login/login';
import { LogoutSuccessful } from '../views/logout-successful/logout-successful';
import { NotFoundScreen } from '../views/not-found-screen/not-found-Screen';
import { OnboardingScreen } from '../views/onboarding-screen/onboarding-screen';
import { OnboardingUpdateProfile } from '../views/onboarding-update-profile/onboarding-update-profile';
import { Signup } from '../views/signup/signup';
import { Version } from '../views/version/version';
import { Welcome } from '../views/welcome/welcome';
import './../api/sentry';
import { ChooseRouter } from './choose-router';

import './../assets/scss/App.scss';

const Onboarding: React.FC = () => {
  const { brandId, step } = useParams<BrandMatch>();
  const [, setBrandId] = useSessionStorage<string | undefined>('brandId');

  useEffect(() => {
    setBrandId(brandId);
  }, []);

  if (!step || step === '0' || step === '1') {
    return (
      <BasicBrandInfo>
        <OnboardingScreen />
      </BasicBrandInfo>
    );
  }

  return (
    <ProtectedRoute>
      <FullBrandInfo>
        <OnboardingScreen />
      </FullBrandInfo>
    </ProtectedRoute>
  );
};

// interval between each call to the Service Worker
const intervalMS = 2 * 60 * 1000;

const queryClient = new QueryClient();

const App: React.FC = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(registration) {
      return (
        registration &&
        setInterval(() => {
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          if (registration.installing || !navigator) return;

          if ('connection' in navigator && !navigator.onLine) return;

          registration.update();
        }, intervalMS)
      );
    },
    onRegisterError(error) {
      Sentry.captureException(error);
    },
  });

  return (
    <Sentry.ErrorBoundary>
      <RecoilRoot>
        <Auth0Provider
          domain={import.meta.env.REACT_APP_AUTH0_DOMAIN as string}
          clientId={import.meta.env.REACT_APP_AUTH0_CLIENT_ID as string}
          authorizationParams={{
            audience: `${import.meta.env.REACT_APP_AUTH0_AUDIENCE as string}`,
            redirect_uri: `${window.location.origin}/login-successful`,
            scope: 'openid profile email offline_access',
          }}
          cacheLocation="localstorage"
          useRefreshTokens
          useRefreshTokensFallback
        >
          <QueryClientProvider client={queryClient}>
            <ChooseRouter>
              <Routes>
                <Route path="/" element={<NotFoundScreen />} />
                <Route
                  path="/login-successful/*"
                  element={<LoginSuccessful />}
                />
                <Route
                  path="/logout-successful/*"
                  element={<LogoutSuccessful />}
                />
                <Route path="/version/*" element={<Version />} />

                <Route
                  path="/:brandId/*"
                  element={
                    <BasicBrandInfo>
                      <Routes>
                        <Route
                          path="onboarding/:step?/:substep?/*"
                          element={<Onboarding />}
                        />

                        <Route path="welcome/*" element={<Welcome />} />
                        <Route
                          path="login/*"
                          element={
                            <RedirectIfLogged>
                              <Login />
                            </RedirectIfLogged>
                          }
                        />
                        <Route
                          path="login-with-password/*"
                          element={
                            <RedirectIfLogged>
                              <LoginWithPassword />
                            </RedirectIfLogged>
                          }
                        />
                        <Route
                          path="signup/*"
                          element={
                            <RedirectIfLogged>
                              <Signup />
                            </RedirectIfLogged>
                          }
                        />
                        <Route
                          path="confirm-passwordless-code/*"
                          element={
                            <RedirectIfLogged>
                              <ConfirmPasswordlessCode />
                            </RedirectIfLogged>
                          }
                        />
                        <Route
                          path="forgot-password/*"
                          element={
                            <RedirectIfLogged>
                              <ForgotPasswordEmail />
                            </RedirectIfLogged>
                          }
                        />
                        <Route
                          path="forgot-password-confirmation/*"
                          element={
                            <RedirectIfLogged>
                              <ForgotPasswordConfirmation />
                            </RedirectIfLogged>
                          }
                        />

                        <Route
                          path="onboarding-update-profile/*"
                          element={
                            <ProtectedRoute>
                              <OnboardingUpdateProfile />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="limited-access/*"
                          element={
                            <ProtectedRoute>
                              <LimitedAccess />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="limited-settings/*"
                          element={
                            <ProtectedRoute>
                              <LimitedSettings />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            <ProtectedRoute>
                              <FullBrandInfo>
                                <DashboardScreen />
                              </FullBrandInfo>
                            </ProtectedRoute>
                          }
                        />
                      </Routes>
                    </BasicBrandInfo>
                  }
                />
              </Routes>
            </ChooseRouter>
            <Toast />
            {needRefresh && (
              <NotifyUpdate
                title={t('update.new_version')}
                description={t('update.refresh_page')}
                onClick={() => {
                  updateServiceWorker();
                  setNeedRefresh(false);
                }}
              />
            )}
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Auth0Provider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  );
};

App.displayName = 'App';

export default App;
