import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Button from '@appchoose/button';
import Checkbox from '@appchoose/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@appchoose/form';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { CircleStroke } from '../icons/circle-stroke/circle-stroke';

import './onboarding.scss';

export type OnboardingStepRuleConfirmationProps = {
  goToNextStep: () => void;
};

type OnboardingStepRuleConfirmationForm = {
  ruleConfirmation: boolean;
};

export const OnboardingStepRuleConfirmation: React.FC<
  OnboardingStepRuleConfirmationProps
> = ({ goToNextStep }) => {
  const { t } = useTranslation();
  const brand = useRecoilValue(brandState);

  const onSubmit = () => {
    goToNextStep();
  };

  const form = useForm<OnboardingStepRuleConfirmationForm>({
    mode: 'onTouched',
    defaultValues: {
      ruleConfirmation: false,
    },
  });

  const rules =
    brand?.store === StoreRegion.Fr
      ? [
          t('onboarding.rule_confirmation.first_info_fr'),
          t('onboarding.rule_confirmation.second_info_fr'),
          t('onboarding.rule_confirmation.third_info_fr'),
          t('onboarding.rule_confirmation.fourth_info_fr'),
        ]
      : [
          t('onboarding.rule_confirmation.first_info_us'),
          t('onboarding.rule_confirmation.second_info_us'),
          t('onboarding.rule_confirmation.third_info_us'),
        ];

  return (
    <section className="sm:col-11 w-full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div>
            <div className="mt-8 sm:mt-20">
              <h2 className="text-2xl font-bold sm:text-3.5xl">
                {t('onboarding.rule_confirmation.title')}
              </h2>
              <h3 className="my-6 text-sm font-normal text-gray-700 sm:mt-10 sm:text-base">
                {t('onboarding.rule_confirmation.subtitle', {
                  count: rules.length,
                })}
              </h3>
            </div>
            <ul className="mb-8 space-y-6">
              {rules.map((rule, index) => (
                <li
                  key={index}
                  className="flex items-center leading-5.5 text-gray-900"
                >
                  <div className="mr-2 inline-block">
                    <div className="relative flex items-center justify-center">
                      <CircleStroke />
                      <span className="absolute text-sm font-bold text-green-900">
                        {index + 1}
                      </span>
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rule,
                    }}
                    className="rule-content-bold"
                  ></div>
                </li>
              ))}
            </ul>
            <FormField
              control={form.control}
              name="ruleConfirmation"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <label className="flex items-center space-x-2 rounded-lg bg-beige-300 p-4">
                    <FormControl>
                      <Checkbox
                        {...field}
                        className="shrink-0"
                        value=""
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <span className="text-sm font-semibold text-gray-900">
                      {t('onboarding.rule_confirmation.confirm')}
                    </span>
                  </label>
                  <FormMessage match="required">
                    {t(
                      'onboarding.rule_confirmation.rule_confirmation.validation_errors.required'
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
          <div className="mt-8 flex sm:mt-10">
            <Button size="large" type="submit">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </section>
  );
};
