import React from 'react';

import Illustration from '@appchoose/illustration';

type SaleInfoProps = {
  variant: 'sale-ready' | 'invoice-waiting' | 'invoice-ready';
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const SaleInfo: React.FC<SaleInfoProps> = ({
  variant,
  title,
  subtitle,
  children,
}: SaleInfoProps) => {
  const getIllustration = () => {
    if (variant === 'sale-ready') {
      return <Illustration illustration="desktopOn" />;
    }
    if (variant === 'invoice-ready') {
      return <Illustration illustration="desktopMoney" />;
    }
    return <Illustration illustration="desktopChecked" />;
  };

  return (
    <div className="flex flex-col items-center rounded-lg border border-gray-300 p-8 pb-12">
      {getIllustration()}
      <h4 className="mb-2 mt-4 text-2xl font-bold text-gray-900">{title}</h4>
      {subtitle ? (
        <h5 className="mb-4 max-w-[530px] text-center text-sm text-gray-700">
          {subtitle}
        </h5>
      ) : null}
      {children}
    </div>
  );
};

SaleInfo.displayName = 'SaleInfo';
