type CrossIllutrationProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const CrossIllutration: React.FC<CrossIllutrationProps> = (
  props: CrossIllutrationProps
) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.4853 31.5147L31.5147 48.4853M48.4853 48.4853L31.5147 31.5147M74 40C74 58.7777 58.7777 74 40 74C21.2223 74 6 58.7777 6 40C6 21.2223 21.2223 6 40 6C58.7777 6 74 21.2223 74 40Z"
        stroke="#0B1115"
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
