import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import Illustration from '@appchoose/illustration';
import { addDays, setHours, setMinutes, setSeconds } from 'date-fns';

import { brandState } from '../../stores/brand';
import { transformFullDateSimple } from '../../utils/date';

type DurationWidgetEndedProps = {
  onClose: (widget_id: string) => void;
  endTime: string;
  widget_id: string;
  additionnalDays?: number;
};

export const DurationWidgetEnded: React.FC<DurationWidgetEndedProps> = ({
  onClose,
  endTime,
  widget_id,
  additionnalDays,
}: DurationWidgetEndedProps) => {
  const { i18n, t } = useTranslation();
  const brand = useRecoilValue(brandState);

  const endDateAlmostMidnight = setSeconds(
    setMinutes(setHours(new Date(endTime), 23), 59),
    59
  );

  function getContent() {
    if (typeof additionnalDays === 'number') {
      return (
        <div className="flex flex-col items-center">
          <Illustration illustration="checked" />
          <h2 className="mb-4 mt-2 text-2xl font-bold text-gray-900">
            {t('widgets.ended_title_ok', {
              x: additionnalDays,
            })}
          </h2>
          <p
            className="mb-6 text-gray-700"
            dangerouslySetInnerHTML={{
              __html: t('widgets.ended_p_ok', {
                x: transformFullDateSimple(
                  addDays(endDateAlmostMidnight, additionnalDays),
                  i18n.language === 'fr' ? 'fr' : 'en',
                  brand?.timezone ?? undefined
                ),
              }),
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center">
          <Illustration illustration="checked" />
          <h2 className="mb-4 mt-2 text-2xl font-bold text-gray-900">
            {t('widgets.ended_title_nok')}
          </h2>
          <p
            className="mb-6 text-gray-700"
            dangerouslySetInnerHTML={{
              __html: t('widgets.ended_p_nok', {
                x: transformFullDateSimple(
                  endDateAlmostMidnight,
                  i18n.language === 'fr' ? 'fr' : 'en',
                  brand?.timezone ?? undefined
                ),
              }),
            }}
          />
        </div>
      );
    }
  }

  return (
    <section className="relative mb-4 rounded-lg border border-gray-300 p-8 pt-10">
      {getContent()}
      <button
        type="button"
        className="absolute right-8 top-8 transition-opacity duration-300 hover:opacity-70"
        onClick={() => onClose(widget_id)}
      >
        <Icon icon="close" size="large" />
      </button>
    </section>
  );
};

DurationWidgetEnded.displayName = 'DurationWidgetEnded';
