import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';

import { Brand, brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getPhoneCountryCode, parsePhoneNumber } from '../../utils/phone';
import { removeAllSpaces } from '../../utils/string';
import {
  BrandContactFormData,
  ContactFormFields,
} from '../brand-info/contact-form-fields';

export type OnBoardingContactFormProps = {
  goToNextStep: () => void;
};

export const OnBoardingContactForm: React.FC<OnBoardingContactFormProps> = ({
  goToNextStep,
}: OnBoardingContactFormProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useRecoilState(brandState);

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const parsedDefaultPhoneNumber = parsePhoneNumber(brand?.contact_phone ?? '');

  const form = useForm<BrandContactFormData>({
    mode: 'onTouched',
    defaultValues: {
      email: brand?.contact_email ?? '',
      phone: parsedDefaultPhoneNumber
        ?.formatInternational()
        ?.replace(/\s/g, ''),
      phoneCountryCode:
        getPhoneCountryCode(
          parsedDefaultPhoneNumber,
          brand?.store ?? StoreRegion.Fr
        ) ?? '',
    },
  });

  const onSubmit = (data: BrandContactFormData) => {
    onUpdate({
      contact_phone: data.phone,
      contact_email: removeAllSpaces(data.email),
    });
  };

  const onUpdate = (
    data: Pick<NonNullable<Brand>, 'contact_phone' | 'contact_email'>
  ) => {
    if (!brand) return;
    updateSellerMutation({ updateSeller: data });
    setBrand({
      ...brand,
      contact_email: data.contact_email,
      contact_phone: data.contact_phone,
    });
    goToNextStep();
  };

  return (
    <section className="sm:col-11 w-full">
      <div className="mt-8 sm:mt-20">
        <h2 className="mb-6 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
          {t('onboarding.contact.title')}
        </h2>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <ContactFormFields autoFocus />
            </div>
            <div className="mt-8 flex sm:mt-10">
              <Button type="submit" size="large">
                {t('continue')}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </section>
  );
};
