import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Alert from '@appchoose/alert';
import { useAuth0 } from '@auth0/auth0-react';

import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import { brandState } from '../../stores/brand';
import { ProfileMenu } from './profile-menu';

export const LimitedAccess: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();

  const { user } = useAuth0();

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader>
        <ProfileMenu />
      </XChooseHeader>
      <main className="mx-2 mt-10 sm:mx-8 sm:mt-20 md:mx-24">
        <h1 className="mb-4 text-3.5xl font-bold">
          {t('auth.limited_access.limited_access')}
        </h1>
        <div className="space-y-10">
          <p className="m-0 text-base leading-5.5 text-gray-700">
            {t('auth.limited_access.dont_have_access_to', {
              brandName: brand?.name,
              interpolation: { escapeValue: false },
            })}
          </p>
          <Alert
            appearance="default"
            size="small"
            icon="help"
            title={
              <>
                {t('auth.limited_access.logged_in_as')}
                <span className="font-bold text-green-900">{user?.email}</span>
              </>
            }
          >
            <span className="text-sm leading-5.5 text-gray-700">
              {t('auth.limited_access.log_in_different_email')}
            </span>
          </Alert>
        </div>
      </main>
    </div>
  );
};
