export const OrderTableSkeleton: React.FC = () => {
  return (
    <tr className="animate-pulse border-b border-b-gray-100 last:border-none">
      <td data-table-cell="true" className="sticky left-0 bg-white p-4">
        <div className="h-[1.375rem] w-[6.5rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.375rem] w-[5.625rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.375rem] w-[5.625rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="flex flex-nowrap gap-x-1 p-4">
        <div className="size-[2rem] rounded-[0.1875rem] bg-gray-300"></div>
        <div className="size-[2rem] rounded-[0.1875rem] bg-gray-300"></div>
        <div className="size-[2rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.375rem] w-[5.625rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.75rem] w-[5.375rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.75rem] w-[5.375rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.75rem] w-[5.375rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.75rem] w-[5.375rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.75rem] w-[5.375rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
      <td className="p-4">
        <div className="h-[1.75rem] w-[5.375rem] rounded-[0.1875rem] bg-gray-300"></div>
      </td>
    </tr>
  );
};
