type MicrosoftProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Microsoft: React.FC<MicrosoftProps> = ({
  ...props
}: MicrosoftProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1H23V23H1V1Z" fill="#F3F3F3" />
      <path
        d="M1.95654 1.95654H11.5218V11.5218H1.95654V1.95654Z"
        fill="#F35325"
      />
      <path
        d="M12.478 1.95654H22.0432V11.5218H12.478V1.95654Z"
        fill="#81BC06"
      />
      <path
        d="M1.95654 12.4783H11.5218V22.0435H1.95654V12.4783Z"
        fill="#05A6F0"
      />
      <path
        d="M12.478 12.4783H22.0432V22.0435H12.478V12.4783Z"
        fill="#FFBA08"
      />
    </svg>
  );
};
